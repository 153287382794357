import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Moment from 'react-moment';
import { Provider } from 'react-redux';
import store from './redux/store';

import Login from './views/Security/Login';
import withAuth from './views/withAuth';
import Remito from './views/Remito';

import './App.css';
import NotificationLayout from './views/NotificationLayout';

// Moment defaults
Moment.globalFormat = 'YYYY-MM-DD HH:mm';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastProvider autoDismissTimeout="6000" placement="bottom-right">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/remitos/:id" component={withAuth(Remito)} />
            <Route path="/" component={withAuth(NotificationLayout)} />
          </Switch>
        </ToastProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
