import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import APIClient from '../../services/APIClient';
import { withToastManager } from 'react-toast-notifications';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FormSelectField, FormInputField, DataTable } from '../../components';
import { columnsProviderDelivery as columns, utils } from './utils';

class ProviderDeliveryEdit extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      isAdding: typeof id === 'undefined',
      isDataLoading: false,
      showUploadModal: false,
      id,
      providers: [],
      stateOptions: [],
      providerId: '',
      orderNumber: null,
      createdDate: '',
      deliveryDate: '',
      stateCode: 100,
      articles: [],
      selectedArticles: [],
      previousArticles: [],
      assocToDelete: [],
      file: {},
    };
  }

  componentDidMount() {
    const { id } = this.state;
    this.onLoadForm();
    if (id && id !== '') {
      this.onRetrieveEntity();
    }
  }

  onLoadForm = async () => {
    const { toastManager } = this.props;
    try {
      const providers = await APIClient.get('/proveedores');
      const states = await APIClient.get('/provider-delivery-state');

      this.setState({
        providers: providers.data.data,
        stateOptions: states.data.data,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
      });
    }
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;
    const { toastManager } = this.props;
    try {
      this.setState({
        isDataLoading: true,
      });

      const providerDeliveryToUpdate = await APIClient.get(`/provider-delivery/${id}`);
      const entity = utils.parseDatesDelivery(providerDeliveryToUpdate.data.data);

      this.setState({
        providerId: entity.providerId,
        orderNumber: entity.orderNumber,
        createdDate: entity.createdDate,
        deliveryDate: entity.deliveryDate,
        stateCode: entity.stateCode,
        selectedArticles: utils.parseItems(providerDeliveryToUpdate.data.data.items),
        previousArticles: utils.parseItems(providerDeliveryToUpdate.data.data.items),
        providerId: providerDeliveryToUpdate.data.data.providerId,
        isDataLoading: false,
      });
    } catch (error) {
      this.setState({
        isDataLoading: false,
      });
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
      });
    }
  };

  onSaveEntity = async () => {
    const { toastManager, history } = this.props;
    const { isAdding, selectedArticles, previousArticles, id, providerId, orderNumber, createdDate, deliveryDate, stateCode } = this.state;

    const entity = {
      providerId,
      orderNumber,
      createdDate,
      deliveryDate,
      stateCode,
    };

    try {
      const validation = utils.validations(entity);
      if (!validation.isOk) {
        throw validation;
      }

      if (isAdding) {
        entity.items = selectedArticles;
        await APIClient.post('/provider-delivery', entity);
      } else {
        entity.items = utils.buildArticlesToAddArray(selectedArticles, previousArticles);
        entity.itemsToDelete = utils.buildArticlesToDeleteArray(selectedArticles, previousArticles);
        await APIClient.patch(`/provider-delivery/${id}`, entity);
      }

      toastManager.add('Pedido guardado con éxito', {
        appearance: 'success',
      });

      history.push('/proveedores/pedido');
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error.message}`, {
        appearance: 'error',
      });
    }
  };

  handleChange = (event) => {
    const { id, value } = event.target;

    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  handleUploadModal = async (action) => {
    const { providerId } = this.state;
    const { toastManager } = this.props;

    if (action === 'OPEN' && providerId === '') {
      return toastManager.add('Por favor seleccione un proveedor', {
        appearance: 'info',
        autoDismiss: true,
      });
    }
    const articles = await APIClient.get(`/articulos?filter[eliminadoFlag][eq]=0&filter[proveedor_id][eq]=${providerId}`);
    this.setState({
      showUploadModal: action === 'OPEN',
      articles: articles.data.data,
    });
  };

  uploadExcelModal = () => (
    <Modal size="md" show={this.state.showUploadModal} onHide={() => this.handleUploadModal('CLOSE')}>
      <Modal.Header closeButton>
        <Modal.Title>Carga de pedido desde excel</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <input name="file" type="file" accept=".xlsx" onChange={(e) => this.setState({ file: e.target.files[0] })} />
        <Button variant="primary" onClick={() => this.onReadFile()}>
          Listo
        </Button>
        <Button variant="secondary" onClick={() => this.handleUploadModal('CLOSE')}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  onReadFile = (e) => {
    const { toastManager } = this.props;
    const { file } = this.state;

    this.handleUploadModal('CLOSE');

    if (file) {
      try {
        this.setState({ operationInProgress: true, fileLoadProgress: 0 });
        const reader = new FileReader();

        reader.onload = function read(e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          this.onLoadDeliveryData(sheet);
        }.bind(this);

        reader.readAsArrayBuffer(file);
      } catch (error) {
        toastManager.add('Por favor verifique el archivo', {
          appearance: 'error',
        });
      }
    }
  };

  onLoadDeliveryData = (data) => {
    const { toastManager } = this.props;
    const { articles } = this.state;

    const toAddProducts = articles.filter((article) => {
      for (let i = 1; i < data.length; i += 1) {
        if (data[i][5] === Number(article.codigoEan13)) {
          article.quantity = Number(data[i][7]);
          article.articleId = article.id;
          delete article.id;
          return article;
        }
      }
    });
    // se resta 1 al lengt de la data al margen del título excel
    if (toAddProducts.length < data.length - 1) {
      toastManager.add('Algunos artículos no fueron cargados, por favor verifique si pertenecen al proveedor seleccionado', {
        appearance: 'info',
        autoDismiss: true,
      });
    }

    this.setState({
      selectedArticles: toAddProducts,
    });
  };

  render() {
    const {
      isAdding,
      isDataLoading,
      providers,
      stateOptions,
      selectedArticles,
      providerId,
      orderNumber,
      createdDate,
      deliveryDate,
      stateCode,
      id,
    } = this.state;

    return (
      <div>
        {!isAdding ? <h1>Pedido {id}</h1> : <h1 className="page-title"> Pedido nuevo </h1>}

        {this.uploadExcelModal()}
        <Row>
          <Col md={6}>
            <FormSelectField
              id="providerId"
              label="Proveedor"
              choices={providers}
              choiceIdField="id"
              choiceLabelField="nombre"
              value={providerId}
              onChange={(e) => this.handleChange(e)}
            />
          </Col>
          <Col md={6}>
            <FormInputField
              id="orderNumber"
              label="Orden de compra No."
              type="text"
              value={orderNumber}
              onChange={(e) => this.handleChange(e)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <FormInputField
              id="createdDate"
              label="Fecha de factura"
              type="date"
              value={createdDate}
              onChange={(e) => this.handleChange(e)}
            />
          </Col>
          <Col md={3}>
            <FormInputField
              id="deliveryDate"
              label="Fecha de entrega"
              type="date"
              value={deliveryDate}
              onChange={(e) => this.handleChange(e)}
            />
          </Col>

          <Col md={6}>
            <FormSelectField
              id="stateCode"
              label="Estado del pedido"
              choices={stateOptions}
              choiceIdField="code"
              choiceLabelField="description"
              value={stateCode}
              onChange={(e) => this.handleChange(e)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DataTable
              columns={columns}
              data={selectedArticles || []}
              keyField="id"
              isDataLoading={isDataLoading}
              showExport={false}
              addSecondButton={() => this.handleUploadModal('OPEN')}
              secondButtonText="Importar excel"
            />
          </Col>
          <Col md={1}>
            <Button variant="primary" size="md" className="mt-4" onClick={() => this.onSaveEntity()}>
              Guardar
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withToastManager(ProviderDeliveryEdit);
