import React from 'react';
import { Route } from 'react-router-dom';
import ColeccionList from './ColeccionList';
import ColeccionEdit from './ColeccionEdit';

const routes = () => [
  <Route path="/colecciones/nuevo" exact component={ColeccionEdit} key="/colecciones/nuevo" />,
  <Route path="/colecciones/:id" component={ColeccionEdit} key="/colecciones/:id" />,
  <Route path="/colecciones" exact component={ColeccionList} key="/colecciones/:id" />,
].map(route => route);

export default routes;
