import moment from "moment";

function sanitizeQuery(expectedFields, queryString) {
  let response = {
    page:''
  };
  const querySections = queryString.split('&');
  if (querySections[0] === '') {
    expectedFields.forEach((field) => {
      response[field] = undefined;
    });
  } else {
    if (querySections[0].indexOf('?') >= 0) {
      querySections[0] = querySections[0].replace('?', '');
    }
    expectedFields.forEach((field) => {
      querySections.forEach((sec) => {
        if (sec.includes(field) && sec.includes('=')) {
          let querys = sec.split('=');
          if (querys[0] === field) {
            let value = querys[1];
            response[field] = value;
          } else {
            response[field] = undefined;
          }
        } else {
          response[field] = undefined;
        }
      });
    });
  }
  return response;
}

function prepareQueryToFilter(queryValueField, op, ...value) {
  if (value[0].length === 0 || value[0][0] === '') {
    return undefined;
  }
  let query = '';
  value.forEach((val, j) => {
    query += val.reduce((ac, val_, k, t) => {
      if (t[k] !== '') {
        return ac + `filter[${queryValueField}][${op}]=${t[k]}&`;
      }
    }, '');
  });
  if (query) {
    return query.slice(0, query.length - 1);
  }
}

function stringToBoolean(aString) {
  let returnBoolean = false;
  switch (aString.toLowerCase()) {
    case 'true':
      returnBoolean = true;
      break;
    default:
      break;
  }
  return returnBoolean;
}

function isPositiveInteger(value) {
  return !Number.isNaN(value) && value >= 0 && Number.isInteger(value);
}

const forSplitProducts = (articulosDelBody, tope) => {
  let subindiceEnArray;
  let cantidadATransladar;
  let count = 0;
  let thisBreak = false;
  for (let i = 0; i < articulosDelBody.length; i++) {
    const articulo = articulosDelBody[i];
    const precio = parseFloat(articulo.precioconivaincluido);
    for (let y = 1; y <= articulo.cantidad; y++) {
      count += precio;
      if (count >= tope) {
        subindiceEnArray = i;
        cantidadATransladar = y - 1;
        // frontera de array
        // if (cantidadATransladar === 0) {
        //   subindiceEnArray = i - 1;
        // }
        thisBreak = true;
        break;
      } else {
        subindiceEnArray = i;
        cantidadATransladar = y;
      }
    }
    if (thisBreak) {
      break;
    }
  }
  return { subindiceEnArray, cantidadATransladar };
};

async function splitProducts(articulosDelBody, tope) {
  const cantYSubindice = await forSplitProducts(articulosDelBody, tope);
  const { subindiceEnArray } = cantYSubindice;
  const { cantidadATransladar } = cantYSubindice;
  let seQuedan;
  let aTransladar;
  // articulo de corte
  const articuloBreak = JSON.parse(JSON.stringify(articulosDelBody[subindiceEnArray]));
  const articuloBreak2 = JSON.parse(JSON.stringify(articulosDelBody[subindiceEnArray]));
  const articuloBreakCantidadToKeep = articuloBreak.cantidad - cantidadATransladar;
  const allTransladar = articuloBreakCantidadToKeep === 0;
  const allSeQuedan = articuloBreakCantidadToKeep === articuloBreak.cantidad;
  const quedanYTransladan = articuloBreakCantidadToKeep > 0 && !allSeQuedan;
  if
  (allTransladar) {
    seQuedan = articulosDelBody.splice(subindiceEnArray + 1);
    aTransladar = articulosDelBody;
  } else if
  (allSeQuedan) {
    seQuedan = articulosDelBody.splice(subindiceEnArray);
    aTransladar = articulosDelBody;
  } else if
  (quedanYTransladan) {
    seQuedan = articulosDelBody.splice(subindiceEnArray + 1);
    articuloBreak.cantidad = articuloBreakCantidadToKeep;
    seQuedan.unshift(articuloBreak);

    aTransladar = articulosDelBody;
    articuloBreak2.cantidad = cantidadATransladar;
    aTransladar[aTransladar.length - 1].cantidad = cantidadATransladar;
  }
  return { aTransladar, seQuedan };
}

export const buildDateAgo = (days) => {
  const currentDate = new Date();
  const miliseconds = days * 24 * 60 * 60 * 1000;
  const dateAgo = new Date(currentDate.getTime() - miliseconds);
  const parseResultDate = moment.utc(dateAgo).format('YYYY-MM-DD').toString();
  return parseResultDate;
};

export default {
  sanitizeQuery,
  prepareQueryToFilter,
  stringToBoolean,
  isPositiveInteger,
  splitProducts,
};
