import React, { Component } from 'react';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { DataTable, FormSelectField } from '../../components';
import monthSelectOptionsCalculator, { assocProductsToDelete } from './priceModifiersUtils';
import APIClient from '../../services/APIClient';

class BulkPriceModifier extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      priceModifierToUpdate: {},
      isDataLoading: false,
      isAdding: true,
      products: [],
      priceLists: [],
      selectedPriceList: '',
      monthSelectOptions: [],
      selectedValidity: '',
      validityTo: '',
      validityFrom: '',
      showAlert: false,
      event: {},
    };
  }

  async componentDidMount() {
    const { id } = this.state;
    await this.loadData();
    if (id !== undefined) {
      await this.onRetrieveEntity();
    }
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;
    const priceModifierRes = await APIClient.get(`/modificadores-precio/${id}`);
    const priceModifier = priceModifierRes.data.data;
    this.setState((prevState) => ({
      ...prevState,
      isAdding: false,
      id: priceModifier.id,
      priceModifierToUpdate: priceModifier,
      selectedValidity: moment.utc(priceModifier.vigenciaHasta).format('YYYY-MM-DD'),
      validityFrom: moment.utc(priceModifier.vigenciaDesde).format('YYYY-MM-DD'),
      validityTo: moment.utc(priceModifier.vigenciaHasta).format('YYYY-MM-DD'),
      selectedPriceList: priceModifier.detalles[0].listaPrecioId.toString(),
    }));
    const isEdit = true;
    await this.loadProductsToTable(priceModifier, isEdit);
  };

  loadData = async () => {
    const { toastManager } = this.props;
    try {
      // prepare the month select options
      const monthSelectOptions = monthSelectOptionsCalculator;
      const priceListsRes = await APIClient.get('/listas-precio?filter[eliminadoFlag][eq]=0');
      return this.setState({
        priceLists: priceListsRes.data.data,
        monthSelectOptions,
      });
    } catch (error) {
      return toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  };

  handleDataChange = (name, value) => {
    return this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      products: [],
      showAlert: false,
    }));
  };

  handleValidityChange = (name, value) => {
    // from the "selectedValidity" select the values of validity are created
    const to = new Date(value);
    const month = to.getMonth();
    const year = to.getFullYear();
    const from = new Date(year, month, 1);

    return this.setState((prevState) => ({
      ...prevState,
      selectedValidity: value,
      validityTo: moment.utc(to).format('YYYY-MM-DD'),
      validityFrom: moment.utc(from).format('YYYY-MM-DD'),
      products: [],
      showAlert: false,
    }));
  };

  handleModal = async (e) => {
    const { name, value } = e.target;
    const { products } = this.state;

    // if there is not products no show the modal
    if (products.length <= 0) {
      switch (name) {
        case 'selectedValidity':
          return this.handleValidityChange(name, value);
        default:
          return this.handleDataChange(name, value);
      }
    }

    // save event in state and show modal
    const event = {
      name,
      value,
    };
    return this.setState((prevState) => ({
      ...prevState,
      event,
      showAlert: true,
    }));
  };

  alertChangePriceModifier = () => {
    const { showAlert, event } = this.state;
    return (
      <>
        <Modal size="md" show={showAlert}>
          <Modal.Header closeButton>
            <Modal.Title>Está seguro de cambiar de modificador de precio?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Los cambios que se han realizado sin guardar se perderán</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={event.name === 'selectedValidity' ? () => this.handleValidityChange(event.name, event.value) : () => this.handleDataChange(event.name, event.value)}>
              Aceptar
            </Button>
            <Button variant="secondary" onClick={() => this.setState({ showAlert: false })}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  onAddProducts = async () => {
    const { selectedPriceList, validityFrom } = this.state;
    const { toastManager } = this.props;

    try {
      // validations
      const validator = this.validations(null);
      if (validator !== true) {
        throw validator;
      }
      // init loading
      this.setState((prevState) => ({
        ...prevState,
        isDataLoading: true,
      }));

      // determine if it will be edition or a new price modifier
      let isEdit = false;
      const fromDate = new Date(validityFrom);
      // find bulk price modifiers with the same validity selected
      const priceModifiersWithSameValidityRes = await APIClient.get(
        `/modificadores-precio?filter[is_bulk][eq]=1&filter[vigencia_desde][eq]=${fromDate}`,
      );

      const priceModifiersWithSameValidity = priceModifiersWithSameValidityRes.data.data;

      let priceModifierToUpdate = null;
      priceModifiersWithSameValidity.forEach((e) => {
        // if this has the same price list selected in the products, then it is updated
        if (e.detalles[0].listaPrecioId.toString() === selectedPriceList) {
          isEdit = true;
          priceModifierToUpdate = e;
        }
      });

      this.setState((prevState) => ({
        ...prevState,
        isAdding: !isEdit,
        id: priceModifierToUpdate === null ? undefined : priceModifierToUpdate.id,
        priceModifierToUpdate,
      }));

      await this.loadProductsToTable(priceModifierToUpdate, isEdit);
    } catch (error) {
      toastManager.add(error.message || `Ocurrió un error ${error}`, {
        appearance: error.appearance || 'error',
        autoDismiss: true,
      });
    }
  };

  loadProductsToTable = async (priceModifierToUpdate, isEdit) => {
    const { selectedPriceList } = this.state;
    // all products associates with the price list selected
    const productsFromPriceListRes = await APIClient.get(`/modificadores-precio/price-list?priceListId=${selectedPriceList}`);

    const productsFromPriceList = productsFromPriceListRes.data.data;

    productsFromPriceList.forEach((e) => {
      e.discount = 0;
      // if is edition, update discount values and id for update
      if (isEdit) {
        priceModifierToUpdate.detalles.forEach((product) => {
          if (e.id === product.articuloId) {
            e.idToUpdate = Number(product.id);
            e.discount = Number(product.porcentaje);
          }
        });
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      products: productsFromPriceList,
      isDataLoading: false,
    }));
  };

  onSaveEntity = async () => {
    const { isAdding, id, selectedPriceList, priceLists, products, validityFrom, validityTo, priceModifierToUpdate } = this.state;
    const { history, toastManager } = this.props;
    const entity = {};

    // remove selectedValidity
    delete entity.selectedValidity;
    // remove selectedListaPrecio
    delete entity.selectedListaPrecio;

    const newDetalles = [];
    try {
      // save the query response
      let saveResponse;

      if (isAdding) {
        products.forEach((e) => {
          const newDetalle = {};
          if (Number(e.discount) !== 0) {
            newDetalle.listaPrecioId = selectedPriceList;
            newDetalle.porcentaje = Number(e.discount);
            newDetalle.articuloId = e.id;
            newDetalles.push(newDetalle);
          }
        });

        // validations
        const validator = this.validations(newDetalles);
        if (validator !== true) {
          throw validator;
        }

        // settings for price modifier

        // object price list selected
        let priceList;
        priceLists.forEach((e) => {
          if (e.id.toString() === selectedPriceList) {
            priceList = e;
          }
        });

        entity.detalles = newDetalles;
        entity.vigenciaDesde = validityFrom;
        entity.vigenciaHasta = validityTo;
        entity.modoCalculo = 'B';
        entity.orden = 100;
        entity.isBulk = true;
        entity.nombre = `Descuento masivo ${entity.vigenciaDesde} ${priceList.descripcion}`;

        saveResponse = await APIClient.post('/modificadores-precio', entity);
      } else {
        products.forEach((e) => {
          const newDetalle = {};
          if (Number(e.discount) !== 0) {
            newDetalle.id = e.idToUpdate || null;
            newDetalle.listaPrecioId = selectedPriceList;
            newDetalle.porcentaje = Number(e.discount);
            newDetalle.articuloId = e.id;
            newDetalle.modificadorId = id;
            newDetalles.push(newDetalle);
          }
        });

        // looking for deleted products for update the price modifier
        const assocToDelete = assocProductsToDelete(priceModifierToUpdate, newDetalles);

        // validations
        const validator = this.validations(newDetalles);
        if (validator !== true) {
          throw validator;
        }

        // settings for price modifier
        entity.detalles = newDetalles;
        entity.assocToDelete = assocToDelete;

        saveResponse = await APIClient.patch(`/modificadores-precio/${id}`, entity);
      }
      return toastManager.add(
        `Modificador de Precio ${saveResponse.data.data.id} guardado con éxito`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => history.push('/modificadores-precio'),
      );
    } catch (error) {
      toastManager.add(error.message || `Ocurrió un error ${error}`, {
        appearance: error.appearance || 'error',
        autoDismiss: true,
      });
    }
  };

  validations = (detalles) => {
    const { validityFrom, selectedPriceList, products } = this.state;
    // validations
    // error date undefined
    if (validityFrom === '') {
      const error = {
        message: 'Debe seleccionar una vigencia',
        appearance: 'warning',
      };
      return error;
    }

    if (selectedPriceList === '') {
      const error = {
        message: 'Debe seleccionar una lista de precio',
        appearance: 'warning',
      };
      return error;
    }

    // error without detalles
    if (detalles !== null && products.length <= 0) {
      const error = {
        message: 'Selecciona los productos para aplicar descuentos',
        appearance: 'warning',
      };
      return error;
    }

    // error without discount
    if (detalles !== null && detalles.length <= 0) {
      const error = {
        message: 'No se han aplicado descuentos',
        appearance: 'warning',
      };
      return error;
    }
    const allIsOkey = true;
    return allIsOkey;
  };

  render() {
    const { isDataLoading, products, priceLists, monthSelectOptions, selectedPriceList, selectedValidity, isAdding, id } = this.state;

    const columns = [
      {
        dataField: 'proveedor',
        text: 'Empresa',
        sort: true,
        editable: false,
      },
      {
        dataField: 'marca',
        text: 'Marca',
        sort: true,
        editable: false,
      },
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'discount',
        text: 'Descuento',
        sort: true,
        editable: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    const rowClasses = () => {
      return 'bg-success text-white font-weight-bold';
    };

    return (
      <div>
        {this.alertChangePriceModifier()}
        <h1 className="page-title">{isAdding ? 'Modificador de precio nuevo' : `Modificador de precio #${id}`}</h1>
        <div>
          <Row>
            <Col md={6}>
              <FormSelectField
                id="selectedValidity"
                label="Mes de vigencia"
                value={selectedValidity}
                isRequired
                onChange={selectedValidity === '' ? (e) => this.handleValidityChange(e.target.name, e.target.value) : this.handleModal}
                choices={monthSelectOptions}
                choiceIdField="value"
                choiceLabelField="month"
              />
            </Col>
            <Col md={6}>
              <FormSelectField
                id="selectedPriceList"
                label="Lista de Precio"
                value={selectedPriceList}
                onChange={selectedPriceList === '' ? (e) => this.handleDataChange(e.target.name, e.target.value) : this.handleModal}
                choices={priceLists}
                choiceIdField="id"
                choiceLabelField="descripcion"
                placeholder="(todos)"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <DataTable
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  autoSelectText: true,
                })}
                columns={columns}
                data={products || []}
                keyField="id"
                isDataLoading={isDataLoading}
                rowClasses={rowClasses}
                addButton={this.onAddProducts}
                buttonText="Agregar productos"
                showExport={false}
              />
            </Col>
            <Col md={1}>
              <Button variant="primary" size="md" className="mt-4" onClick={() => this.onSaveEntity()}>
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withToastManager(BulkPriceModifier);
