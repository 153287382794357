import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm } from '../../components';
import APIClient from '../../services/APIClient';
import { ClienteTipo, Props } from '../Interfaces/interfaces';
import { ClienteTipoEditState } from './clienteTipoInterface';
import FormInput from '../../components/componentsTs/FormInput';

class ClienteTipoEdit extends React.Component<Props, ClienteTipoEditState> {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);

    const { id } = props.match.params;
    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get entity
    const entityResponse = await APIClient.get(`/cliente-tipos/${id}`);
    const entity = entityResponse.data.data;

    this.setState({ entity });
    return entity;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave: ClienteTipo) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/cliente-tipos', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/cliente-tipos/${id}`, entityToSave);
    }

    toastManager.add(
      `Línea ${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/cliente-tipos'),
    );
    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Tipo de Cliente nuevo' : `Tipo de Cliente #${id}`}</h1>

        <EntityEditForm onRetrieveEntity={this.onRetrieveEntity} onSaveEntity={this.onSaveEntity} addMode={isAdding}>
          <>
            <Row>
              <Col>
                <FormInput id="descripcion" label="Descripción" type="text" defaultValue={entity && entity.descripcion} required />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ClienteTipoEdit);
