import React from 'react';
import { Route } from 'react-router-dom';
import VendedorList from './VendedorList';
import VendedorEdit from './VendedorEdit';

const routes = () => [
  <Route path="/vendedores/nuevo" exact component={VendedorEdit} key="/vendedor/nuevo" />,
  <Route path="/vendedores/:id" component={VendedorEdit} key="/vendedor/:id" />,
  <Route path="/vendedores" exact component={VendedorList} key="/vendedor" />,
].map(route => route);

export default routes;
