import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Type } from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import { ArticuloListState } from './articuloInterface';
import FormSelect from '../../components/componentsTs/FormSelect';
import { Props } from '../Interfaces/interfaces';
import { QueryParameters } from '../Cliente/clientInterface';

class ArticuloList extends React.Component<Props, ArticuloListState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);
    const query = Utils.sanitizeQuery(['page'], this.props.location.search).page;
    const getPageNumber = parseInt(query);
    const pageNumber = Utils.isPositiveInteger(getPageNumber) ? getPageNumber : 1;
    this.state = {
      apiParams: '',
      articulos: [],
      isDataLoading: true,
      totalSize: 0,
      //FILTROS Y ROW EDIT
      marcas: [],
      lineas: [],
      proveedores: [],
      selectedMarcaIds: [],
      selectedLineaIds: [],
      selectedProveedorIds: [],
      filterStringMarca: '',
      filterStringLinea: '',
      filterStringProveedor: '',
      pageNumber,
    };

    this.loadArticulos = this.loadArticulos.bind(this);
  }

  componentDidMount() {
    this.getFilterAndEditData();
    this.loadArticulos();
  }

  onTableUpdate = async (queryParameters: QueryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    const { filterStringMarca, filterStringLinea, filterStringProveedor } = this.state;
    try {
      let { direction, field } = sorting;
      const filterQuery = `${filterStringMarca}&${filterStringLinea}&${filterStringProveedor}`;
      if (field && field === 'linea') {
        field = field.concat('_id');
      }
      if (field && field === 'marca') {
        field = field.concat('Codigo');
      }
      const apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || 'id'}&sortDir=${
        direction || 'desc'
      }&excludeAssocFields=imagenes`;
      const articulosRes = await APIClient.get(
        `/articulos?limit=${pagination.limit}&offset=${pagination.offset}&${apiParams}&${filterQuery}`,
      );
      const articulos = articulosRes.data.data;
      window.history.pushState({ page: pagination.page }, '', `?page=${pagination.page}`);
      this.setState({
        apiParams,
        articulos,
        totalSize: articulosRes.data.meta.total,
        pageNumber: pagination.page,
      });
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };
  async loadArticulos() {
    const { toastManager } = this.props;
    const { pageNumber } = this.state;
    const offset = (pageNumber - 1) * 10;
    try {
      // get paginated list of articulos sorts by entity id
      const articulosRes = await APIClient.get(
        `/articulos?limit=10&sortField=id&sortDir=desc&offset=${offset}&excludeAssocFields=imagenes`,
      );

      this.setState({
        articulos: articulosRes.data.data,
        isDataLoading: false,
        totalSize: articulosRes.data.meta.total,
      });
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  /////// %%%%%%% FILTROS %%%%%%% ///////

  getFilterAndEditData = async () => {
    const { toastManager } = this.props;
    try {
      // get marcas
      const marcasRes = await APIClient.get('/marcas');
      // get lineas
      const lineasRes = await APIClient.get('/lineas');
      // get proveedores
      const proveedoresRes = await APIClient.get('/proveedores');

      this.setState({
        marcas: marcasRes.data.data,
        lineas: lineasRes.data.data,
        proveedores: proveedoresRes.data.data,
      });
    } catch (err: any) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  createSelectAllButtons = (entityName: any) => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)} (
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}>
        Seleccionar todos
      </button>
      )
    </p>
  );

  handleDataChange = (e: any) => {
    const { id, value, options, multiple } = e.target;
    let filterName = '';
    let fieldName = '';
    switch (id) {
      case 'selectedMarcaIds':
        fieldName = 'marcaCodigo';
        filterName = 'filterStringMarca';
        break;
      case 'selectedLineaIds':
        fieldName = 'lineaId';
        filterName = 'filterStringLinea';
        break;
      case 'selectedProveedorIds':
        fieldName = 'proveedorId';
        filterName = 'filterStringProveedor';
        break;
      default:
        break;
    }
    if (multiple) {
      let whereOp = [...options].filter((opt) => opt.selected).length > 1 ? 'or' : 'eq';
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState((prevState) => ({
        ...prevState,
        [id]: values,
        [filterName]: Utils.prepareQueryToFilter(fieldName, whereOp, values),
      }));
    } else {
      this.setState((prevState: any) => ({
        ...prevState,
        [id]: value,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', [value]),
      }));
    }
  };

  handleSelectAll = (entityName: any) => {
    this.setState((prevState) => {
      const { marcas, lineas, proveedores } = prevState;
      let { selectedMarcaIds, selectedLineaIds, selectedProveedorIds, filterStringMarca, filterStringLinea, filterStringProveedor } =
        prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'marcas':
          selectedMarcaIds = marcas.map((cla) => cla.codigo);
          filterStringMarca = Utils.prepareQueryToFilter('marcaCodigo', 'or', selectedMarcaIds);
          break;
        case 'lineas':
          selectedLineaIds = lineas.map((cli) => cli.id);
          filterStringLinea = Utils.prepareQueryToFilter('lineaId', 'or', selectedLineaIds);
          break;
        case 'proveedores':
          selectedProveedorIds = proveedores.map((col) => col.id);
          filterStringProveedor = Utils.prepareQueryToFilter('proveedorId', 'or', selectedProveedorIds);
          break;
        default:
          break;
      }

      return {
        ...prevState,
        selectedMarcaIds,
        selectedLineaIds,
        selectedProveedorIds,
        filterStringMarca,
        filterStringLinea,
        filterStringProveedor,
      };
    });
  };

  /////////// %%%%%%%%%%% FIN_FILTROS %%%%%%%%%%%  ///////////

  //////////%%%%%%%%%% EDIT CELLS %%%%%%%%%%//////////
  updateRowField = async (row: any, column: any) => {
    const { toastManager } = this.props;
    const { articulos } = this.state;
    try {
      //call to api UPDATE
      await APIClient.patch(`/articulos/${row.id}`, row);
      toastManager.add(`Cambio guardado`, {
        appearance: 'success',
      });
      // Update the component's state with the changes
      const articuloToUpdateState = await APIClient.get(`/articulos/${row.id}`);
      const arrayToUpdateState = articulos.map((art) => {
        if (art.id === row.id) {
          return articuloToUpdateState.data.data;
        }
        return art;
      });
      this.setState((prevState) => ({
        ...prevState,
        articulos: arrayToUpdateState,
      }));
    } catch (err: any) {
      console.error('Error al actualizar el campo: ', err);
      toastManager.add(`No se pudo guardar la información. Inténtelo nuevamente. ${err}`, {
        appearance: 'error',
      });
    }
  };

  //////////////%%%%%%%%%%%%%%%FIN EDIT_CELLS%%%%%%%%%%%%%%%//////////////

  render() {
    const {
      marcas,
      lineas,
      proveedores,
      articulos,
      isDataLoading,
      totalSize,
      selectedMarcaIds,
      selectedLineaIds,
      selectedProveedorIds,
      pageNumber,
      apiParams,
    } = this.state;
    const events = {
      onMouseEnter: (e: any) => {
        e.target.style.cursor = `pointer`;
      },
    };
    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código ERP',
        sort: true,
        events: events,
      },
      {
        dataField: 'codigoEan13',
        text: 'EAN',
        sort: true,
        events: events,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        events: events,
      },
      {
        dataField: 'marcaCodigo',
        text: 'Marca',
        sort: true,
        events: events,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          const marcaFound = marcas.find((marca) => cellContent === marca.codigo);
          const marca = marcaFound ? marcaFound.descripcion : row.marca.descripcion;
          return marca;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            return marcas.map((marca) => {
              return { value: marca.codigo, label: marca.descripcion };
            });
          },
        },
      },
      {
        dataField: 'lineaId',
        text: 'Línea',
        sort: true,
        events: events,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          const lineaFound = lineas.find((lin) => parseInt(cellContent, 10) === lin.id);
          const linea = lineaFound ? lineaFound.descripcion : row.linea.descripcion;
          return linea;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            return lineas.map((lin) => {
              return { value: lin.id, label: lin.descripcion };
            });
          },
        },
      },
      {
        dataField: 'proveedorId',
        text: 'Proveedor',
        csvExport: false,
        events: events,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const proveedorFound = proveedores.find((proveedor) => cellContent === proveedor.id);
          const proveedor = proveedorFound ? proveedorFound.nombre : row.proveedor.nombre;
          return proveedor;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            return proveedores.map((proveedor) => {
              return { value: proveedor.id, label: proveedor.nombre };
            });
          },
        },
      },
      {
        dataField: 'isEliminado',
        text: 'Estado',
        //si el flag es false el artículo está activo
        csvExport: false,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
          const UISettings = { text: { true: 'Inactivo', false: 'Activo' }, color: { true: 'danger', false: 'success' } };
          return UIUtils.getArticulosEstadoBadge(cellContent, UISettings);
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            const arrayForOptionsEstado = [
              { value: false, label: 'Activo' },
              { value: true, label: 'Inactivo' },
            ];
            return arrayForOptionsEstado.map((estado) => {
              return { value: estado.value, label: estado.label };
            });
          },
        },
      },
      {
        dataField: 'isAgotado',
        text: 'Stock',
        //si el flag es false el artículo está en stock
        csvExport: false,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent !== 'number' ? parseInt(cellContent, 10) : cellContent;
          const UISettings = { text: { true: 'Agotado', false: 'En stock' }, color: { true: 'danger', false: 'success' } };
          return UIUtils.getArticulosEstadoBadge(cellContent, UISettings);
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            const arrayForOptionsEstado = [
              { value: 0, label: 'En stock' },
              { value: 1, label: 'Agotado' },
            ];
            return arrayForOptionsEstado.map((estado) => {
              return { value: estado.value, label: estado.label };
            });
          },
        },
      },
      {
        dataField: 'isPromo',
        text: 'Promoción',
        //si el flag es false el artículo está en NO está en promoción
        csvExport: false,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent !== 'number' ? parseInt(cellContent, 10) : cellContent;
          const UISettings = { text: { true: 'Sí', false: 'No' }, color: { true: 'success', false: 'danger' } };
          return UIUtils.getArticulosEstadoBadge(cellContent, UISettings);
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            const arrayForOptionsEstado = [
              { value: 0, label: 'No' },
              { value: 1, label: 'Sí' },
            ];
            return arrayForOptionsEstado.map((estado) => {
              return { value: estado.value, label: estado.label };
            });
          },
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent: any, row: any) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/articulos/${row.id}?page=${pageNumber}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Artículos</h1>

        <Card>
          <Card.Header>Filtros</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <FormSelect
                  id="selectedMarcaIds"
                  label={this.createSelectAllButtons('marcas')}
                  value={selectedMarcaIds}
                  onChange={this.handleDataChange}
                  choices={marcas}
                  choiceIdField="codigo"
                  choiceLabelField="descripcion"
                  multiple
                />
              </Col>
              <Col md={6}>
                <FormSelect
                  id="selectedLineaIds"
                  label={this.createSelectAllButtons('lineas')}
                  value={selectedLineaIds}
                  onChange={this.handleDataChange}
                  choices={lineas}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  multiple
                />
              </Col>
              <Col md={6}>
                <FormSelect
                  id="selectedProveedorIds"
                  label={this.createSelectAllButtons('proveedores')}
                  value={selectedProveedorIds}
                  onChange={this.handleDataChange}
                  choices={proveedores}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                  multiple
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col>
            <div className="d-flex flex-row justify-content-center">
              <Button
                disabled={isDataLoading}
                className="d-flex py-2 m-1 my-3"
                variant="primary"
                onClick={() =>
                  this.onTableUpdate({
                    freeText: '',
                    pagination: { limit: 10, offset: 0 },
                    sorting: {},
                  })
                }>
                {!isDataLoading ? <p className="m-0">Buscar</p> : <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />}
              </Button>
            </div>
          </Col>
        </Row>

        <DataTable
          remote={{
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false,
          }}
          totalSize={totalSize}
          columns={columns}
          data={articulos}
          onTableUpdate={this.onTableUpdate}
          isDataLoading={isDataLoading}
          keyField="id"
          exportURL={`/articulos/export.csv?${apiParams}`}
          addButton="/articulos/nuevo"
          updateRowField={this.updateRowField}
          pageNumber={pageNumber}
        />
      </div>
    );
  }
}

export default withToastManager(ArticuloList);
