import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Row, Col, Table, Modal, Card, Form, FormLabel
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment';
import { FacturaPopOver, EntityEditForm, FormInputField, FormSelectField } from '../index';

import Utils from '../../views/Utils';
import xubioService from '../../services/XubioService';

import config from '../../config';

const { makeSplitFacturacionBody } = xubioService;

class FacturaModal extends React.Component {
  static propTypes = {
    showFacturarModal: PropTypes.bool.isRequired,
    selectedEntrega: PropTypes.object.isRequired,
    buttonArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    facturacionBody: PropTypes.object.isRequired,
    onConfirmFacturar: PropTypes.func.isRequired,
    hideFacturarModal: PropTypes.func.isRequired,
    toastManager: PropTypes.object.isRequired,
    setFacturaSplitState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      showFacturarModal, selectedEntrega, buttonArray,
      facturacionBody, onConfirmFacturar, hideFacturarModal,
      setFacturaSplitState, ...otherProps
    } = props;
    this.state = {
      showFacturarModal,
      buttonArray,
      selectedEntrega,
      facturacionBody,
      onConfirmFacturar,
      facturasDesagregadasData: [],
      hideFacturarModal,
      disabled: otherProps.disable,
      setFacturaSplitState,
    };
  }

  makeRowsFromDetalles = (entity) => {
    const { selectedEntrega } = this.state;
    return entity.map((art) => {
      const key = `${art.producto.id}_${selectedEntrega.id}`;
      return (
        <tr key={key}>
          <td>{art.producto.nombre}</td>
          <td>{art.cantidad}</td>
          <td>{art.precio}</td>
          <td>{(parseFloat(art.precioconivaincluido) * art.cantidad).toFixed(2)}</td>
        </tr>
      );
    });
  };

  onRetrieveEntity = () => {};

  saveFactura = (entitityToSave, actionType) => {
    const { toastManager } = this.props;
    const { facturacionBody } = this.state;
    const fieldsToUpdate = Object.keys(entitityToSave);
    if (fieldsToUpdate.length > 0) {
      fieldsToUpdate.forEach((field) => {
        facturacionBody[field] = entitityToSave[field];
      });
    }
    this.setState({
      facturacionBody,
    });
    toastManager.add('Cambios realizados en la factura', {
      appearance: 'success',
    });
  };

  splitProducts = async (facturacionBody) => {
    const { facturasDesagregadasData, setFacturaSplitState } = this.state;
    const articulos = await Utils.splitProducts(facturacionBody.transaccionProductoItems, config.facturarXubio.splitMoneyLimit);
    const facturaDesagregada = JSON.parse(JSON.stringify(facturacionBody));
    facturaDesagregada.transaccionProductoItems = articulos.aTransladar;
    facturasDesagregadasData.push(facturaDesagregada);
    facturacionBody.transaccionProductoItems = articulos.seQuedan;

    const splitFacturacionBodyObjectToPost = makeSplitFacturacionBody(
      facturasDesagregadasData, facturacionBody,
    );
    setFacturaSplitState(splitFacturacionBodyObjectToPost);
    this.setState({
      facturacionBody,
      facturasDesagregadasData,
    });
  };

  renderFacturaPopOver = () => {
    const { facturasDesagregadasData } = this.state;
    return facturasDesagregadasData.map((factura, i) => (
      <FacturaPopOver
        key={`FacturarPopOver - ${i}`}
        text={`Factura ${i + 1}`}
        data={factura}
        makeRowsFromDetalles={this.makeRowsFromDetalles}
      />
    ));
  };

  handleOnHide = () => {
    const { hideFacturarModal, setFacturaSplitState } = this.state;
    this.setState({ showFacturarModal: false });
    hideFacturarModal();
    setFacturaSplitState({});
  };

  render() {
    const {
      showFacturarModal, buttonArray, selectedEntrega, facturacionBody,
      onConfirmFacturar, facturasDesagregadasData,
    } = this.state;
    const clientSelectData = showFacturarModal
      ? [
          { key: 1, nombre: selectedEntrega.cliente.razonSocial },
          { key: 2, nombre: 'Consumidor Final' },
        ]
      : [];
    const condicionPagoSelectData = showFacturarModal
      ? [
          { key: 1, nombre: 'Cuenta Corriente' },
          { key: 2, nombre: 'Al Contado' },
        ]
      : [];
    const condicionPagoCurrent = facturacionBody.dejarClienteDeLaEntrega ? facturacionBody.dejarClienteDeLaEntrega : '';
    const disabled = showFacturarModal
      ? facturacionBody.transaccionProductoItems.length === 0 : true;
    return (
      <Modal size="lg" show={showFacturarModal} onHide={() => this.handleOnHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Facturar con Xubio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EntityEditForm
            // onLoadForm={this.onLoadForm}
            onRetrieveEntity={this.onRetrieveEntity}
            onSaveEntity={this.saveFactura}
            buttons={buttonArray}>
            {showFacturarModal ? (
              <Fragment>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <FormSelectField
                          id="dejarClienteDeLaEntrega"
                          label="Sr.(es)"
                          type="text"
                          choices={clientSelectData}
                          choiceIdField="key"
                          choiceLabelField="nombre"
                          defaultValue={selectedEntrega.cliente.razonSocial}
                          required
                        />
                      </Col>
                      <Col>
                        <Form.Group className="form-group" controlId={clientSelectData.id}>
                          <FormLabel className="form-label">CUIT</FormLabel>
                          <p className="form-control-plaintext">
                            {condicionPagoCurrent !== '1' && condicionPagoCurrent !== '' ? '-' : selectedEntrega.cliente.documentoNumero}
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormSelectField
                          id="condicionDePago"
                          label="Condición de Pago"
                          type="text"
                          choices={condicionPagoSelectData}
                          choiceIdField="key"
                          choiceLabelField="nombre"
                          defaultValue={facturacionBody.condicionPago === 1 ? condicionPagoSelectData[0] : condicionPagoSelectData[1]}
                          required
                        />
                      </Col>
                      <Col md={6}>
                        <FormInputField
                          id="fechaVto"
                          label="Fecha de Vencimiento"
                          type="date"
                          defaultValue={moment(new Date(facturacionBody.fechaVto).toISOString()).format('YYYY-MM-DD')}
                          required
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Cant.</th>
                      <th>Precio</th>
                      <th>Impt. c/IVA</th>
                    </tr>
                  </thead>
                  <tbody>{this.makeRowsFromDetalles(facturacionBody.transaccionProductoItems)}</tbody>
                </Table>
                <Row className="bg-success m-2 p-2">
                  <Col md={1}>Total</Col>
                  <Col md={{ offset: 9, span: 2 }}>
                    {facturacionBody.transaccionProductoItems
                      .reduce((counter, art) => {
                        return counter + art.cantidad * parseFloat(art.precioconivaincluido);
                      }, 0)
                      .toFixed(2)}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInputField
                      id="descripcion"
                      label="Observaciones"
                      as="textarea"
                      type="text"
                      defaultValue={facturacionBody.descripcion}
                    />
                  </Col>
                </Row>
              </Fragment>
            ) : null}
          </EntityEditForm>
          <Row>
            <Col md={2}>
              <Button className="bg-success" onClick={() => this.splitProducts(facturacionBody)} disabled={disabled}>
                <FontAwesomeIcon icon={faPlus} fixedWidth className="mr-1" />
                Desagregar factura
              </Button>
            </Col>
            {facturasDesagregadasData.length ? this.renderFacturaPopOver() : null}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={!showFacturarModal} onClick={() => onConfirmFacturar()}>
            Enviar factura
          </Button>
          <Button variant="secondary" onClick={() => this.handleOnHide()}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withToastManager(FacturaModal);
