import React from 'react';
import { Route } from 'react-router-dom';
import ClienteList from './ProveedorList';
import ClienteEdit from './ProveedorEdit';
import ProviderDeliveryList from '../ProviderDelivery/ProviderDeliveryList';
import ProviderDeliveryEdit from '../ProviderDelivery/ProviderDeliveryEdit';

const routes = () => [
  <Route path="/proveedores/pedido" exact component={ProviderDeliveryList} key="/proveedores/pedido" />,
  <Route path="/proveedores/pedido/nuevo" exact component={ProviderDeliveryEdit} key="/proveedores/pedido/nuevo" />,
  <Route path="/proveedores/pedido/:id" exact component={ProviderDeliveryEdit} key="/proveedores/pedido/:id" />,
  <Route path="/proveedores/nuevo" exact component={ClienteEdit} key="/proveedores/nuevo" />,
  <Route path="/proveedores/:id" component={ClienteEdit} key="/proveedores/:id" />,
  <Route path="/proveedores" exact component={ClienteList} key="/proveedores" />,
].map(route => route);

export default routes;
