
import XLSX from 'xlsx';
import config from '../config';

// XLSX librarie
const generateWorkbookObject = (arrayData) => {
  const wb = XLSX.utils.book_new();
  wb.SheetNames.push("1");
  const ws_data = arrayData;  //a row with 2 columns
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["1"] = ws;
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  return wbout;
};

const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;
}

const CSVToArrays = (csv) => {
  const rowsArray = csv.split('┴');
  const response = rowsArray.map((rowString, index) => {
    if (index === 0) {
      return rowString.split(',');
    }
    return rowString.split('|');
  });
  return response;
};

// ///
const parseDataToExport = async (data, headersCol, exportExtraFields, customHeaders) => {
  let headers = headersCol.map(col => col.dataField);

  let headerNames = customHeaders[0] === '' ? headersCol.map(col => col.dataField) : customHeaders;
  if (exportExtraFields[0] !== '') {
    headers = headers.concat(exportExtraFields);
    headerNames = headerNames.concat(exportExtraFields);
  }
  const dataColumns = data.map(datO => headers.map((h) => {
    // nested data
    if (h.indexOf('.') > 0) {
      // only for level 1 nested (i.e. nested.length = 2)
      const nested = h.split('.');
      return datO[nested[0]] ? datO[nested[0]][nested[1]] : '';
    }
    return datO[h];
  }));
  dataColumns.unshift(headerNames.map((h) => { if (h.indexOf('.') > 0) { return h.split('.')[0]; } return h; }));
  return dataColumns;
};

const getNowDateLocale = () => {
  const dateNow = Date.now();
  const dateString = new Date(dateNow);
  return dateString.toLocaleDateString(config.date.locale);
};



export {
  generateWorkbookObject,
  s2ab,
  CSVToArrays,
  parseDataToExport,
  getNowDateLocale,
};
