import { faCircleNotch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withToastManager } from 'react-toast-notifications';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import DataTableFooter from '../../components/DataTableFooter';
import { buildDateAgo } from '../Utils';
import { DataTable } from '../../components';

class MonitorPendientes extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      articulosList: [],
      colTotals: {},
      generatedColors: [],
      isDataLoading: true,
      marcas: [],
      monitorPendientes: [],
      pendingsToRemove: [],
      pendingsToRemoveSelected: [],
      selectedLinea: {},
      selectedClienteId: 0,
      selectedRow: {},
      showCancel: false,
      showModal: false,
      showArticulosModal: false,
      showRemovePendingsModal: false,
      windowHeight: 0,
    };
  }

  componentDidMount() {
    this.loadData();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  handleRedirect = async () => {
    const { toastManager, history } = this.props;
    const { selectedClienteId } = this.state;
    try {
      const entregaAbierta = await APIClient.get(`/entregas?filter[clienteId][eq]=${selectedClienteId}&filter[estadoEntrega][eq]=EA`);
      if (entregaAbierta.data.data.length === 0) {
        history.push(`/entregas/nuevo/${selectedClienteId}`);
      } else {
        history.push(`/entregas/${entregaAbierta.data.data[0].id}`);
      }
    } catch (err) {
      toastManager.add('Ocurrió un error al intentar obtener la entrega', {
        appearance: 'error',
      });
    }
  };

  /**
   * Get /monitor-pendientes data and calculate totals.
   * Generate random colors for rows.
   * Save monitorPendientesData, columnTotals & generatedColors to state.
   */
  loadData = async () => {
    const { toastManager } = this.props;
    let monitorPendientesData = [];
    let marcas = [];
    try {
      const monitorPendientesRes = await APIClient.get('/monitor-pendientes');
      monitorPendientesData = monitorPendientesRes.data.data;
      marcas = await APIClient.get('/marcas?filter[eliminadoFlag][eq]=0');
      if (monitorPendientesData.length > 0) {
        await this.calculateRemovePendings(monitorPendientesData);
        const colTotals = {};
        monitorPendientesData.forEach((row, index) => {
          const keys = Object.keys(row);
          for (let i = 0; i < keys.length; i++) {
            const curVal = parseInt(row[keys[i]]);
            const key = keys[i];
            if (index === 0 && !isNaN(curVal)) {
              colTotals[key] = curVal;
            } else if (index === 0) {
              colTotals[key] = 0;
            } else if (curVal) {
              colTotals[key] += curVal;
            }
          }
          row.total = this.getRowTotals(row);
        });
        colTotals.total = this.getRowTotals(colTotals);

        // Get columns and generate random colors.
        const tableColumns = Object.keys(monitorPendientesData[0]);
        const randomColors = UIUtils.generateRandomColors(tableColumns.length);
        const bgColors = randomColors.map((color, index) => {
        // set grey for Razon Social and Totals columns.
          if (index === 1 || index === randomColors.length - 1) {
            return { backgroundColor: 'grey' };
          }
          return { backgroundColor: color };
        });
        this.setState({
          monitorPendientes: monitorPendientesData,
          colTotals,
          marcas: marcas.data.data,
          generatedColors: bgColors,
        });
      }
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };

  calculateRemovePendings = async (pendings) => {
    const daysAgo = 30;
    const parseDate = buildDateAgo(daysAgo);
    let { pendingsToRemove } = this.state;

    pendings.forEach((pending) => {
      if (pending['Fecha Entrega'] <= parseDate || pending['Fecha Entrega'] === null) {
        pendingsToRemove.push({
          customerId: pending.clienteId,
          customerName: pending['Razon Social'],
          deliveryDate: pending['Fecha Entrega']
        })
        this.setState({
          pendingsToRemove,
        });
      }
    });
    this.setState({showRemovePendingsModal: true})
  }

  handleModalRemovePendings = (show) => {
    this.setState({
      showRemovePendingsModal: show
    })
  }

  removePendingsModal = () => {
    // render articulos modal
    const { pendingsToRemove } = this.state;

    const columns_table = [
      {
        dataField: 'customerName',
        text: 'Razón social',
        sort: true,
        editable: false,
      },
      {
        dataField: 'deliveryDate',
        text: 'Fecha de entrega',
        sort: true,
        editable: false,
      }
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      //clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectPending,
    };

    return (
      <Modal size="xl" show={this.state.showRemovePendingsModal} onHide={() => this.handleModalRemovePendings(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Pendientes a limpiar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pendingsToRemove !== undefined && pendingsToRemove !== {} && (
            <DataTable
              isDataLoading={this.state.isDataLoading}
              selectRow={selectRowProps}
              columns={columns_table}
              data={pendingsToRemove || []}
              keyField="customerId"
              showExport={false}
              showSearch={false}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.onBulkRemovePendings()}>
            Listo
          </Button>
          <Button variant="secondary" onClick={() => this.handleModalRemovePendings(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onSelectPending = (row, isSelect) => {
  
    let { pendingsToRemoveSelected } = this.state;

    if (isSelect === true) {
      pendingsToRemoveSelected.push(row.customerId)
    } else {
      pendingsToRemoveSelected = pendingsToRemoveSelected.filter(p => p !== row.customerId);
    }

    this.setState({
      pendingsToRemoveSelected: pendingsToRemoveSelected,
    });
  
  }

  onBulkRemovePendings = async () => {
    let { pendingsToRemoveSelected } = this.state;

    const removePendings = pendingsToRemoveSelected.map(p => {
      return this.limpiarPendientes(p);
    });
    await Promise.all(removePendings);
    this.setState({
      showRemovePendingsModal: false
    });
  }

  calculateColumnWidth = (value) => {
    if (value.length * 18 < 50) {
      return 100;
    }
    return value.length * 10 + 50;
  };

  /** Receives column values string array,
   * generates column objects and returns an column object array.
   * @param  {string[]} columnValues - Column string array.
   * @return {Object[]} - column object array.
   */
  generateColumns = (columnValues) => {
    const { colTotals, generatedColors } = this.state;
    const columns = columnValues.map((colVal, index) => {
      const newCol = {
        accessor: colVal,
        sortable: true,
        sortMethod(a, b, descending) {
          // sort values while ignoring null
          if (!descending) {
            return (a === null || a === 0) - (b === null || b === 0) || a - b;
          }
          return (b === null || b === 0) - (a === null || a === 0) || a - b;
        },
      };

      // hide clienteId column
      if (colVal === 'clienteId') {
        newCol.show = false;
      }

      // return null when value is 0
      newCol.Cell = (cell) => {
        if (Number(cell.value) === 0) {
          return null;
        }
        if (colVal === 'Razon Social') {
          return <div title={cell.value}>{cell.value}</div>;
        }
        if (colVal === 'Fecha Entrega') {
          const curDate = moment.utc(cell.value);
          return (
            <div
              className={curDate < moment() ? 'text-center font-weight-bold text-danger' : 'text-center'}
              title={moment(cell.value).format('YYYY-MM-DD')}
            >
              {moment.utc(cell.value).format('YYYY-MM-DD')}
            </div>
          );
        }
        // apply classes for marcas and total columns
        return <div className={newCol.accessor === 'total' ? 'text-center' : 'text-right'}>{Number(cell.value)}</div>;
      };

      // set column headers
      newCol.Header = (dataObj) => {
        const { column } = dataObj;
        if (!column.id.match(/(Razon Social|total|Fecha Entrega)/)) {
          // set onClick event for text in marca columns, retrieve and set column totals and percentage.
          return (
            <span className="flex-column rt-th">
              <p className="border-bottom pb-2 text-center align-self-center text-uppercase text-nowrap">
                <button type="submit" className="link-button" onClick={e => this.getArticulosAndShowModal(column.id, e)}>
                  {colVal}
                  {' '}
                  <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              </p>
              <p className="mb-0">
                {colTotals[column.id]}
                {' '}
(
                {((colTotals[column.id] * 100) / colTotals.total).toFixed(2)}
                %)
              </p>
            </span>
          );
        }
        if (column.id.match(/clienteId/)) return true;

        if (column.id.match(/total/)) {
          // for total column, set classes to center values and show as table header.
          // Also, remove bottom margin from total number.
          return (
            <span className="rt-th">
              <p className="text-nowrap border-bottom pb-2 text-center align-self-center">{column.id}</p>
              <p className="mb-0">{colTotals.total}</p>
            </span>
          );
        }
        return (
          <span className="rt-th">
            <p className="text-center align-self-center">{column.id}</p>
          </span>
        );
      };
      // set generated color for header.
      const bgColor = generatedColors[index].backgroundColor;
      newCol.headerStyle = {
        backgroundColor: bgColor,
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        whiteSpace: 'no-wrap',
      };

      // set minWidth value according to accessor.length
      newCol.minWidth = this.calculateColumnWidth(newCol.accessor);
      return newCol;
    });
    return columns;
  };

  handleLimpiarBtnClick = async () => {
    // Check for open entregas for clienteId, show limpiar pendientes button if there are none.
    const { toastManager } = this.props;
    const { selectedClienteId } = this.state;
    this.setState({ isDataLoading: true });
    try {
      const entregaResponse = await APIClient.get(`/entregas?filter[clienteId][eq]=${selectedClienteId}&filter[estadoEntrega][eq]=EA`);
      const entregaInProgress = entregaResponse.data.data;
      if (entregaInProgress.length === 0) {
        this.setState({ showCancel: true });
      } else {
        toastManager.add('No se pueden limpiar los pendientes, hay una entrega en curso.', { appearance: 'warning', autoDismiss: true });
        this.setState({ showModal: false });
      }
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: ${err.message}`, { appearance: 'error' });
      this.setState({ showModal: false });
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  limpiarPendientes = async (customerId) => {
    const { toastManager } = this.props;
    const selectedClienteId = customerId || this.state.selectedClienteId;
    try {
      await APIClient.delete(`/monitor-pendientes/${selectedClienteId}`);
      this.setState((prevState) => {
        const monitorCopy = [...prevState.monitorPendientes];
        const newMonitorPendientes = monitorCopy.filter(row => row.clienteId !== selectedClienteId);
        return {
          ...prevState,
          monitorPendientes: newMonitorPendientes,
          selectedRow: [],
        };
      });
      toastManager.add('Se limpiaron los pendientes correctamente.', { appearance: 'success' });
    } catch (err) {
      toastManager.add(`Ocurrió un error: ${err.message}`, { appearance: 'error' });
      console.error(err);
    } finally {
      this.setState({ showCancel: false, showModal: false, isDataLoading: false });
    }
  };

  /**
   * Receives a string and an event object, then retrieves marca info by text,
   * and then gets articulos by marcaId.
   * @param  {string} text - column header to search for marca.
   * @param  {Object} e - event to stop sorting when clicking text.
   */
  getArticulosAndShowModal = async (text, e) => {
    const { toastManager } = this.props;
    e.stopPropagation();
    e.preventDefault();
    try {
      const marcaRes = await APIClient.get(`/marcas?filter[descripcion][like]=${text}`);
      const marca = marcaRes.data.data[0];
      const articulosRes = await APIClient.get(`/articulos?filter[marcaCodigo][eq]=${marca.codigo}`);
      this.setState({
        showArticulosModal: true,
        selectedLinea: marca,
        articulosList: articulosRes.data.data,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        showArticulosModal: false,
      });
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    }
  };

  /**
   * Receives row object, isSelect boolean, rowIndex integer and event object.
   * Retrieves solicitudes with estado_solicitud_codigo = SP for client,
   * then loops through the solicitud and its detalles searching
   * for undelivered detalles, pushing them into undeliveredDetalles array.
   * Then, saves undeliveredDetalles into state and shows modal.
   * @param  {Object} row - Row object.
   */
  getRowDataAndShowModal = async (row) => {
    const { toastManager } = this.props;
    const { showModal } = this.state;
    let rowDataRes = [];

    try {
      rowDataRes = await APIClient.get(
        `/monitor-pendientes/${row.clienteId}`,
      );
      const undeliveredDetalles = [];
      rowDataRes.data.data.forEach((detalle) => {
        if (detalle.solicitado > detalle.entregado) {
          detalle.noEntregado = detalle.solicitado - detalle.entregado;
          undeliveredDetalles.push(detalle);
        }
      });
      this.setState({ selectedClienteId: row.clienteId, selectedRow: undeliveredDetalles, showModal: !showModal });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  /** Receives row data & returns onClick event for row, passing rowInfo to getRowDataAndShowModal function.
   * @param  {Object} state - Table object.
   * @param  {Object} rowInfo - Row data object.
   * @return {Object} onClick event property for rows.
   */
  getRowProps = (state, rowInfo) => ({
    onClick: () => this.getRowDataAndShowModal(rowInfo.original),
  });

  /** Receives row object, loops through its values and returns a total value.
   * @param  {Object} row - Row object.
   * @return  {number} - Row total.
   */
  getRowTotals = (row) => {
    const keys = Object.keys(row);
    let total = 0;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = row[key];
      if (!key.match(/Razon Social|Fecha Entrega|clienteId/) && value !== null) {
        value = Number(value);
        total += value;
      }
    }
    return total;
  };

  renderEntregasModal = () => {
    const {
      isDataLoading, marcas, selectedRow, showModal, showCancel,
    } = this.state;

    const columns = [
      {
        dataField: 'articuloId',
        text: 'articuloId',
        hidden: true,
      },
      {
        dataField: 'erpCodigo',
        text: 'Código ERP',
      },
      {
        dataField: 'marcaCodigo',
        text: 'Marca',
        formatter: (cell, row, data, extra) => {
          const marca = extra.find(elem => elem.codigo === row.marcaCodigo);
          return marca.descripcion;
        },
        formatExtraData: marcas,
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
      },
      {
        dataField: 'noEntregado',
        text: 'Pendientes de Entrega',
        formatter: cellContent => <div className="text-right">{cellContent}</div>,
      },
    ];

    return (
      <Modal size="lg" show={showModal} onHide={() => this.setState({ showModal: false, showCancel: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Pendientes de Entrega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable columns={columns} hover data={selectedRow} keyField="articuloId" />
        </Modal.Body>

        <Modal.Footer>
          {showCancel ? (
            <>
              <strong className="text-danger">¡Advertencia! Este proceso no se puede deshacer. </strong>
              <Button
                disabled={isDataLoading}
                variant="danger"
                onClick={() => {
                  this.setState({ isDataLoading: true });
                  this.limpiarPendientes();
                }}
              >
                {isDataLoading ? <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" /> : 'Confirmar'}
              </Button>
            </>
          ) : (
            <>
              <Button variant="danger" onClick={this.handleLimpiarBtnClick}>
                Limpiar Pendientes
              </Button>
              <Button variant="primary" onClick={() => this.handleRedirect()}>
            Generar Entrega
              </Button>
            </>
          )}
          <Button variant="secondary" onClick={() => this.setState({ showModal: false, showCancel: false })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderArticulosModal = () => {
    const { selectedLinea, showArticulosModal, articulosList } = this.state;
    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        hidden: true,
      },
      {
        dataField: 'erpCodigo',
        text: 'Codigo ERP',
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
      },
    ];
    return (
      <Modal show={showArticulosModal} onHide={() => this.setState({ showArticulosModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedLinea.descripcion}
            {' '}
- Articulos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable columns={columns} hover data={articulosList} keyField="id" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showArticulosModal: false })}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  updateWindowDimensions = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  render() {
    const { isDataLoading, monitorPendientes, windowHeight } = this.state;
    const otherProps = { customHeaders: [''] };
    let columns = [];

    if (monitorPendientes.length > 0) {
      // generate columns
      const colValues = Object.keys(monitorPendientes[0]);
      columns = this.generateColumns(colValues);
    }

    return (
      <div>
        {this.renderEntregasModal()}
        {this.renderArticulosModal()}
        {this.removePendingsModal()}

        <h1 className="page-title">Monitor de Pendientes</h1>
        {monitorPendientes.length > 0 && !isDataLoading ? (
          <div>
            <ReactTable
              data={monitorPendientes}
              columns={columns}
              defaultPageSize={monitorPendientes.length}
              showPagination={false}
              getTrProps={this.getRowProps}
              className="react-bootstrap-table table-bordered table -highlight"
              style={{
                cursor: 'pointer',
                borderSpacing: 0,
                height: parseInt(`${windowHeight * 0.78}`, 10),
                display: 'flex',
              }}
            />
            <DataTableFooter
              cols={[{ dataField: 'descripcion' }, { dataField: 'erpCodigo' }, { dataField: 'marca' }, { dataField: 'razonSocial' }, { dataField: 'pendiente' }]}
              exportURL="/monitor-pendientes/export"
              exportFileName="Pendientes"
              exportCustomHeaders={['Producto', 'erp', 'Marca', 'Cliente', 'Pendiente']}
              {...otherProps}
        />
          </div>
        ) : (
          <div>¡No hay entregas pendientes!</div>
        )}
      </div>
    );
  }
}

export default withToastManager(MonitorPendientes);
