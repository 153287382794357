import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Modal, Row,
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Moment from 'react-moment';
import moment from 'moment';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import {
  DataTable, EntityEditForm, FormInputField, FormSelectField,
} from '../../components';

class SolicitudEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      articulosList: [],
      clientes: [],
      detalles: [],
      detallesToDelete: [],
      entity: {},
      isDataLoading: true,
      isEditable: false,
      listasPrecios: [],
      formasDePago: [],
      selectedDetalles: [],
      selectedListaPrecioId: 0,
      puntosEntrega: [],
      selectedDetalle: {},
      showAddModal: false,
      showListaPrecioModal: false,
      showDeleteModal: false,
      updatedDetalles: [],
      vendedores: [],
    };
  }

  /**
   * If row is selected, then add to selectedDetalles array and save state.
   * @param  {string} oldValue - old cell value
   * @param  {string} newValue - new cell value
   * @param  {Object} row - entire row object
   */
  afterSaveSelectedDetalle = (oldValue, newValue, row) => {
    if (row.isSelected) {
      this.setState((prevState) => {
        const selectedDetalles = [...prevState.selectedDetalles];
        const selectedDetalle = {
          articulo: row,
          solicitudId: this.state.id,
          articuloId: row.id,
          precioTotal: row.precio,
          posicion: this.findMaxPosicion(prevState.detalles),
          listaPrecioId: this.state.entity.listaPrecioId,
          formaPagoId: this.state.entity.formaPagoId,
          solicitudDetalleTalle: {
            precio: row.precio,
            talleNombre: 'ST',
            cantidad: 1,
            entregado: 0,
          },
        };
        selectedDetalles.push(selectedDetalle);
        return { selectedDetalles };
      });
    }
  };

  /**
   * Save changes after changing prices on detalles.
   * @param  {string} oldValue - old cell value
   * @param  {string} newValue - new cell value
   * @param  {Object} detalle - entire detalle object
   */
  afterSaveUpdatedDetalle = (oldValue, newValue, detalle) => {
    const updatedDetalles = [...this.state.updatedDetalles];
    if (detalle.precio !== null) {
      const { precio, cantidad } = detalle.solicitudDetalleTalle;
      if (cantidad) {
        detalle.precioTotal = (parseFloat(precio) * cantidad).toFixed(2);
      } else {
        detalle.precioTotal = parseFloat(precio);
      }
    }
    let existingUpdDet = updatedDetalles.find(updatedDetalle => updatedDetalle.id === detalle.id);
    if (existingUpdDet === undefined) {
      updatedDetalles.push(detalle);
    } else {
      existingUpdDet = detalle;
    }
    this.setState(prevState => ({
      ...prevState,
      updatedDetalles,
    }));
  };

  /**
   * Render articulos modal.
   * @return {JSX.Element} articulosList modal element
   */
  renderArticulosListModal = () => {
    const { articulosList, isDataLoading, showAddModal } = this.state;
    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => (
          <span>
            {row.erpCodigo}
            <br />
            <small title="EAN">{row.codigoEan13}</small>
          </span>
        ),
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
        editable: false,
      },
      {
        dataField: 'linea.descripcion',
        text: 'Línea',
        sort: true,
        editable: false,
      },
      {
        dataField: 'coleccion.descripcion',
        text: 'Colección',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
        formatter: (cellContent, row) => {
          if (cellContent) {
            if (cellContent.search(/\.\d{1,2}$/g) > 0) {
              if (cellContent.search(/\.\d{1}$/g) > 0) {
                return cellContent.concat('0');
              }
              return cellContent;
            }
            return cellContent.concat('.00');
          }
          return <span className="text-danger font-weight-bold">No tiene precio.</span>;
        },
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectArticulo,
    };

    return (
      <Modal
        size="xl"
        show={showAddModal}
        onHide={() => this.setState({ showAddModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Artículos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {articulosList !== undefined && articulosList !== {} && (
            <DataTable
              isDataLoading={isDataLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={articulosList || []}
              keyField="id"
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                autoSelectText: true,
                afterSaveCell: this.afterSaveSelectedDetalle,
              })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.saveSelectedDetalles}>
            Listo
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showAddModal: false })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /**
   * Render modal to change Lista Precio.
   * @returns {JSX.Element} confirmation modal
   */
  renderChangeListaPrecioModal = () => (
    <Modal
      size="md"
      show={this.state.showListaPrecioModal}
      onHide={() => this.setState({ showListaPrecioModal: false })}
    >
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea cambiar la lista de precios?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.handleListaPrecioChange}>
          Confirmar
        </Button>
        <Button variant="secondary" onClick={() => this.setState({ showListaPrecioModal: false })}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  /**
   * Gets max posicion value and returns value incresead by 1.
   * @param  {Object[]} detalles - array de objetos detalle
   * @return {int} nextPosicion - max value +1.
   */
  findMaxPosicion = (array) => {
    const { selectedDetalles } = this.state;
    let nextPosicion = 0;
    array.forEach((data) => {
      const { posicion } = data;
      if (posicion >= nextPosicion) {
        nextPosicion = posicion + 1;
      }
    });
    nextPosicion += selectedDetalles.length + 1;
    return nextPosicion;
  };

  /**
   * Get new lista de precio prices and save to state.
   */
  handleListaPrecioChange = async () => {
    const { selectedListaPrecioId } = this.state;
    const newListaPrecio = await APIClient.get(
      `/listas-precio/${selectedListaPrecioId}`,
    );
    const detallePrecios = newListaPrecio.data.data.detalles;
    this.setState((prevState) => {
      const detalles = [...prevState.detalles];
      const updatedDetalles = detalles.map((detalle) => {
        const detallePreciosDet = detallePrecios.find(
          detPrecios => detPrecios.articuloId === detalle.articuloId,
        );
        if (detallePreciosDet) {
          return {
            ...detalle,
            precioTotal: parseFloat(
              detallePreciosDet.precio * detalle.solicitudDetalleTalle.cantidad,
            ).toFixed(2),
            solicitudDetalleTalle: {
              ...detalle.solicitudDetalleTalle,
              precio: detallePreciosDet.precio,
            },
          };
        }
        delete detalle.solicitudDetalleTalle.precio;
        delete detalle.solicitudDetalleTalle.precioTotal;
        return { ...detalle };
      });
      return { detalles: updatedDetalles, showListaPrecioModal: false, isDataLoading: false };
    });
  };

  calculateTotals = (detalles) => {
    let total = 0;
    if (detalles.length > 0) {
      detalles.forEach((det) => {
        const { solicitudDetalleTalle: sdt } = det;
        if (sdt) {
          total += (sdt.precio * sdt.cantidad);
        }
      });
    }
    return total;
  }

  /**
   * Remove deleted detalle from detalles array. If it's not new, add to detallesToDelete. Save to state.
   */
  onConfirmDelete = () => {
    this.setState((prevState) => {
      const detallesToDelete = [...prevState.detallesToDelete];
      const updatedDetalles = [...prevState.updatedDetalles];
      const detalles = [...prevState.detalles];
      const selectedDetalle = { ...prevState.selectedDetalle };

      if (prevState.entity.detalles.length > 0) {
        const isDeletedDetalleNew = prevState.entity.detalles.find(
          detalle => detalle.id === selectedDetalle.id,
        );
        if (isDeletedDetalleNew === undefined) {
          const updatedIndex = updatedDetalles.findIndex(
            detalle => detalle.id === selectedDetalle.id,
          );
          updatedDetalles.splice(updatedIndex, 1);
        } else {
          detallesToDelete.push(selectedDetalle);
        }
        const selectedIndex = detalles.findIndex(detalle => detalle.id === selectedDetalle.id);
        detalles.splice(selectedIndex, 1);
      }

      return {
        detalles,
        detallesToDelete,
        selectedDetalle: {},
        showDeleteModal: false,
        updatedDetalles,
      };
    });
  };

  /**
   * Show modal and save selectedDetalle in state.
   * @param  {Object} row - row object from table
   */
  showDeleteConfirmModal = (row) => {
    this.setState({ showDeleteModal: true, selectedDetalle: row });
  };

  /**
   * Show deleteDetalle modal to confirm selection to delete.
   * @returns {JSX.Element} confirmation modal
   */
  renderDeleteDetalleModal = () => (
    <Modal
      size="md"
      show={this.state.showDeleteModal}
      onHide={() => this.setState({ showDeleteModal: false })}
    >
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar este detalle?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.onConfirmDelete}>
          Eliminar
        </Button>
        <Button
          variant="secondary"
          onClick={() => this.setState({ showDeleteModal: false, selectedDetalle: {} })}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  /**
   * Copy detalles and selectedDetalles from state and merge them.
   */
  saveSelectedDetalles = () => {
    this.setState((prevState) => {
      const newDetalles = [...prevState.selectedDetalles];
      const selectedDetalles = newDetalles.map((detalle) => {
        if (detalle.articulo.isSelected === true) {
          return detalle;
        }
        return null;
      });
      return {
        detalles: [...prevState.detalles, ...selectedDetalles],
        selectedDetalles: [],
        updatedDetalles: [...prevState.updatedDetalles, ...prevState.selectedDetalles],
        showAddModal: false,
      };
    });
  };

  /**
   * Get articulos, add precio and isSelected properties to all objects in array. Then save state.
   */
  showAddDetalleModal = async () => {
    const { detalles, entity } = this.state;
    const detalleArticuloIds = detalles.map(detalle => detalle.articuloId);
    try {
      const articulosListRes = await APIClient.get(
        `/articulos?filter[id][notIn]=${detalleArticuloIds}`,
      );
      const articulosList = articulosListRes.data.data;
      const articulosPrices = await APIClient.get(
        `/listas-precio/${entity.listaPrecioId}`,
      );
      const detallePrecios = articulosPrices.data.data.detalles;
      articulosList.forEach((articulo) => {
        articulo.precio = 0;
        for (let i = 0; i < detallePrecios.length; i++) {
          if (detallePrecios[i].articuloId === articulo.id) {
            articulo.precio = detallePrecios[i].precio;
            articulo.isSelected = false;
          }
        }
      });
      this.setState({ showAddModal: true, isDataLoading: false, articulosList });
    } catch (err) {
      console.error('Ocurrió un error al traer los precios de los artículos!', err);
    }
  };

  /**
   * Show listaPrecio modal to confirm price changes and save selectedListaPrecioId in state.
   * @param  {Object} event - event object
   */
  onChangeListaPrecio = (e) => {
    this.setState({
      showListaPrecioModal: true,
      selectedListaPrecioId: parseInt(e.target.value),
      isDataLoading: true,
    });
  };

  /**
   * Get data for form and save to state.
   */
  onLoadForm = async () => {
    try {
      const clienteResponse = await APIClient.get('/clientes?sortField=razonSocial&sortDir=asc');
      const listaPrecioResponse = await APIClient.get('/listas-precio');
      const vendedorResponse = await APIClient.get('/vendedores');
      const puntosEntregaResponse = await APIClient.get('/puntos-entrega');
      const formasDePagoResponse = await APIClient.get('/formas-pago');
      
      formasDePagoResponse.data.data.unshift({
        description: "No tiene ninguna forma de pago seleccionada",
      })

      this.setState({
        clientes: clienteResponse.data.data,
        isDataLoading: false,
        listasPrecios: listaPrecioResponse.data.data,
        formasDePago: formasDePagoResponse.data.data,
        puntosEntrega: puntosEntregaResponse.data.data,
        vendedores: vendedorResponse.data.data,
      });
    } catch (error) {
      console.error('Cargando información del formulario.', error);
      this.setState({
        isDataLoading: false,
      });
    }
  };

  /**
   * Retrieve entity (array), add precioTotal property to each element and save to state.
   */
  onRetrieveEntity = async () => {
    const { id, isAdding } = this.state;
    let { isEditable } = this.state;

    const entityResponse = await APIClient.get(`/solicitudes/${id}`);
    const entity = entityResponse.data.data;
    const puntosEntregaResponse = await APIClient.get(
      `/clientes/${entity.cliente.id}/puntos-entrega`,
    );
    const puntosDeEntrega = puntosEntregaResponse.data.data.map((pe) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${!pe.depto ? '' : ' ' + pe.depto}
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`
      return pe;
    })

    const detalles = [...entity.detalles];

    detalles.forEach((detalle) => {
      const { solicitudDetalleTalle: sdt } = detalle;
      if (sdt) {
        detalle.precioTotal = parseFloat((
          sdt.cantidad * sdt.precio
        ).toFixed(2));
        sdt.precio = parseFloat(sdt.precio);
      } else {
        detalle.precioTotal = 0;
      }
    });
    if (!isAdding && entity.estadoSolicitudCodigo.match(/SA|SP/)) {
      isEditable = true;
    }
    this.setState({ entity, detalles, isEditable, puntosEntrega: puntosDeEntrega, });
    return entity;
  };

  /**
   * Save entity.
   */
  onSaveEntity = async (entityToSave) => {
    const {
      id, isAdding, detalles, entity, detallesToDelete, updatedDetalles,
    } = this.state;

    const { history, toastManager } = this.props;
    const codigoArticulosSinPrecio = [];

    detalles.forEach((detalle) => {
      if (!detalle.solicitudDetalleTalle.precio) {
        codigoArticulosSinPrecio.push(detalle.Articulo.erpCodigo);
      }
    });

    // Check for articulos without price, prevent saving if there are any.
    if (codigoArticulosSinPrecio.length > 0) {
      if (codigoArticulosSinPrecio.length === 1) {
        const error = new Error(`El articulo ${codigoArticulosSinPrecio} no tiene precio cargado.`);
        throw error;
      }
      const codigosSinPrecio = codigoArticulosSinPrecio.join(', ').replace(/, ([^,]*)$/, ' y $1');
      const error = new Error(`Los articulos: ${codigosSinPrecio} no tienen precio cargado.`);
      throw error;
    }

    // If there are updatedDetalles, change precioEntrega if necessary.
    if (updatedDetalles.length > 0) {
      updatedDetalles.forEach(detalle => delete detalle.articulo);

      if (entity.estadoSolicitudCodigo !== 'SA') {
        let precioEntrega = 0.0;
        entity.detalles.forEach((detalle) => {
          precioEntrega
            += detalle.solicitudDetalleTalle.cantidad * detalle.solicitudDetalleTalle.precio;
        });
        entityToSave.precioEntrega = precioEntrega.toFixed(2);
      }
      entityToSave.detalles = [...updatedDetalles];
    }

    // If the listaPrecioId has changed, set the new listaPrecioId in every detalle.
    if (entityToSave.listaPrecioId) {
      if (entityToSave.detalles) {
        entityToSave.detalles = [...detalles, entityToSave.detalles];
      }
      entityToSave.detalles = [...detalles];
      entityToSave.detalles.forEach((detalle, listaPrecioId) => {
        detalle.listaPrecioId = listaPrecioId;
      });
    }

    if (detallesToDelete.length > 0) {
      const deletedDetallesIds = detallesToDelete.map(detalle => detalle.id);
      entityToSave.assocToDelete = { detalles: [...deletedDetallesIds] };
    }

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/solicitudes', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/solicitudes/${id}`, entityToSave);
    }

    toastManager.add(
      `Solicitud ${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/solicitudes'),
    );
    return saveResponse.data.data;
  };

  prepareToSave = (entityToSave) => {
    const { updatedDetalles } = this.state;
    entityToSave.detalles = updatedDetalles;
    return entityToSave;
  }

  /**
   * Toggle isSelected value from articulo.
   * @param  {Object} row
   * @param  {boolean} isSelect
   */
  onSelectArticulo = (row, isSelect) => {
    const { articulosList } = this.state;
    const articulo = articulosList.find(art => art.id === row.id);
    articulo.isSelected = isSelect;
    if (articulo.isSelected) {
      this.setState((prevState) => {
        const { id, entity } = prevState;
        const selectedDetalles = [...prevState.selectedDetalles];
        const selectedDetalle = {
          articulo: row,
          solicitudId: id,
          articuloId: row.id,
          precioTotal: row.precio,
          posicion: this.findMaxPosicion(prevState.detalles),
          listaPrecioId: entity.listaPrecioId,
          formaPagoId: entity.formaPagoId,
          solicitudDetalleTalle: {
            precio: row.precio,
            talleNombre: 'ST',
            cantidad: 1,
            entregado: 0,
          },
        };
        selectedDetalles.push(selectedDetalle);
        return { ...prevState, selectedDetalles };
      });
    }
  };

  render() {
    const {
      id,
      entity,
      clientes,
      detalles,
      isAdding,
      isDataLoading,
      isEditable,
      listasPrecios,
      formasDePago,
      puntosEntrega,
      vendedores,
    } = this.state;

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: true,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const hours = UIUtils.generateHours();

    const total = this.calculateTotals(detalles);

    const columns = [
      {
        dataField: 'articulo.erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'articulo.descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'solicitudDetalleTalle.cantidad',
        text: 'Cant.',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: isEditable,
        validator: (newValue, row) => {
          if (newValue < row.solicitudDetalleTalle.entregado) {
            newValue = row.solicitudDetalleTalle.cantidad;
            return {
              valid: false,
              message: 'La cantidad solicitada no puede ser menor a la entregada.',
            };
          }
        },
      },
      {
        dataField: 'solicitudDetalleTalle.entregado',
        text: 'Entreg.',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: false,
      }, {
        dataField: 'solicitudDetalleTalle.precio',
        text: 'Precio/Unid.',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: isEditable,
        formatter: (cellContent, row) => {
          if (!cellContent) {
            return <span className="text-danger font-weight-bold">No tiene precio.</span>;
          }
          return cellContent;
        },
      },
      {
        dataField: 'precioTotal',
        text: 'Total',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: isEditable,
        formatter: (cellContent, row) => {
          if (cellContent === '0.00' || cellContent === undefined) {
            return <span className="text-danger font-weight-bold">No tiene precio.</span>;
          }
          return cellContent;
        },
      },
      {
        dataField: 'isSelected',
        text: '',
        sort: false,
        hidden: !isEditable,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        formatter: (cell, row, rowIndex) => {
          if (!row.solicitudDetalleTalle || row.solicitudDetalleTalle.entregado > 0 || !isEditable) {
            return;
          }
          return (
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => this.showDeleteConfirmModal(row)}
              fixedWidth
              title="Eliminar detalle"
              size="xs"
            />
          );
        },
        csvExport: false,
      },
    ];

    return (
      <div>
        {this.renderDeleteDetalleModal()}
        {this.renderArticulosListModal()}
        {this.renderChangeListaPrecioModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          isEditable={isEditable}
          prepareToSave={this.prepareToSave}
        >
          <h1 className="d-flex my-3">
            {isAdding ? 'Solicitud nueva' : 'Solicitud'}
            {' '}
            {entity.codigo === null ? `#${id}` : entity.codigo && `${entity.codigo}`}
            {entity.estadoSolicitudCodigo && (
              <div className="ml-3 d-flex">
                {UIUtils.getSolicitudEstadoBadge(entity.estadoSolicitudCodigo)}
              </div>
            )}
          </h1>
          <Row>
            <Col md={6}>
              <FormSelectField
                id="clienteId"
                label="Cliente"
                defaultValue={entity.clienteId}
                choices={clientes}
                choiceIdField="id"
                choiceLabelField="razonSocial"
                disabled={!isEditable}
              />
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label">Fecha de creación</label>
                    <p className="form-control-plaintext">
                      <Moment interval={0} format="DD/MM/YYYY">
                        {entity.createdAt}
                      </Moment>
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <FormInputField
                    id="fechaVencimiento"
                    label="Fecha de Vencimiento"
                    type="date"
                    defaultValue={
                      entity.fechaVencimiento
                        ? moment.utc(entity.fechaVencimiento).format('YYYY-MM-DD')
                        : ''
                    }
                    min={moment().format('YYYY-MM-DD')}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <FormSelectField
                id="vendedores"
                label="Ejecutivo comercial"
                type="text"
                defaultValue={entity.vendedor_id}
                choices={vendedores}
                choiceIdField="id"
                choiceLabelField="nombre"
                disabled={!isEditable}
                required
              />
            </Col>
            <Col md={4}>
              <FormSelectField
                id="listaPrecioId"
                label="Lista de Precios"
                type="text"
                defaultValue={entity.listaPrecioId}
                choices={listasPrecios}
                choiceIdField="id"
                choiceLabelField="descripcion"
                onChange={this.onChangeListaPrecio}
                disabled={!isEditable}
                required
              />
            </Col>
            <Col md={4}>
              <FormSelectField
                id="formaPagoId"
                label="Forma de Pago"
                type="text"
                defaultValue={entity.formaPagoId}
                choices={formasDePago}
                choiceIdField="id"
                choiceLabelField="description"
            
                disabled={!isEditable}
                required
              />
            </Col>
            <Col md={4}>
              <FormInputField
                id="facturaTipo"
                label="Tipo de factura"
                defaultValue={entity.facturaTipo}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="form-label">Transferencista</label>
                <p className="form-control-plaintext">{entity.transferencistaName}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInputField
                id="observaciones"
                label="Observaciones"
                as="textarea"
                defaultValue={entity.observaciones}
                placeholder={!entity.observaciones ? 'No hay observaciones.' : ''}
                disabled={!isEditable}
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Card.Title>
                <h2 className="d-flex">Entrega</h2>
              </Card.Title>
              <Row>
                <Col md={8}>
                  <Row>
                    <Col md={4}>
                      <FormInputField
                        id="fechaEntrega"
                        type="date"
                        label="Fecha de Entrega:"
                        defaultValue={moment.utc(entity.fechaEntrega).format('YYYY-MM-DD')}
                        min={moment().format('YYYY-MM-DD')}
                        disabled={!isEditable}
                        required
                      />
                    </Col>
                    <Col md={2}>
                      <FormSelectField
                        label="Desde:"
                        type="text"
                        id="horaEntregaDesde"
                        defaultValue={entity.horaEntregaDesde}
                        choices={hours}
                        choiceIdField="hour"
                        choiceLabelField="hour"
                        disabled={!isEditable}
                      />
                    </Col>
                    <Col md={2}>
                      <FormSelectField
                        label="Hasta:"
                        type="text"
                        id="horaEntregaHasta"
                        defaultValue={entity.horaEntregaHasta}
                        choices={hours}
                        choiceIdField="hour"
                        choiceLabelField="hour"
                        disabled={!isEditable}
                      />
                    </Col>
                    <Col md={4}>
                      <div className="form-group">
                        <label className="form-label">Fecha de Confirmación</label>
                        <p className="form-control-plaintext">
                          {entity.fechaConfirmacion ? (
                            <Moment interval={0} format="DD/MM/YYYY">
                              {entity.fechaConfirmacion}
                            </Moment>
                          ) : (
                              '-'
                            )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <FormSelectField
                    label="Punto de Entrega:"
                    type="text"
                    id="puntoEntregaId"
                    defaultValue={entity.puntoEntregaId}
                    choices={puntosEntrega}
                    choiceIdField="id"
                    choiceLabelField="descripcion"
                    disabled={!isEditable}
                    required
                  />
                  <Row className="text-center ml-2">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" fixedWidth size="xs" />
                    <p className="text-center font-weight-light">
                      {entity.PuntoEntrega
                        && `${entity.PuntoEntrega.localidad}, ${entity.PuntoEntrega.calle} ${entity.PuntoEntrega.numero
                        }`}
                    </p>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <h2 className="d-flex my-3">Detalle</h2>
          <Row>
            <Col className="p-3">
              {detalles && (
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.afterSaveUpdatedDetalle,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={detalles}
                  keyField="posicion"
                  isDataLoading={isDataLoading}
                  addButton={isEditable && this.showAddDetalleModal}
                  enablePagination={false}
                  total={total}
                  getExportData={() => detalles}
                  exportFileName= {`solicitud ${entity.codigo ? entity.codigo : ''}`}
                  customHeaders={columns.map(col => col.text)}
                />
              )}
            </Col>
          </Row>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(SolicitudEdit);
