import React from 'react';
import { Route } from 'react-router-dom';
import PrecioModificadorList from './PrecioModificadorList';
import PrecioModificadorEdit from './PrecioModificadorEdit';
import BulkPriceModifier from './BulkPriceModifier';

const routes = () => [
  <Route path="/modificadores-precio/nuevo" exact component={PrecioModificadorEdit} key="/modificadores-precio/nuevo" />,
  <Route path="/modificadores-precio/:id" component={PrecioModificadorEdit} key="/modificadores-precio/:id" />,
  <Route path="/modificadores-precio" exact component={PrecioModificadorList} key="/modificadores-precio" />,
  <Route path="/modificadores-precio-masivo" exact component={BulkPriceModifier} key="/modificadores-precio-masivo" />,
  <Route path="/modificadores-precio-masivo/:id" component={BulkPriceModifier} key="/modificadores-precio-masivo/:id" />,
].map(route => route);

export default routes;
