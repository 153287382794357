import React from 'react';
import {
  Card, Container, Form, Button, Image,
} from 'react-bootstrap';
import APIClient from '../../services/APIClient';
import Security from '../../services/Security';

import './Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorMessage: '',
      logo: {},
    };
  }

  componentDidMount() {
    this.getLogo();
  }

  getLogo () {
    APIClient.get('/cms/logo').then((res) => {
      this.setState({ logo: res.data.data });
    }).catch ((err) => {
      console.error(`No se pudo obtener el logo: `, err);
    });
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { history } = this.props;
    const { username, password } = this.state;

    const data = {
      username,
      password,
    };

    APIClient.post('/authenticate', data)
      .then((res) => {
        Security.setSession(res.data.data);
        history.push('/');
      })
      .catch((err) => {
        console.error(err);
        this.setState({ errorMessage: 'Usuario o contraseña incorrectos.' });
      });
  };

  render() {
    const { username, password, errorMessage, logo } = this.state;

    return (
      <Container className="login-page">
        <Container className="mb-4 login-logo">
          <Image src={logo.full_url} fluid/>
        </Container>
        {/* <h1 className="text-center">Portal Comercial</h1> */}
        <Card>
          <Card.Body>
            <p>Ingrese su usuario o e-mail, y contraseña.</p>
            {errorMessage !== '' && <p className="text-danger">{errorMessage}</p>}
            <Form onSubmit={this.onSubmit}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Usuario o e-mail"
                  value={username}
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" block>
                Ingresar
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default Login;
