import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';

class ZonaList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      zonas: [],
    };

    this.loadZonas = this.loadZonas.bind(this);
  }

  componentDidMount() {
    this.loadZonas();
  }

  loadZonas() {
    const { toastManager } = this.props;

    APIClient.get('/zonas')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          zonas: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { isDataLoading, zonas } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: false,
        hidden: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/zonas/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Zonas</h1>

        <DataTable
          columns={columns}
          data={zonas}
          isDataLoading={isDataLoading}
          keyField="id"
          addButton="/zonas/nueva"
        />
      </div>
    );
  }
}

export default withToastManager(ZonaList);
