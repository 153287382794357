import { ColumnTable } from '../Interfaces/interfaces';

//Columnas de Excel - Lista de Combos
export const colsLista: Array<ColumnTable> = [
  {
    dataField: 'ID',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Descripcion',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Lista_de_Precios',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Estado_del_Combo',
    sort: false,
    hidden: true,
  },
];

//Columnas de Excel -Detalle de Combo
export const colsDetalles: Array<ColumnTable> = [
  {
    dataField: 'Codigo_ERP',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Descripcion',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Cantidad',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Lista_de_Precios',
    sort: false,
    hidden: true,
  },
];
