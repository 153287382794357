import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import APIClient from '../../../services/APIClient';
import { DataTable, FormInputField, FormSelectField } from '../../../components';
import FormSelect from '../../../components/componentsTs/FormSelect';
import DataTableExportCSVButton from '../../../components/DataTableExportCSVButton';
import Moment from 'react-moment';

class SellerReport extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      sellers: [],
      states: [],
      details: [],
      isDataLoading: true,
      totalSize: 0,
      selectedSellerId: '',
      selectedStateIds: [],
      statesSale: [],
      fromDate: '',
      toDate: '',
      disabled: true
    };
  }

  async componentDidMount() {
    await this.loadSelectData();
  }

  loadSelectData = async () => {
    const { toastManager } = this.props;
    try {
      const sellers = await APIClient.get('/vendedores');
      const states = await APIClient.get('/state-order');

      this.setState(prevState => ({
        ...prevState,
        sellers: sellers.data.data,
        states: states.data.data,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleFormChange = (e) => {
    const { id, value, options, multiple } = e.target;

    if (multiple) {
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState(prevState => ({
        ...prevState,
        [id]: {states: values},
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  onLoadData = async () => {
    const { toastManager } = this.props;
    const { selectedSellerId, fromDate, toDate, statesSale } = this.state;
    try {
    
      this.setState(prevState => ({
        ...prevState,
        isDataLoading: true,
      }));

      const response = await APIClient.get(`/reportes/sales-by-seller?sellerId=${selectedSellerId}&fromDate=${fromDate}&toDate=${toDate}&statesSale=${JSON.stringify(statesSale)}`);

      this.setState(prevState => ({
        ...prevState,
        details: response.data.data,
        totalSize: response.data.data.length,
        isDataLoading: false,
        disabled: response.data.data.length > 0 ? false : true
      }));

    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  getExportData = () => {
    const { details } = this.state;
    return details;
  }

  createSelectAllButtons = (entityName) => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)}(
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}>
        Seleccionar todos
      </button>
      )
    </p>
  );

  render() {
    const {
      sellers, details, selectedSellerId, isDataLoading, totalSize, selectedStateIds, states, fromDate, toDate, disabled
    } = this.state;

    const columns = [
      {
        dataField: 'Fecha de pedido',
        text: 'Fecha de solicitud',
        sort: true,
        formatter: (cellContent) => cellContent && <Moment format="YYYY-MM-DD HH:mm">{cellContent}</Moment>,
      },
      {
        dataField: 'Código',
        text: 'Código de solicitud',
        sort: true,
      },
      {
        dataField: 'Cliente',
        text: 'Cliente',
        sort: true,
      },
      {
        dataField: 'Vendedor',
        hidden: true,
        sort: true,
      },
      {
        dataField: 'Precio de entrega',
        text: 'Precio de entrega',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title className="mb-4">Reporte de ventas por vendedor</Card.Title>
            <Row className="d-flex justify-content-between">
              <Col md={6}>
                <FormSelectField
                  id="selectedSellerId"
                  label="Selecciona un vendedor"
                  value={selectedSellerId}
                  onChange={this.handleFormChange}
                  choices={sellers}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                  required
                />
              </Col>
              <Col md={6}>
                <FormSelect
                      id="statesSale"
                      label={this.createSelectAllButtons('estados de solicitudes')}
                      value={selectedStateIds}
                      choices={states}
                      choiceIdField="code"
                      choiceLabelField="description"
                      onChange={this.handleFormChange}
                      multiple
                      required
                    />
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormInputField
                      id="fromDate"
                      type="date"
                      label="Fecha desde:"
                      value={fromDate !== '' ? fromDate : null}
                      onChange={this.handleFormChange}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <FormInputField
                      id="toDate"
                      type="date"
                      label="Fecha hasta:"
                      onChange={this.handleFormChange}
                      value={toDate !== '' ? toDate : null}
                      min={fromDate !== '' ? fromDate : null}
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.onLoadData}>
                  Buscar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details || []}
              keyField="orderCode"
              isDataLoading={isDataLoading}
              totalSize={totalSize}
              showExport={false}
            />
            <DataTableExportCSVButton
              getExportData={this.getExportData}
              disabled={disabled}
              cols={columns}
              exportFileName={`Ventas-vendedor-${selectedSellerId}-${fromDate}-${toDate}`}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(SellerReport);
