import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row, Button, Modal, Table, Form } from 'react-bootstrap';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faDownload, faList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormInputField, FormSelectField } from '..';
import { interactionService } from '../../services/interactions/interactionService';

export default class AddInteractionModal extends Component {
  static propTypes = {
    showAddInteractionModal: PropTypes.bool.isRequired,
    interactionEdit: PropTypes.object.isRequired,
    interactionFlow: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    customerId: PropTypes.any.isRequired,
    customersIdsForBulkInteraction: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    interactionType: PropTypes.any.isRequired,
    files: PropTypes.array.isRequired,
    typesOption: PropTypes.array.isRequired,
    startDate: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    userId: PropTypes.any.isRequired,
    usersOptions: PropTypes.array.isRequired,
    interactionState: PropTypes.number.isRequired,
    stateOptions: PropTypes.array.isRequired,
    flowOptions: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
    handleDataChange: PropTypes.func.isRequired,
    handleAddInteractionModal: PropTypes.func.isRequired,
    handleInteractionStateInput: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    onSaveEntity: PropTypes.func.isRequired,
  };
  constructor (props) {
    super(props);
    this.state = {
      showFiles: false,
    }
  }

  onSaveEntity = () => {
    const { onSaveEntity } = this.props;
    return onSaveEntity();
  }

  handlerModalFiles = () => {
    const { showFiles } = this.state;
    this.setState({showFiles: !showFiles});
  }

  onDownloadFile = (file) => {
    const { interactionEdit } = this.props;
    interactionService.downLoadFile(file, interactionEdit);
  }

  modalFiles = () => {
    return(
      <Modal size="md" show={this.state.showFiles} onHide={() => this.handlerModalFiles()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Archivos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Archivo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.files.map(e => (
                <tr key={e.name}>
                  <td>{e.name}</td>
                  <td>
                    <Button size="sm" variant="outline-primary" title="Descargar" onClick={() => this.onDownloadFile(e)}>
                      <FontAwesomeIcon icon={faDownload} fixedWidth size="xs" />
                    </Button>
                    <Button className="ml-2" size="sm" variant="outline-primary" title="Eliminar" onClick={() => this.props.deleteFile(e)}>
                      <FontAwesomeIcon icon={faTrash} fixedWidth size="xs" />
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    const {
      showAddInteractionModal,
      startDate,
      interactionEdit,
      interactionFlow,
      bgColor,
      customerId,
      customersIdsForBulkInteraction,
      customers,
      interactionType,
      files,
      typesOption,
      dueDate,
      userId,
      usersOptions,
      interactionState,
      stateOptions,
      flowOptions,
      description,
      handleDataChange,
      handleAddInteractionModal,
      handleInteractionStateInput,
      handleFileChange,
    } = this.props;

    return (
      <Modal size="xl" show={showAddInteractionModal} onHide={() => handleAddInteractionModal('close')}>
        {this.modalFiles()}
        <Modal.Header closeButton>
          <Modal.Title>
            {interactionEdit.id ? `${interactionEdit.customer.razonSocial} ${startDate}` : 'Interacción: Nueva'}

            {interactionFlow === 'entrante' ? (
              <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" className="ml-2 mb-2" style={{ color: '#3295ff' }} />
            ) : (
              <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" className="ml-2 mb-2" style={{ color: '#01b746' }} />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ backgroundColor: `${bgColor}` }}>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <FormSelectField
                    id="customerId"
                    label="Cliente"
                    value={customerId}
                    choices={customers}
                    choiceIdField="id"
                    choiceLabelField="razonSocial"
                    onChange={handleDataChange}
                    disabled={customersIdsForBulkInteraction.length > 0 ? true: false}
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="interactionType"
                    label="Tipo de interacción"
                    value={interactionType}
                    choices={typesOption}
                    onChange={handleDataChange}
                    choiceIdField="code"
                    choiceLabelField="description"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormInputField
                    id="startDate"
                    type="date"
                    label="Fecha de comienzo"
                    value={startDate}
                    onChange={handleDataChange}
                    required
                  />
                </Col>
                <Col md={6}>
                  <FormInputField
                    id="dueDate"
                    type="date"
                    label="Fecha máxima de resolución"
                    value={dueDate}
                    onChange={handleDataChange}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormSelectField
                    id="interactionFlow"
                    label="Flujo de interacción"
                    value={interactionFlow}
                    onChange={handleDataChange}
                    choices={flowOptions}
                    choiceIdField="value"
                    choiceLabelField="label"
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="userId"
                    label="Operador"
                    value={userId}
                    choices={usersOptions}
                    onChange={handleDataChange}
                    choiceIdField="id"
                    choiceLabelField="name"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormSelectField
                    id="interactionState"
                    label="Estado de interacción"
                    value={interactionState}
                    choices={stateOptions}
                    onChange={handleInteractionStateInput}
                    choiceIdField="code"
                    choiceLabelField="description"
                  />
                </Col>
                {
                  customersIdsForBulkInteraction.length <= 0
                  ?
                    (
                      <Col md={6}>
                        <Row>
                          <Col md={10}>
                            <Form.Group controlId="fileToUpload" className="mt-1">
                              <Form.Label>Selecciona archivos</Form.Label>
                              <Form.Control type="file" onChange={handleFileChange}/>
                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <Form.Group className="mt-4">
                              <Button size="md" variant="outline-primary" title="Ver archivos" onClick={() => this.handlerModalFiles()}>
                                <FontAwesomeIcon icon={faList} fixedWidth size="xs" />
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                    )
                  :
                  ''
                }
              </Row>
              <Row>
                <Col md={12}>
                  <FormInputField
                    id="description"
                    label="Descripción"
                    as="textarea"
                    rows={3}
                    value={description}
                    onChange={handleDataChange}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.onSaveEntity()}>
            Guardar
          </Button>
          <Button variant="secondary" onClick={() => handleAddInteractionModal('close')}>
            Cerrar
          </Button>
          {
            customersIdsForBulkInteraction.length > 0 ?
            (
              <Button variant="secondary" onClick={() => handleAddInteractionModal('back')}>
                Atrás
              </Button>
            ) : ''
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
