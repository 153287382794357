import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { DataTable, FormSelectField } from '../../../components';
import APIClient from '../../../services/APIClient';
import DataTableExportCSVButton from '../../../components/DataTableExportCSVButton';

const periods = [
    {id: 'trimester', label: 'Trimestre'},
    {id: 'semester', label: 'Semestre'}
]
class SalesByCustomer extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      details: [],
      totalSize: 0,
      isDataLoading: true,
      totalSize: 0,
      disabled: true,
      period: periods[0].id
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { toastManager } = this.props;
    const { period } = this.state;
    try {
      const saleCustomerReport = await APIClient.get(`/reportes/sales-by-customer-type?period=${period}`);
      this.setState(prevState => ({
        ...prevState,
        details: saleCustomerReport.data.data,
        isDataLoading: false,
        totalSize: saleCustomerReport.data.data.length,
        disabled: saleCustomerReport.data.data.length > 0 ? false : true
      }));
    } catch (error) {
      toastManager.add('Ocurrió un error', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleFormChange = (e) => {
    const { id, value } = e.target;
    this.setState(prevState => ({
        ...prevState,
        [id]: value,
    }));
  };

  getExportData = () => {
    const { details } = this.state;
    return details;
  }

  render() {
    const { details, totalSize, isDataLoading, disabled, period } = this.state;

    const columns = [
      {
        dataField: 'Tipo de cliente',
        text: 'Tipo de cliente',
        sort: true,
      },
      {
        dataField: 'Total clientes',
        text: 'Total clientes',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'Total clientes compradores',
        text: 'Total clientes compradores',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'Porcentaje compradores',
        text: 'Porcentaje de compradores',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title>Reporte de clientes por tipo de cliente</Card.Title>
            <Row>
                <Col md={6}>
                <FormSelectField
                  id="period"
                  label="Selecciona un periodo de comprarativa"
                  value={period}
                  onChange={this.handleFormChange}
                  choices={periods}
                  choiceIdField="id"
                  choiceLabelField="label"
                />
                </Col>
                <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.loadData}>
                  Buscar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details}
              totalSize={totalSize}
              isDataLoading={isDataLoading}
              keyField="id"
              showExport={false}
              showSearch={false}
            />
            <DataTableExportCSVButton
              getExportData={this.getExportData}
              disabled={disabled}
              cols={columns}
              exportFileName={`Clientes-tipo-compradores`}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(SalesByCustomer);
