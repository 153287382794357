import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const NotFound = ({ location }) => (
  <div className="text-center py-4 lead">
    <p><FontAwesomeIcon icon={faExclamationTriangle} size="7x" /></p>
    <p>{`La página "${location.pathname}" no se ha encontrado`}</p>
  </div>
);

NotFound.propTypes = {
  location: PropTypes.object,
};

export default NotFound;
