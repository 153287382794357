import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import SellerReport from './SellerReport.js';
import SalesByCustomer from './SalesByCustomer.js';
import SalesByProviderPerMonth from './SalesByProviderPerMonth.js';

class SalesReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
 
    return (
      <div>
        <Row className="d-flex align-items-center page-title">
          <Col md={6}>
            <h1>Reportes de ventas</h1>
          </Col>
        </Row>
        <Tabs defaultActiveKey="sellerReport" id="uncontrolled-tab-example">
          <Tab eventKey="sellerReport" title="Ventas por vendedor">
            <SellerReport />
          </Tab>
          <Tab eventKey="salesByCustomer" title="Clientes por tipo">
            <SalesByCustomer />
          </Tab>
          <Tab eventKey="salesByProviderPerMonth" title="Ventas mensuales por proveedor">
            <SalesByProviderPerMonth />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withToastManager(SalesReport);
