import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    }
  },
};

const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


export function Graphic(props) {
    const { dataProviders } = props;
    const salesInformation = dataProviders.map((dataProvider, index) => {
        return {
            label: dataProvider.provider,
            data: [
                dataProvider.january,
                dataProvider.february,
                dataProvider.march,
                dataProvider.april,
                dataProvider.may,
                dataProvider.june,
                dataProvider.july,
                dataProvider.august,
                dataProvider.september,
                dataProvider.october,
                dataProvider.november,
                dataProvider.december
            ],
            borderColor: `rgb(${index}55, ${index}9, ${index}13)`,
            backgroundColor: `rgb(${index}55, ${index}9, ${index}13)`,
        }
    })

    const data = {};
    data.labels = labels;
    data.datasets = salesInformation;

    return <Line options={options} data={data} />;
}
