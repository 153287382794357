import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { Coleccion, Props } from '../Interfaces/interfaces';
import { ColeccionListState } from './coleccionIterface';

class ColeccionList extends React.Component<Props,ColeccionListState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props:Props) {
    super(props);
    this.state = {
      colecciones: [],
      isDataLoading: true,
    };

    this.loadColecciones = this.loadColecciones.bind(this);
  }

  componentDidMount() {
    this.loadColecciones();
  }

  loadColecciones() {
    const { toastManager } = this.props;

    APIClient.get('/colecciones')
      .then((res) => {
        this.setState({
          colecciones: res.data.data,
          isDataLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { colecciones, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'descripcionWeb',
        text: 'Descripción web',
        sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent:any, row:Coleccion) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/colecciones/${row.codigo}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Colecciones</h1>
        <DataTable
          columns={columns}
          data={colecciones}
          isDataLoading={isDataLoading}
          keyField="codigo"
          addButton="/colecciones/nuevo"
        />
      </div>
    );
  }
}

export default withToastManager(ColeccionList);
