import React from 'react';
import { Route } from 'react-router-dom';
import ComboList from './ComboList';
import ComboEdit from './ComboEdit';

const routes = () => [
  <Route path="/combo/nuevo" exact component={ComboEdit} key="/combo/nuevo" />,
  <Route path="/combo/:id" component={ComboEdit} key="/combo/:id" />,
  <Route path="/combo" exact component={ComboList} key="/combo" />,
].map(route => route);

export default routes;
