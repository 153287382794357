import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Container, Form, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import config from '../../config';
import { EntityEditForm, FormCheckField } from '../../components';

import Utils from '../Utils';
import APIClient from '../../services/APIClient';
import {ArticuloEditState, Imagen, PosicionChanges } from './articuloInterface';
import FormSelect from '../../components/componentsTs/FormSelect';
import FormInput from '../../components/componentsTs/FormInput';
import { Props } from '../Interfaces/interfaces';

class ArticuloEdit extends React.Component<Props, ArticuloEditState> {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);
    const { id } = props.match.params;
    const pageNumbUrl = Utils.sanitizeQuery(['page'], this.props.location.search).page;
    const pageNum = parseInt(pageNumbUrl);
    const page = Utils.isPositiveInteger(pageNum) ? pageNum : 1;
    this.state = {
      entity: {},
      id,
      page,
      imagenes: [],
      isAdding: typeof id === 'undefined',
      marcas: [],
      lineas: [],
      colecciones: [],
      posicionChanges: [],
      selectedImage: {},
      tipologias: [],
      imageToDelete: [],
      proveedores: [],
      showDeleteImageModal: false,
    };
  }

  /**
   * Get img array, selectedImage and imageToDelete from state and remove matching image from array.
   * If image deleted was default image, add first image as default. Save to state.
   */
  deleteFile = async () => {
    const { toastManager } = this.props;
    const { id, imageToDelete, imagenes: imgs } = this.state;
    let { selectedImage } = this.state;
    try {
      if (imageToDelete.id) {
        // delete image from API.
        await APIClient.delete(`/articulos/${id}/imagenes/${imageToDelete.id}`);
      }
      this.setState((prevState) => {
        const { imagenes } = prevState;
        // get image index
        const imgIndex = imagenes.findIndex((img) => img.posicion === imageToDelete.posicion);
        let posicionChanges: Array<PosicionChanges> = [];
        if (imagenes.length > 1) {
          // update posicion for each consecutive element and push changes into array.
          for (let i = imgIndex + 1; i < imagenes.length; i += 1) {
            const imagen: any = imagenes[i];
            imagen.posicion -= 1;
            posicionChanges.push({ id: imagen.id, posicion: imagen.posicion });
          }
          imagenes.splice(imgIndex, 1);
        } else {
          // images array has only one element, no posicion updates are necessary.
          imagenes.pop();
          posicionChanges = [];
        }
        return {
          ...prevState,
          imagenes,
          imageToDelete: {},
          showDeleteImageModal: false,
          posicionChanges,
        };
      });
      toastManager.add('El archivo fue eliminado correctamente.', {
        appearance: 'success',
      });
    } catch (error) {
      console.error(error);
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    } finally {
      selectedImage = imgs[0];
      this.setState({ selectedImage });
    }
  };

  /**
   * Create JSX elements for uploaded and to be uploaded images.
   * Un-uploaded images have a tooltip to indicate they haven't been uploaded yet.
   * @return image JSX elements
   */
  generateImagesElem = () => {
    const { entity, imagenes } = this.state;
    const { id } = entity;
    return (
      <Row>
        {imagenes.map((img, i) => {
          if (img.isUploaded) {
            return (
              <Col key={`img_${img.id}`} md={3}>
                <Container
                  style={{ height: '250px' }}
                  className="d-flex justify-content-center align-items-center img-thumbnail"
                  onClick={() => this.setState((prevState) => ({ ...prevState, selectedImage: img }))}>
                  <Image
                    className="mh-100"
                    id={`img_${i}`}
                    src={`${config.api.publicPath}/${id}/${img.filename}`}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </Container>
              </Col>
            );
          }
          return (
            <OverlayTrigger key={`img_${i}`} overlay={<Tooltip id="tooltip-disabled">Aún no ha sido subida al servidor!</Tooltip>}>
              <Col md={3}>
                <div style={{ height: '250px' }} className="bg-warning d-flex justify-content-center align-items-center img-thumbnail">
                  <Image
                    className="mh-100"
                    //disabled
                    style={{ opacity: '0.6' }}
                    id={`img_${i}`}
                    src={`${img.filename}`}
                    onClick={() => this.setState((prevState) => ({ ...prevState, selectedImage: img }))}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </div>
              </Col>
            </OverlayTrigger>
          );
        })}
      </Row>
    );
  };

  /**
   * Create temporary URL for new file and add it into imagenes array.
   */
  handleImageChange = (event: any) => {
    const { toastManager } = this.props;
    const newFile = event.target.files[0];
    if (newFile) {
      if (newFile.size > 5000000) {
        toastManager.add('Solo se permiten imágenes de menos de 5 MB.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        this.setState((prevState) => {
          const { imagenes } = prevState;
          const newImage: Imagen = {};
          const fileTempUrl = URL.createObjectURL(newFile);

          newImage.filename = fileTempUrl;
          if (imagenes.length > 0) {
            const imgPositions = imagenes.map((img: any) => img.posicion);
            const maxPosition = Math.max.apply(null, imgPositions) + 1;
            newImage.posicion = maxPosition;
            imagenes.push(newImage);
            return { ...prevState, imagenes };
          }
          newImage.posicion = 0;
          imagenes.push(newImage);
          return { ...prevState, imagenes, selectedImage: newImage };
        });
      }
    }
  };

  onLoadForm = async () => {
    // get marcas
    const marcasRes = await APIClient.get('/marcas');
    // get lineas
    const lineasRes = await APIClient.get('/lineas');
    // get colecciones
    const coleccionesRes = await APIClient.get('/colecciones');
    // get tipologias
    const tipologiasRes = await APIClient.get('/tipologias');
    // get proveedores
    const proveedoresRes = await APIClient.get('/proveedores');

    this.setState({
      marcas: marcasRes.data.data,
      colecciones: coleccionesRes.data.data,
      lineas: lineasRes.data.data,
      tipologias: tipologiasRes.data.data,
      proveedores: proveedoresRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get articulo
    const articuloRes = await APIClient.get(`/articulos/${id}`);
    const articulo = articuloRes.data.data;
    const { imagenes } = articulo;

    imagenes.forEach((img: any) => {
      img.isUploaded = true;
    });
    imagenes.sort((a: any, b: any) => a.posicion - b.posicion);

    this.setState({
      entity: articulo,
      imagenes,
      selectedImage: imagenes[0],
    });

    return articulo;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave: any) => {
    const { id, page, imagenes, isAdding } = this.state;
    const { history, toastManager } = this.props;

    // Retrieve images to upload
    const imgsToUpload = imagenes.filter((img) => !img.isUploaded);
    let saveResponse: any = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/articulos', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/articulos/${id}`, entityToSave);
    }
    if (imgsToUpload.length > 0) {
      const imgPromises: any = [];
      for (let i = 0; i < imgsToUpload.length; i += 1) {
        const curImg = imgsToUpload[i];
        // Get image object
        const curImgResponse = await APIClient.getImage(curImg.filename, { responseType: 'blob' });
        const newImage: any = {};

        const reader: any = new FileReader();
        reader.onloadend = () => {
          const result: any = reader.result.split(',');
          newImage.fileData = result[1];
          newImage.fileType = result[0].match(/image\/.*g/g)[0];
          newImage.isUploaded = false;
          newImage.posicion = curImg.posicion;
          const curPromise = APIClient.post(`/articulos/${saveResponse.data.data.id}/imagenes`, newImage);
          imgPromises.push(curPromise);
        };
        reader.onerror = (error: any) => {
          console.error('Error: ', error);
        };
        reader.readAsDataURL(curImgResponse.data);
      }
      await Promise.all(imgPromises);
    }
    // '/articulos?limit=10&sortField=id&sortDir=desc&offset=0&excludeAssocFields=imagenes'
    history.push(`/articulos?page=${page}`);
    toastManager.add(`Artículo ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  prepareToSave = (entityToSave: any) => {
    const { posicionChanges } = this.state;
    if (posicionChanges.length > 0) {
      entityToSave.imagenes = posicionChanges;
    }
    return entityToSave;
  };

  setImageAsDefault = () => {
    this.setState((prevState) => {
      const { imagenes, selectedImage } = prevState;
      // get old posicion value
      const { posicion } = selectedImage;
      // find existing default image
      const defaultImage: any = imagenes[0];
      // if there is and it is not the selected image, then change
      // its posicion to selectedImages old posicion
      if (defaultImage && defaultImage.id !== selectedImage.id) {
        defaultImage.posicion = posicion;
      }
      const image: any = imagenes.find((img) => selectedImage.id === img.id);
      image.posicion = 0;
      // selectedImage.posicion = 0;
      const posicionChanges: Array<PosicionChanges> = [];
      if (selectedImage.id) {
        posicionChanges.push({ id: selectedImage.id, posicion: selectedImage.posicion });
      }
      posicionChanges.push({ id: defaultImage.id, posicion: defaultImage.posicion });
      imagenes.sort((a: any, b: any) => a.posicion - b.posicion);

      return {
        ...prevState,
        imagenes,
        selectedImage: imagenes[0],
        posicionChanges,
      };
    });
  };

  showDeleteImageModal = () => {
    const { showDeleteImageModal } = this.state;
    return (
      <Modal size="sm" show={showDeleteImageModal} onHide={() => this.setState({ showDeleteImageModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Desea eliminar este archivo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showDeleteImageModal: false })}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={this.deleteFile}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const { id, entity, imagenes, isAdding, marcas, colecciones, lineas, selectedImage, tipologias, proveedores } = this.state;

    return (
      <div>
        {this.showDeleteImageModal()}
        <h1 className="page-title">{isAdding ? 'Artículo nuevo' : `Artículo #${id}`}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}>
          <>
            <Row>
              <Col md={6}>
                <FormInput id="descripcion" label="Descripción" type="text" defaultValue={entity.descripcion} required />
              </Col>
              <Col md={6}>
                <FormSelect
                  id="marcaCodigo"
                  label="Marca"
                  defaultValue={entity.marcaCodigo}
                  choices={marcas}
                  choiceIdField="codigo"
                  choiceLabelField="descripcion"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInput id="erpCodigo" label="Código ERP" type="text" defaultValue={entity.erpCodigo} />
              </Col>
              <Col md={6}>
                <FormSelect
                  id="lineaId"
                  label="Linea"
                  defaultValue={entity.lineaId}
                  choices={lineas}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelect
                  id="proveedorId"
                  label="Proveedor"
                  defaultValue={entity.proveedorId}
                  choices={proveedores}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                />
              </Col>
              <Col md={6}>
                <FormSelect
                  id="coleccionCodigo"
                  label="Colección"
                  defaultValue={entity.coleccionCodigo}
                  choices={colecciones}
                  choiceIdField="codigo"
                  choiceLabelField="descripcion"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput id="codigoEan13" label="Código EAN13" type="text" defaultValue={entity.codigoEan13} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelect
                  id="tipologia"
                  label="Tipología"
                  defaultValue={entity.tipologiaId}
                  choices={tipologias}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  required
                />
              </Col>
              <Col md={3}>
                <FormInput
                  id="factorBulto"
                  type="number"
                  label="Unidades x Bulto:"
                  defaultValue={entity ? entity.factorBulto : null}
                  min={0}
                  step="0.1"
                />
              </Col>
              <Col md={3}>
                <FormInput
                  id="factorBulto2"
                  type="number"
                  label="Bultos por Pallet:"
                  min={0}
                  step="0.1"
                  defaultValue={entity ? entity.factorBulto2 : null}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField id="isPromo" label="Promoción" defaultChecked={entity.isPromo} />
              </Col>
              <Col>
                <FormCheckField id="isNovedad" label="Novedad" defaultChecked={entity.isNovedad} />
              </Col>
              <Col>
                <FormCheckField id="isEliminado" label="Inactivo" defaultChecked={entity.isEliminado} />
              </Col>
              <Col>
                <FormCheckField id="isFavorito" label="Favorito" defaultChecked={entity.isFavorito} />
              </Col>
              <Col>
                <FormCheckField id="isAgotado" label="Agotado" defaultChecked={entity.isAgotado} />
              </Col>
              <Col>
                <FormCheckField id="isVentaBulto" label="Restricción de venta unitaria" defaultChecked={entity.isVentaBulto} />
              </Col>
              <Col>
                <FormCheckField id="isInternal" label="Producto interno" defaultChecked={entity.isInternal} />
              </Col>
            </Row>
            <Card>
              <Card.Body>
                <Card.Title>
                  <h2 className="d-flex my-3">Imágenes</h2>
                </Card.Title>
                {selectedImage && imagenes.length > 0 && (
                  <Row>
                    <Col className="d-flex align-items-center" md={9}>
                      <Container className="mb-2 border border-primary rounded d-flex justify-content-center">
                        <Image
                          id="img_selected"
                          src={
                            selectedImage.isUploaded ? `${config.api.publicPath}/${id}/${selectedImage.filename}` : selectedImage.filename
                          }
                          alt={`imagen_${selectedImage.posicion}`}
                          rounded
                          fluid
                        />
                      </Container>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center" md={3}>
                      <Container className="d-flex flex-column">
                        <Button
                          type="submit"
                          disabled={selectedImage.posicion === 0}
                          className="m-1 bg-primary"
                          onClick={this.setImageAsDefault}>
                          {selectedImage.posicion === 0 ? 'Predeterminada' : 'Marcar como predet.'}
                        </Button>
                        <Button
                          type="submit"
                          className="m-1 bg-primary"
                          onClick={() =>
                            this.setState({
                              showDeleteImageModal: true,
                              imageToDelete: { ...selectedImage },
                            })
                          }>
                          Eliminar
                        </Button>
                      </Container>
                    </Col>
                  </Row>
                )}
                {imagenes && this.generateImagesElem()}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Col md={10}>
                  <Form.Group className="d-flex align-items-center mb-0" controlId="fileUpload">
                    <label className="custom-file-label position-absolute mb-0" htmlFor="customFileLang">
                      Haga click para seleccionar un archivo.
                    </label>
                    <input
                      name="fileUpload"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={this.handleImageChange}
                    />
                  </Form.Group>
                </Col>
              </Card.Footer>
            </Card>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ArticuloEdit);
