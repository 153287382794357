import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import FormInputField from './FormInputField';
import FormSelectField from './FormSelectField';

const FormAddressInput = (props) => {
  const { entity, provincias } = props;

  return (
    <>
      <Row>
        <Col md={6}>
          <FormInputField
            id="calle"
            label="Calle"
            type="text"
            defaultValue={entity && entity.calle}
            required
          />
        </Col>
        <Col md={2}>
          <FormInputField
            id="numero"
            label="Número"
            type="text"
            defaultValue={entity && entity.numero}
            required
          />
        </Col>
        <Col md={2}>
          <FormInputField id="piso" label="Piso" type="text" defaultValue={entity && entity.piso} />
        </Col>
        <Col md={2}>
          <FormInputField
            id="depto"
            label="Depto."
            type="text"
            defaultValue={entity && entity.depto}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormInputField
            id="localidad"
            label="Localidad"
            type="text"
            defaultValue={entity && entity.localidad}
          />
        </Col>
        <Col md={4}>
          <FormSelectField
            id="provinciaCodigo"
            label="Provincia"
            defaultValue={entity && entity.provinciaCodigo}
            choices={provincias}
            choiceIdField="codigo"
            choiceLabelField="descripcion"
          />
        </Col>
        <Col md={2}>
          <FormInputField
            id="codigoPostal"
            label="C.P."
            type="text"
            defaultValue={entity && entity.codigoPostal}
          />
        </Col>
      </Row>
    </>
  );
};

export default withToastManager(FormAddressInput);
