import React from 'react';
import { Route } from 'react-router-dom';
import EntregaList from './EntregaList';
import EntregaEdit from './EntregaEdit';

const routes = () => [
  <Route path="/entregas/nuevo/:clienteId" exact component={EntregaEdit} key="/entregas/nuevo/:clienteId" />,
  <Route path="/entregas/:id" component={EntregaEdit} key="/entregas/:id" />,
  <Route path="/entregas" exact component={EntregaList} key="/entregas/:id" />,
].map(route => route);

export default routes;
