import APIClient from '../APIClient';
import Security from '../Security';

export async function areLoggedUserInteractionNotifications() {
  const session = Security.getSession();
  const interactionsNotSeen = await APIClient.get(`/interaction?filter[sf_guard_user_id][eq]=${session.user.id}&filter[is_seen]=0`);
  return interactionsNotSeen.data.data.length;
}

export async function areCustomersWithoutInteractions() {
  const customersWithoutInteractions = await APIClient.get('/interaction/interactions-by-customers');
  return customersWithoutInteractions.data.data;
}

export async function areStockValidityNotification() {
  const nextProductsToExpire = await APIClient.get('/reportes-stock/validity-stock?period=8');
  return nextProductsToExpire.data.data.length;
}

export async function areNotifications() {
  const session = Security.getSession();

  let notificationState = {
    notifications: false,
    interactionNotification: {
      state: false,
      interactionsNotSeen: 0,
    },
    validityStockNotification: {
      state: false,
      nextProductsToExpire: 0,
    },
  };
  if (session.user.id) {
    const interactionsNotSeen = await areLoggedUserInteractionNotifications();
    const customersWithoutInteractions = await areCustomersWithoutInteractions();
    const nextProductsToExpire = await areStockValidityNotification();
    let areNotification = false;
    if (interactionsNotSeen > 0 || nextProductsToExpire > 0) {
      areNotification = true;
    }
    notificationState = {
      notifications: areNotification,
      interactionNotification: {
        state: interactionsNotSeen > 0 ? true : false,
        interactionsNotSeen,
      },
      customersWithoutInteractionsNotification: {
        state: customersWithoutInteractions.length > 0 ? true : false,
        customersWithoutInteractions: customersWithoutInteractions,
      },
      validityStockNotification: {
        state: nextProductsToExpire > 0 ? true : false,
        nextProductsToExpire,
      },
    };
  }
  return notificationState;
}
