import React from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

class DataTableSearchField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      // enter
      const { searchText } = this.state;
      const { onSearch } = this.props;

      onSearch(searchText);
    }
  };

  handleSearchClick() {
    const { searchText } = this.state;
    const { onSearch } = this.props;

    onSearch(searchText);
  }

  handleClearClick() {
    const { onClear } = this.props;

    this.setState({ searchText: '' });
    onClear();
  }

  render() {
    const { searchText } = this.state;

    return (
      <InputGroup size="sm">
        <InputGroup.Prepend>
          <Button className="btn btn-primary" title="Filtrar" onClick={this.handleSearchClick}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Filtrar registros"
          name="searchText"
          value={searchText}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyPress}
        />
        <InputGroup.Append>
          <Button className="btn btn-secondary" title="Limpiar" onClick={this.handleClearClick}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

export default DataTableSearchField;
