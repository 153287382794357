import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm } from '../../components';
import APIClient from '../../services/APIClient';
import { Coleccion, Props } from '../Interfaces/interfaces';
import { ColeccionEditState } from './coleccionIterface';
import FormInput from '../../components/componentsTs/FormInput';

class ColeccionEdit extends React.Component<Props, ColeccionEditState> {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
      isLoading: true,
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get coleccione
    const coleccioneRes = await APIClient.get(`/colecciones/${id}`);

    this.setState({
      isLoading: false,
      entity: coleccioneRes.data.data,
    });
    return coleccioneRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave: Coleccion) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/colecciones', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/colecciones/${id}`, entityToSave);
    }

    history.push('/colecciones');
    toastManager.add(`Colección ${saveResponse.data.data.codigo} guardada con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });

    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Colección nueva' : `Colección #${id}`}</h1>

        <EntityEditForm onRetrieveEntity={this.onRetrieveEntity} onSaveEntity={this.onSaveEntity} addMode={isAdding}>
          <>
            <Row>
              <Col md={6}>
                <FormInput id="codigo" label="Código" type="text" defaultValue={entity.codigo} required />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInput id="descripcion" label="Descripción" type="text" defaultValue={entity.descripcion} required />
              </Col>
              <Col md={6}>
                <FormInput id="descripcionWeb" label="Descripción Web" type="text" defaultValue={entity.descripcionWeb} />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ColeccionEdit);
