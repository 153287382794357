import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Loading = props => (
  <div className="p-3">
    <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
    <span>Cargando...</span>
  </div>
);
export default Loading;
