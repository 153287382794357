import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export default class Notification extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
    handleModal: PropTypes.func.isRequired,
    redirect: PropTypes.string
  }

  render() {
    const { title, detail, redirect, showModal, handleModal } = this.props;
    return (
      <Modal size="md" show={showModal} onHide={() => handleModal('close')}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    redirect ?
                    (
                        <Link to={redirect} onClick={() => handleModal('close')}>{detail}</Link>
                    )
                    :
                    (
                        <div>{detail}</div>
                    )
                }
                
            </Modal.Body>
      </Modal>
    )
  }
}
