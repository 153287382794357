import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '.';

class EntityEditForm extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    addMode: PropTypes.bool,
    onLoadForm: PropTypes.func,
    initializedEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isEditable: PropTypes.bool,
    onRetrieveEntity: PropTypes.func.isRequired,
    onSaveEntity: PropTypes.func.isRequired,
    prepareToSave: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.object),
    validate: PropTypes.func,
  };

  static defaultProps = {
    addMode: false,
    initializedEntity: {},
    isEditable: true,
    onLoadForm: () => {},
    prepareToSave: (entity) => { return entity },
    validate: () => {},
    buttons: [
      {
        text: 'Guardar',
        type: 'submit',
        variant: 'primary',
        classes: 'mr-1',
        icon: <FontAwesomeIcon icon={faCheck} fixedWidth className="mr-1" />,
      },
    ],
  };

  constructor(props) {
    super(props);

    this.state = {
      actionType: '',
      isLoading: true,
      isSaving: false,
      isSubmitButtonClicked: false,
      entityToSave: {},
    };
  }

  async componentDidMount() {
    const {
      onRetrieveEntity, onLoadForm, addMode, initializedEntity,
    } = this.props;

    if (onLoadForm) {
      await onLoadForm();
    }

    if (addMode) {
      // undo the Loading mode
      if (initializedEntity) {
        let newEntity = initializedEntity;
        if (typeof initializedEntity === 'function') {
          newEntity = await initializedEntity();
        }
        this.setState({
          entityToSave: newEntity,
        });
      }
      this.setState({
        isLoading: false,
      });
    } else {
      try {
        await onRetrieveEntity();

        this.setState({
          isLoading: false,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  /**
   * Save the item
   */
  onSaveSubmit = async (event) => {
    const { isSaving, isSubmitButtonClicked, actionType } = this.state;
    let { entityToSave } = this.state;

    const {
      isEditable, prepareToSave, toastManager, onSaveEntity, validate,
    } = this.props;
    event.preventDefault();

    // if it's in the process of saving, do nothing else
    if (isSaving) {
      return;
    }
    
    if (isSubmitButtonClicked && isEditable) {
      try {
        if (prepareToSave) {
          // Prepare entity to save
          entityToSave = prepareToSave(entityToSave, actionType);
        }
        let validationMessage = null;
        if (validate) {
          validationMessage = validate(entityToSave, actionType);
        }
        if (validationMessage && validationMessage.length !== 0) {
          toastManager.add(validationMessage, { appearance: 'warning' });
        } else {
          // if not addMode and nothing to save, notify and do nothing
          if (entityToSave == null || Object.keys(entityToSave).length === 0) {
            toastManager.add('No se realizaron cambios', {
              appearance: 'info',
              autoDismiss: true,
            });
            return;
          }
          this.setState({
            isSaving: true,
          });
          await onSaveEntity(entityToSave, actionType);
          this.setState({ entityToSave: {} });
        }
      } catch (err) {
        console.error('Error al guardar la entidad.', err);
        let errorMessage = err.message;
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        toastManager.add(`Ocurrió un error: "${errorMessage}"`, {
          appearance: 'error',
        });
      } finally {
        this.setState({ isSubmitButtonClicked: false, isSaving: false });
      }
    }
  };

  /**
   * Form field input changed
   */
  onFormChange = (event) => {
    const { value, name, type } = event.target;
    const { entityToSave } = this.state;

    let valueToSave = value;
    if (type === 'checkbox') {
      valueToSave = event.target.checked;
    } else if (type === 'select-one') {
      if (valueToSave === '') {
        // assume '' as empty
        valueToSave = null;
      }
    } else if (type === 'text' && name === '') {
      return;
    }

    const names = name.split('.');
    // support up to 3 levels
    if (names.length > 1) {
      // if the object is not created, do it
      if (!entityToSave[names[0]]) {
        entityToSave[names[0]] = {};
      }

      if (names.length > 2) {
        // if the object is not created, do it
        if (!entityToSave[names[0]][names[1]]) {
          entityToSave[names[0]][names[1]] = {};
        }
        entityToSave[names[0]][names[1]][names[2]] = valueToSave;
      } else if (names.length === 2) {
        entityToSave[names[0]][names[1]] = valueToSave;
      }
    } else {
      entityToSave[name] = valueToSave;
    }
    
    this.setState(prevState => ({
      ...prevState,
      ...{ entityToSave },
    }));
  };

  renderSubmitButtons = (buttons) => {
    const { isSaving } = this.state;
    const buttonsElements = buttons.map(but => (
      <Button
        key={but.type}
        className={but.classes}
        variant={but.variant}
        onClick={() => this.setState({ isSubmitButtonClicked: true, actionType: but.type })}
        type={but.type}
        disabled={isSaving || but.disabled}
      >
        {!isSaving ? but.icon : <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" />}
        {but.text}
      </Button>
    ));
    return buttonsElements;
  };

  render() {
    const { isLoading } = this.state;
    const { buttons, children, isEditable } = this.props;

    return (
      <Form onSubmit={this.onSaveSubmit} onChange={this.onFormChange}>
        {isLoading && <Loading />}
        {!isLoading && children}

        <div className="my-2">{isEditable ? this.renderSubmitButtons(buttons) : null}</div>
      </Form>
    );
  }
}

export default withToastManager(EntityEditForm);
