import React from 'react';
import { Route } from 'react-router-dom';
import ClienteTipoList from './ClienteTipoList';
import ClienteTipoEdit from './ClienteTipoEdit';

const routes = () => [
  <Route
    path="/cliente-tipos/nuevo"
    exact
    component={ClienteTipoEdit}
    key="/cliente-tipos/nuevo"
  />,
  <Route path="/cliente-tipos/:id(\d+)" component={ClienteTipoEdit} key="/cliente-tipos/:id" />,
  <Route path="/cliente-tipos" exact component={ClienteTipoList} key="/cliente-tipos/:id" />,
].map(route => route);

export default routes;
