import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormSelectField = (props) => {
  const {
    id,
    label,
    choices,
    required,
    placeholder,
    choiceIdField,
    choiceLabelField,
    arrayvalidateindex,
    ...otherProps
  } = props;

  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control name={id} multiple={otherProps.multiple} as="select" className={ arrayvalidateindex && arrayvalidateindex.indexOf(id) >= 0 ? 'border border-danger' : ''} {...otherProps}>
        {!required && !otherProps.multiple && <option value="">{placeholder}</option>}
        {choices.map(choice => (
          <option value={choice[choiceIdField]} key={choice[choiceIdField]}>
            {typeof choiceLabelField === 'string' ? choice[choiceLabelField] : choiceLabelField(choice)}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
            Please provide a valid zip.
          </Form.Control.Feedback>
    </Form.Group>
  );
};

FormSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  choiceIdField: PropTypes.string,
  choiceLabelField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  arrayvalidateindex: PropTypes.arrayOf(PropTypes.string)
};
FormSelectField.defaultProps = {
  required: false,
  placeholder: '(Seleccione)',
  choiceIdField: 'id',
  choiceLabelField: 'label',
  arrayvalidateindex: []
};

export default FormSelectField;
