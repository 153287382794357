import moment from 'moment';

const date = new Date();
export const currentDate = moment.utc(date).format('YYYY-MM-DD');

export const flowOptions = [
  {
    label: 'Entrante',
    value: 'entrante',
  },
  {
    label: 'Saliente',
    value: 'saliente',
  },
];

export const dictionary = {
  state: {
    NEW: 100,
    INPROGRESS: 200,
    FINISHED: 300,
    SEEN: 1,
    NOTSENN: 0,
  },
  bgColor: {
    NEW: '#D0F1FF',
    INPROGRESS: '#FFEAE7',
    FINISHED: '#EFFFE7',
  },
};

export const chooseBgColor = (state) => {
  switch (Number(state)) {
    case dictionary.state.NEW:
      return dictionary.bgColor.NEW;
    case dictionary.state.INPROGRESS:
      return dictionary.bgColor.INPROGRESS;
    case dictionary.state.FINISHED:
      return dictionary.bgColor.FINISHED;
    default:
      break;
  }
};

export const filterValidations = (curFromDate, curToDate, selectedCustomersId) => {
  let allIsOkey = true;
  if (curFromDate !== '' && curToDate === '') {
    allIsOkey = false;
    const error = {
      message: 'Debe seleccionar un rango de fechas',
      appearance: 'warning',
    };
    return error;
  }

  if (curFromDate === '' && curToDate !== '') {
    allIsOkey = false;
    const error = {
      message: 'Debe seleccionar un rango de fechas',
      appearance: 'warning',
    };
    return error;
  }

  if (curFromDate === '' && curToDate === '' && selectedCustomersId.length <= 0) {
    allIsOkey = false;
    const error = {
      message: 'Debe seleccionar algún filtro',
      appearance: 'warning',
    };
    return error;
  }
  return allIsOkey;
};

export const formValidations = (state) => {
  let validation = {
    status: true,
  };

  if (state.customers.length === 0) {
    validation = {
      ...validation,
      status: false,
      appearance: 'error',
      message: 'Selecciona uno o más clientes',
    };

    return validation;
  }

  if (state.dueDate === '') {
    validation = {
      ...validation,
      status: false,
      appearance: 'error',
      message: 'Selecciona la fecha máxima de resolución',
    };

    return validation;
  }

  if (state.userId === '') {
    validation = {
      ...validation,
      status: false,
      appearance: 'error',
      message: 'Debes seleccionar un operador',
    };

    return validation;
  }

  return validation;
}

export const parseInteractionsData = (interactions) => {
  const parseInteractions = interactions.map((e) => {
    e.startDate = moment.utc(e.startDate).format('YYYY-MM-DD');
    return e;
  });
  return parseInteractions;
};

export const addPropertyAlreadyUpload = (files) => {
  const newFiles = files.map(file => {
    file.alreadyUpload = true;
    return file;
  });
  return newFiles;
};
