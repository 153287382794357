import { faCircleNotch, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import 'react-table/react-table.css';
import { withToastManager } from 'react-toast-notifications';
import {
  DataTable, FormCheckField, FormInputField, FormSelectField, Loading,
} from '../../components';
import APIClient from '../../services/APIClient';
import { defineMainTableDataCustomer, defineMainTableDataProvider } from './utils';

class AvanceVenta extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      clases: [],
      clientes: [],
      colecciones: [],
      lineas: [],
      marcas: [],
      mainTableData: [],
      vendedores: [],
      proveedores: [],
      isDataLoading: true,
      fromMonth: moment()
        .startOf('month')
        .format('YYYY-MM'),
      toMonth: moment()
        .endOf('month')
        .format('YYYY-MM'),
      selectedClaseIds: [],
      selectedClienteIds: [],
      selectedColeccionCodigos: [],
      selectedLineaIds: [],
      selectedMarcaCodigos: [],
      selectedVendedorIds: [],
      selectedClienteTipoIds: [],
      selectedProveedorIds: [],
      clienteTipos: [],
      isSubtableSortedAsc: false,
      subtableSortedBy: '',
      totals: {},
      byCustomer: true,
      byProvider: false
    };
  }

  componentDidMount() {
    this.getFilterData();
  }

  createSelectAllButtons = entityName => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)}
      {' '}
(
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}
      >
        Seleccionar todos
      </button>
      )
    </p>
  );

  getFilterData = async () => {
    const { toastManager } = this.props;
    try {
      // get clases
      const clasesRes = await APIClient.get('/clases');
      // get clientes
      const clientesRes = await APIClient.get('/clientes?sortField=razonSocial&sortDir=asc');
      // get colecciones
      const coleccionesRes = await APIClient.get('/colecciones');
      // get lineas
      const lineasRes = await APIClient.get('/lineas');
      // get marcas
      const marcasRes = await APIClient.get('/marcas');
      // get vendedores
      const vendedoresRes = await APIClient.get('/vendedores');
      // get proveedores
      const proveedoresRes = await APIClient.get('/proveedores');
      // get clienteTipos
      const clienteTiposRes = await APIClient.get('/cliente-tipos');

      this.setState({
        clases: clasesRes.data.data,
        clientes: clientesRes.data.data,
        colecciones: coleccionesRes.data.data,
        lineas: lineasRes.data.data,
        marcas: marcasRes.data.data,
        vendedores: vendedoresRes.data.data,
        clienteTipos: clienteTiposRes.data.data,
        proveedores: proveedoresRes.data.data,
      });
      await this.getReportRows();
    } catch (err) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  getReportRows = async () => {
    const { toastManager } = this.props;
    const {
      clientes,
      proveedores,
      fromMonth,
      toMonth,
      selectedClaseIds,
      selectedClienteIds,
      selectedColeccionCodigos,
      selectedLineaIds,
      selectedMarcaCodigos,
      selectedVendedorIds,
      selectedClienteTipoIds,
      selectedProveedorIds,
      byCustomer,
      byProvider
    } = this.state;
    this.setState({ isDataLoading: true });
    // parse month values into full dates.
    const fromDate = moment(fromMonth).format('YYYY-MM-DD HH:mm:ss');
    const toDate = moment(toMonth)
      .endOf('month')
      .format('YYYY-MM-DD HH:mm:ss');

    // create query filters using state data.
    const groupFilter = byCustomer ? 'byCustomer=on&' : ''
    const ventaFilters = `tipoIds=${selectedClienteTipoIds}&clienteIds=${selectedClienteIds}&vendedorIds=${selectedVendedorIds}`;
    const dateFilters = `fromDate=${fromDate}&toDate=${toDate}`;
    const productoFilters = `claseIds=${selectedClaseIds}&coleccionCodigos=${selectedColeccionCodigos}&lineaIds=${selectedLineaIds}&marcaCodigos=${selectedMarcaCodigos}&proveedorIds=${selectedProveedorIds}`;
    const queryFilters = `${groupFilter}${dateFilters}&${ventaFilters}&${productoFilters}`;
    try {
      const reportRows = await APIClient.get(`/reportes/avance-ventas?${queryFilters}`);
      const avanceVentaReport = reportRows.data.data;
      // create main report.
      let mainTableData;
      
      if (byCustomer) {
        mainTableData = await defineMainTableDataCustomer(clientes);
        for (let i = 0; i < avanceVentaReport.length; i++) {
          const row = avanceVentaReport[i];
          const customer = mainTableData.find(data => row.customerId === data.id);
  
          if (customer) {
            customer.detalles.push(row);
            if (customer.coleccion === null || (customer && customer.coleccion.length < 1)) {
              customer.coleccion = row.coleccion;
            }
            if (customer.proveedor === null || (customer && customer.proveedor.length < 1)) {
              customer.proveedor = row.proveedor;
            }
            row.pendiente = row.solicitado - row.facturado;
            // add to totals.
            customer.solicitado += row.solicitado;
            customer.facturado += row.facturado;
            customer.pendiente += row.pendiente;
            // obtain num datatype
            row.precioSolicitado = parseFloat(row.precioSolicitado);
            row.precioFacturado = parseFloat(row.precioFacturado);
            row.precioPendiente = parseFloat(row.precioPendiente);
            customer.precioSolicitado += row.precioSolicitado;
            customer.precioFacturado += row.precioFacturado;
            customer.precioPendiente += row.precioPendiente;
            // round float decimals.
            customer.precioSolicitado = Math.round(customer.precioSolicitado * 100) / 100;
            customer.precioPendiente = Math.round(customer.precioPendiente * 100) / 100;
            customer.precioFacturado = Math.round(customer.precioFacturado * 100) / 100;
          }
        }
      } else if (byProvider) {
        mainTableData = await defineMainTableDataProvider(proveedores);
        for (let i = 0; i < avanceVentaReport.length; i++) {
          const row = avanceVentaReport[i];
          const proveedor = mainTableData.find(data => row.proveedorId === data.id);

          if (proveedor) {
            proveedor.detalles.push(row);
            if (proveedor.coleccion === null || (proveedor && proveedor.coleccion.length < 1)) {
              proveedor.coleccion = row.coleccion;
            }
            if (proveedor.proveedor === null || (proveedor && proveedor.proveedor.length < 1)) {
              proveedor.proveedor = row.proveedor;
            }
            row.pendiente = row.solicitado - row.facturado;
            // add to totals.
            proveedor.solicitado += row.solicitado;
            proveedor.facturado += row.facturado;
            proveedor.pendiente += row.pendiente;
            // obtain num datatype
            row.precioSolicitado = parseFloat(row.precioSolicitado);
            row.precioFacturado = parseFloat(row.precioFacturado);
            row.precioPendiente = parseFloat(row.precioPendiente);
            proveedor.precioSolicitado += row.precioSolicitado;
            proveedor.precioFacturado += row.precioFacturado;
            proveedor.precioPendiente += row.precioPendiente;
            // round float decimals.
            proveedor.precioSolicitado = Math.round(proveedor.precioSolicitado * 100) / 100;
            proveedor.precioPendiente = Math.round(proveedor.precioPendiente * 100) / 100;
            proveedor.precioFacturado = Math.round(proveedor.precioFacturado * 100) / 100;
          }
        }
      }

      // initialize totals
      const totals = {
        solicitado: 0,
        facturado: 0,
        pendiente: 0,
        precioSolicitado: 0.00,
        precioFacturado: 0.00,
        precioPendiente: 0.00,
      };

      // calculate totals for each column
      const totalsProperties = Object.keys(totals);
      for (let i = 0; i < totalsProperties.length; i++) {
        const key = totalsProperties[i];
        let value = totals[key];
        mainTableData.forEach((data) => {
          if (data[key]) {
            value += data[key];
          }
        });
        if (!Number.isInteger(value)) {
          value = Math.round(value * 100) / 100;
        }
        totals[key] = value;
      }
      this.setState({ reportRows: avanceVentaReport, totals, mainTableData: mainTableData.filter(data => data.detalles.length > 0) });
    } catch (err) {
      console.error('Error al obtener la información del reporte: ', err);
      toastManager.add(`No se pudo obtener el listado de avance de venta. ${err}`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };
  
  handleDataChange = (e) => {
    const {
      id, value, options, multiple,
    } = e.target;
    if (multiple) {
      const values = [...options].filter(opt => opt.selected).map(opt => opt.value);
      this.setState(prevState => ({
        ...prevState,
        [id]: values,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  handleSelectAll = (entityName) => {
    this.setState((prevState) => {
      const {
        clientes, clases, colecciones, lineas, marcas, vendedores, clienteTipos, proveedores,
      } = prevState;
      let {
        selectedClaseIds,
        selectedClienteIds,
        selectedLineaIds,
        selectedMarcaCodigos,
        selectedColeccionCodigos,
        selectedVendedorIds,
        selectedClienteTipoIds,
        selectedProveedorIds,
      } = prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'clases':
          selectedClaseIds = clases.map(cla => cla.id);
          break;
        case 'clientes':
          selectedClienteIds = clientes.map(cli => cli.id);
          break;
        case 'colecciones':
          selectedColeccionCodigos = colecciones.map(col => col.codigo);
          break;
        case 'lineas':
          selectedLineaIds = lineas.map(lin => lin.id);
          break;
        case 'marcas':
          selectedMarcaCodigos = marcas.map(mar => mar.codigo);
          break;
        case 'vendedores':
          selectedVendedorIds = vendedores.map(vend => vend.id);
          break;
        case 'tipos':
          selectedClienteTipoIds = clienteTipos.map(zon => zon.id);
          break;
        case 'proveedores':
          selectedProveedorIds = proveedores.map(prov => prov.id);
          break;
        default:
          break;
      }
      // clientes.forEach(cli => cli.selected = true);
      return {
        ...prevState,
        selectedClaseIds,
        selectedClienteIds,
        selectedLineaIds,
        selectedMarcaCodigos,
        selectedColeccionCodigos,
        selectedVendedorIds,
        selectedClienteTipoIds,
        selectedProveedorIds,
      };
    });
  };

  handleSort = (value) => {
    this.setState((prevState) => {
      const { mainTableData, isSubtableSortedAsc } = prevState;
      const sortedTableData = mainTableData.map((data) => {
        let sortedRow = [];
        if (isSubtableSortedAsc) {
          sortedRow = data.detalles.sort((a, b) => a[value] - b[value]);
        } else {
          sortedRow = data.detalles.sort((a, b) => b[value] - a[value]);
        }
        data.detalles = sortedRow;
        return data;
      });
      return {
        ...prevState,
        mainTableData: sortedTableData,
        isSubtableSortedAsc: !isSubtableSortedAsc,
        subtableSortedBy: value,
      };
    });
  };

  calculateSortClass = (value) => {
    const { isSubtableSortedAsc, subtableSortedBy } = this.state;
    let resultClass = 'order-4';
    if (subtableSortedBy === value) {
      if (isSubtableSortedAsc) {
        resultClass = 'caret-4-asc';
      } else {
        resultClass = 'caret-4-desc';
      }
    }

    return resultClass;
  };

  handleChecks = (e) => {
    const { id } = e.target;
    if (id === "byProvider") {
      this.setState({
        byProvider: true,
        byCustomer: false,
        mainTableData: []
      });
    } else {
      this.setState({
        byProvider: false,
        byCustomer: true,
        mainTableData: []
      });
    }
  }

  render() {
    const {
      clases,
      clientes,
      lineas,
      marcas,
      colecciones,
      vendedores,
      proveedores,
      fromMonth,
      isDataLoading,
      mainTableData,
      reportRows,
      toMonth,
      selectedClaseIds,
      selectedClienteIds,
      selectedColeccionCodigos,
      selectedLineaIds,
      selectedMarcaCodigos,
      selectedVendedorIds,
      selectedClienteTipoIds,
      selectedProveedorIds,
      clienteTipos,
      totals,
      byCustomer,
      byProvider
    } = this.state;

    const columns = [
      {
        dataField: 'descripcion',
        text: 'Descripcion',
        sort: true,
        editable: false,
        hidden: true,
        headerClasses: 'm-auto text-center align-middle',
      },
      {

        dataField: byCustomer ? 'razonSocial' : 'proveedor',
        text: byCustomer ? 'Cliente' : 'Proveedor',
        sort: true,
        editable: false,
        headerClasses: 'm-auto text-center align-middle',
      },
      {
        dataField: 'marca',
        text: 'Marca',
        sort: true,
        editable: false,
        hidden: true,
        headerClasses: 'm-auto text-center align-middle',
      },
      {
        dataField: 'facturado',
        text: 'Facturado',
        sort: true,
        type: 'number',
        editable: false,
        classes: 'text-center',
        headerClasses: 'text-center',
        headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column">
              <span>
                {`${col.text}`}
              </span>
              <span>
                {totals[col.dataField]}
              </span>

            </div>
            <span className="my-auto">{sortElement}</span>
          </div>
        ),
      },
      {
        dataField: 'precioFacturado',
        text: '$ Facturado',
        sort: true,
        type: 'number',
        editable: false,
        classes: 'text-right',
        headerClasses: 'text-right',
        formatter: cellValue => cellValue.toLocaleString('es-AR', {
          style: 'currency',
          currency: 'ARS',
        }),
        headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
          <div className="d-flex flex-row justify-content-end">
            <div className="d-flex flex-column">
              <span>
                {`${col.text}`}
              </span>
              <span>
                {`$${totals[col.dataField]}`}
              </span>
            </div>
            <span className="my-auto">{sortElement}</span>
          </div>
        ),
      },
      {
        dataField: 'pendiente',
        text: 'Pendiente',
        sort: true,
        type: 'number',
        editable: false,
        classes: 'text-center',
        headerClasses: 'text-center',
        headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column">
              <span>
                {`${col.text}`}
              </span>
              <span>
                {totals[col.dataField]}
              </span>

            </div>
            <span className="my-auto">{sortElement}</span>
          </div>
        ),
      },
      {
        dataField: 'precioPendiente',
        text: '$ Pendiente',
        sort: true,
        type: 'number',
        editable: false,
        classes: 'text-right',
        headerClasses: 'text-right',
        formatter: cellValue => cellValue.toLocaleString('es-AR', {
          style: 'currency',
          currency: 'ARS',
        }),
        headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
          <div className="d-flex flex-row justify-content-end">
            <div className="d-flex flex-column">
              <span>
                {`${col.text}`}
              </span>
              <span>
                {`$${totals[col.dataField]}`}
              </span>
            </div>
            <span className="my-auto">{sortElement}</span>
          </div>
        ),
      },
      {
        dataField: 'solicitado',
        text: 'Total',
        sort: true,
        type: 'number',
        editable: false,
        classes: 'text-center',
        headerClasses: 'text-center',
        headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column">
              <span>
                {`${col.text}`}
              </span>
              <span>
                {totals[col.dataField]}
              </span>

            </div>
            <span className="my-auto">{sortElement}</span>
          </div>
        ),
      },
      {
        dataField: 'precioSolicitado',
        text: '$ Total',
        sort: true,
        type: 'number',
        editable: false,
        classes: 'text-right',
        headerClasses: 'text-right',
        formatter: cellValue => cellValue.toLocaleString('es-AR', {
          style: 'currency',
          currency: 'ARS',
        }),
        headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
          <div className="d-flex flex-row justify-content-end">
            <div className="d-flex flex-column">
              <span>
                {`${col.text}`}
              </span>
              <span>
                {`$${totals[col.dataField]}`}
              </span>
            </div>
            <span className="my-auto">{sortElement}</span>
          </div>
        ),
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      // clickToEdit: true,
      clickToSelect: true,
      hideSelectColumn: true,
      // onSelect: this.onSelectArticulo,
    };

    const expandRow = {
      showExpandColumn: true,
      renderer: row => (
        <table className="w-100">
          <thead className="font-weight-bold">
            <tr>
              <th>Descripcion</th>
              <th>Marca</th>
              <th>Código ERP</th>
              <th>Código EAN</th>
              <th onClick={() => this.handleSort('facturado')} className="sortable">
                Facturado
                <span className={this.calculateSortClass('facturado')} />
              </th>
              <th onClick={() => this.handleSort('precioFacturado')} className="sortable">
                $ Facturado
                <span className={this.calculateSortClass('precioFacturado')} />
              </th>
              <th onClick={() => this.handleSort('pendiente')} className="sortable">
                Pendiente
                <span className={this.calculateSortClass('pendiente')} />
              </th>
              <th onClick={() => this.handleSort('precioPendiente')} className="sortable">
                $ Pendiente
                <span className={this.calculateSortClass('precioPendiente')} />
              </th>
              <th onClick={() => this.handleSort('solicitado')} className="sortable">
                Total
                <span className={this.calculateSortClass('solicitado')} />
              </th>
              <th onClick={() => this.handleSort('precioSolicitado')} className="sortable">
                $ Total
                <span className={this.calculateSortClass('precioSolicitado')} />
              </th>
            </tr>
          </thead>
          <tbody>
            {row.detalles.map((det, index) => (
              <tr key={`${det.id}_${index}`}>
                <td>{det.descripcion}</td>
                <td>{det.marca ? det.marca : ''}</td>
                <td>{det.erpCodigo}</td>
                <td>{det.codigoean13}</td>
                <td className="text-center">{det.facturado}</td>
                <td className="text-right">{det.precioFacturado.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
                <td className="text-center">{det.pendiente}</td>
                <td className="text-right">{det.precioPendiente.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
                <td className="text-center">{det.solicitado}</td>
                <td className="text-right">{det.precioSolicitado.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
    };

    return (
      <div>
        <h1 className="page-title">Avance de Ventas</h1>
        <>
          <Row className="mb-3">
            <Col md={3}>
              <FormInputField
                id="fromMonth"
                type="month"
                label="Fecha desde:"
                value={fromMonth !== '' ? fromMonth : null}
                onChange={this.handleDataChange}
                required
              />
            </Col>
            <Col md={3}>
              <FormInputField
                id="toMonth"
                type="month"
                label="Fecha hasta:"
                onChange={this.handleDataChange}
                value={toMonth !== '' ? toMonth : null}
                min={fromMonth !== '' ? fromMonth : null}
                required
              />
            </Col>
            <Col md={6}>
              <Card>
                <Card.Header>Avance de venta por:</Card.Header>
                <Card.Body>
                  <FormCheckField
                    id="byCustomer"
                    value={byCustomer}
                    label="Clientes"
                    checked={byCustomer}
                    onChange={(e) => this.handleChecks(e)}
                  />
                  <FormCheckField
                    id="byProvider"
                    value={byProvider}
                    label="Proveedores"
                    checked={byProvider}
                    onChange={(e) => this.handleChecks(e)}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Card>
            <Card.Header>Filtros Venta</Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <FormSelectField
                    id="selectedClienteTipoIds"
                    label={this.createSelectAllButtons('tipos')}
                    value={selectedClienteTipoIds}
                    onChange={this.handleDataChange}
                    choices={clienteTipos}
                    choiceIdField="id"
                    choiceLabelField="descripcion"
                    multiple
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="selectedClienteIds"
                    label={this.createSelectAllButtons('clientes')}
                    value={selectedClienteIds}
                    onChange={this.handleDataChange}
                    choices={clientes}
                    choiceIdField="id"
                    choiceLabelField="razonSocial"
                    multiple
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="selectedVendedorIds"
                    label={this.createSelectAllButtons('vendedores')}
                    value={selectedVendedorIds}
                    onChange={this.handleDataChange}
                    choices={vendedores}
                    choiceIdField="id"
                    choiceLabelField="nombre"
                    multiple
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>Filtros Producto</Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <FormSelectField
                    id="selectedProveedorIds"
                    label={this.createSelectAllButtons('proveedores')}
                    value={selectedProveedorIds}
                    onChange={this.handleDataChange}
                    choices={proveedores}
                    choiceIdField="id"
                    choiceLabelField="nombre"
                    multiple
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="selectedColeccionCodigos"
                    label={this.createSelectAllButtons('colecciones')}
                    value={selectedColeccionCodigos}
                    onChange={this.handleDataChange}
                    choices={colecciones}
                    choiceIdField="codigo"
                    choiceLabelField="descripcion"
                    multiple
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="selectedLineaIds"
                    label={this.createSelectAllButtons('lineas')}
                    value={selectedLineaIds}
                    onChange={this.handleDataChange}
                    choices={lineas}
                    choiceIdField="id"
                    choiceLabelField="descripcion"
                    multiple
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="selectedMarcaCodigos"
                    label={this.createSelectAllButtons('marcas')}
                    value={selectedMarcaCodigos}
                    onChange={this.handleDataChange}
                    choices={marcas}
                    choiceIdField="codigo"
                    choiceLabelField="descripcion"
                    multiple
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="selectedClaseIds"
                    label={this.createSelectAllButtons('clases')}
                    value={selectedClaseIds}
                    onChange={this.handleDataChange}
                    choices={clases}
                    choiceIdField="id"
                    choiceLabelField="descripcion"
                    multiple
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col>
              <div className="d-flex flex-row justify-content-center">
                <Button disabled={isDataLoading} className="d-flex py-2 m-1 my-3" variant="primary" onClick={this.getReportRows}>
                  {!isDataLoading ? (
                    <p className="m-0">Buscar</p>
                  ) : (
                      <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                    )}
                  {/* <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1"/> */}
                </Button>
              </div>
            </Col>
          </Row>
          {isDataLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loading />
            </div>
          ) : (
              <div>
                <DataTable
                  isDataLoading={isDataLoading}
                  selectRow={selectRowProps}
                  columns={columns}
                  data={mainTableData}
                  keyField="id"
                  showSearch
                  enablePagination={false}
                  expandRow={expandRow}
                  showExport
                  getExportData={() => reportRows}
                  exportFileName='Avance de ventas'
                  exportExtraFields={['codigoean13', 'customer']}
                />
              </div>
            )}
        </>
      </div>
    );
  }
}

export default withToastManager(AvanceVenta);
