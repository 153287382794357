import React from 'react';
import { Route } from 'react-router-dom';
import StockList from './StockList';


const routes = () => [
  <Route path="/stock" exact component={StockList} key="/stock" />,
].map(route => route);

export default routes;
