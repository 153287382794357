import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { DataTable, FormSelectField } from '../../../components';
import APIClient from '../../../services/APIClient';
import DataTableExportCSVButton from '../../../components/DataTableExportCSVButton';
import Moment from 'react-moment';

const periods = [
    {id: 1, label: 'Próximo mes'},
    {id: 3, label: 'Próximo trimestre'},
    {id: 6, label: 'Próximo semestre'},
    {id: 8, label: 'Próximos 8 meses'}
]
class NextToExpire extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      details: [],
      totalSize: 0,
      isDataLoading: true,
      totalSize: 0,
      disabled: true,
      period: periods[0].id,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { toastManager } = this.props;
    const { period } = this.state;
    try {
      const nextProductsToExpire = await APIClient.get(`/reportes-stock/validity-stock?period=${period}`);
      this.setState(prevState => ({
        ...prevState,
        details: nextProductsToExpire.data.data,
        isDataLoading: false,
        totalSize: nextProductsToExpire.data.data.length,
        disabled: !(nextProductsToExpire.data.data.length > 0)
      }));
    } catch (error) {
      toastManager.add('Ocurrió un error', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleFormChange = (e) => {
    const { id, value } = e.target;
    this.setState(prevState => ({
        ...prevState,
        [id]: value,
    }));
  };

  getExportData = () => {
    const { details } = this.state;
    return details;
  }

  render() {
    const { details, totalSize, isDataLoading, disabled, period } = this.state;

    const columns = [
      {
        dataField: 'nombreprod',
        text: 'Artículo',
        sort: true,
      },
      {
        dataField: 'elaboracion',
        text: 'Fecha de elaboración',
        sort: true,
        formatter: (cellContent) => cellContent && <Moment format="YYYY-MM-DD HH:mm">{cellContent}</Moment>,
      },
      {
        dataField: 'vencimiento',
        text: 'Fecha de vencimiento',
        sort: true,
        formatter: (cellContent) => cellContent && <Moment format="YYYY-MM-DD HH:mm">{cellContent}</Moment>,
      },
      {
        dataField: 'estadomer',
        text: 'Estado',
        sort: true
      },
      {
        dataField: 'disponible',
        text: 'Unidades disponibles',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title>Reporte de productos próximos a vencer</Card.Title>
            <Row>
                <Col md={6}>
                <FormSelectField
                  id="period"
                  label="Selecciona un periodo de comprarativa"
                  value={period}
                  onChange={this.handleFormChange}
                  choices={periods}
                  choiceIdField="id"
                  choiceLabelField="label"
                />
                </Col>
                <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.loadData}>
                  Buscar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details}
              totalSize={totalSize}
              isDataLoading={isDataLoading}
              keyField="id"
              showExport={false}
            />
            <DataTableExportCSVButton
              getExportData={this.getExportData}
              disabled={disabled}
              cols={columns}
              exportFileName={`artículos a vencer dentro de los próximos ${period} meses`}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(NextToExpire);
