import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import APIClient from '../../../services/APIClient';
import { DataTable, FormInputField } from '../../../components';

class CustomerOrdervsSales extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      details: [],
      isDataLoading: false,
      totalSize: 0,
      fromDate: '',
      toDate: ''
    };
  }

  handleFormChange = (e) => {
    const { value, name } = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  onLoadData = async () => {
    const { toastManager } = this.props;
    const { fromDate, toDate } = this.state;
    try {
      this.setState(prevState => ({
        ...prevState,
        isDataLoading: true,
      }));

      const ordersVsSalesReport = await APIClient.get(`/reportes/orders-vs-sales-by-customer?fromDate=${fromDate}&toDate=${toDate}`);
      this.setState(prevState => ({
        ...prevState,
        details: ordersVsSalesReport.data.data,
        totalSize: ordersVsSalesReport.data.data.length,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  render() {
    const {
      details, fromDate, toDate, isDataLoading, totalSize,
    } = this.state;

    const columns = [
      {
        dataField: 'razonSocial',
        text: 'Cliente',
        sort: true,
      },
      {
        dataField: 'documento',
        text: 'Documento',
        sort: true,
      },
      {
        dataField: 'localidad',
        text: 'Localidad',
        sort: true,
      },
      {
        dataField: 'tipo',
        text: 'Tipo de cliente',
        sort: true,
      },
      {
        dataField: 'zona',
        text: 'Zona',
        sort: true,
      },
      {
        dataField: 'pedido',
        text: 'Pedido en $',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'precioEntrega',
        text: 'Entregado en $',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title className="mb-4">Comparativa entre los pedidos y las entregas por cliente</Card.Title>
            <Row className="d-flex justify-content-between">
              <Col md={6}>
                <FormInputField
                    id="fromDate"
                    type="date"
                    label="Fecha desde:"
                    value={fromDate !== '' ? fromDate : null}
                    onChange={this.handleFormChange}
                    required
                />
              </Col>
              <Col md={6}>
                <FormInputField
                    id="toDate"
                    type="date"
                    label="Fecha hasta:"
                    onChange={this.handleFormChange}
                    value={toDate !== '' ? toDate : null}
                    min={fromDate !== '' ? fromDate : null}
                    required
                />
              </Col>
              <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.onLoadData}>
                  Buscar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details}
              keyField="code"
              isDataLoading={isDataLoading}
              totalSize={totalSize}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(CustomerOrdervsSales);