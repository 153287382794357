import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faDolly,
  faFileAlt,
  faList,
  faShoppingCart,
  faSuitcase,
  faTachometerAlt,
  faTruck,
  faUsers,
  faUserTie,
  faFileInvoiceDollar,
  faIndustry,
  faHdd,
  faAddressCard,
} from '@fortawesome/free-solid-svg-icons';
import './SideMenu.css';
import projectPackage from '../../package.json';

function SideMenu() {
  return (
    <Nav className="col-md-2 d-none d-md-block bg-light sidebar">
      <div className="sidebar-sticky">
        <div className="flex-column">
          <NavItem>
            <LinkContainer exact to="/">
              <Nav.Link>
                <FontAwesomeIcon icon={faTachometerAlt} fixedWidth className="mr-2" />
                Avance de Venta
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/monitor-pendientes">
              <Nav.Link>
                <FontAwesomeIcon icon={faClipboardList} fixedWidth className="mr-2" />
                Monitor de Pendientes
              </Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/pendientes-entregas">
              <Nav.Link>
                <FontAwesomeIcon icon={faClipboardList} fixedWidth className="mr-2" />
                Pendientes de Entregas
              </Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/solicitudes">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileAlt} fixedWidth className="mr-2" />
                Pedidos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Logística</span>
          </h6>
          <NavItem>
            <LinkContainer to="/entregas">
              <Nav.Link>
                <FontAwesomeIcon icon={faTruck} fixedWidth className="mr-2" />
                Entregas
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/stock">
              <Nav.Link>
                <FontAwesomeIcon icon={faHdd} fixedWidth className="mr-2" />
                Stock
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/expresos">
              <Nav.Link>
                <FontAwesomeIcon icon={faDolly} fixedWidth className="mr-2" />
                Expresos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/puntos-entrega">
              <Nav.Link>
                <FontAwesomeIcon icon={faDolly} fixedWidth className="mr-2" />
                Puntos de Entrega
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Artículos</span>
        </h6>
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/articulos">
              <Nav.Link>
                <FontAwesomeIcon icon={faShoppingCart} fixedWidth className="mr-2" />
                Artículos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/marcas">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="mr-2" />
                Marcas
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/lineas">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="mr-2" />
                Líneas
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/colecciones">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="mr-2" />
                Colecciones
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Clientes</span>
        </h6>
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/clientes">
              <Nav.Link>
                <FontAwesomeIcon icon={faUserTie} fixedWidth className="mr-2" />
                Clientes
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/prospectos">
              <Nav.Link>
                <FontAwesomeIcon icon={faUserTie} fixedWidth className="mr-2" />
                Prospectos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/interacciones">
              <Nav.Link>
                <FontAwesomeIcon icon={faAddressCard} fixedWidth className="mr-2" />
                Interacciones
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/zonas">
              <Nav.Link>
                <FontAwesomeIcon icon={faDolly} fixedWidth className="mr-2" />
                Zonas
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/listas-precio">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth className="mr-2" />
                Listas de Precio
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/combo">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth className="mr-2" />
                Combos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/modificadores-precio">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth className="mr-2" />
                Modif. de Precio
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/cliente-tipos">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="mr-2" />
                Tipos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/vendedores">
              <Nav.Link>
                <FontAwesomeIcon icon={faSuitcase} fixedWidth className="mr-2" />
                Vendedores
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Proveedores</span>
        </h6>
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/proveedores">
              <Nav.Link>
                <FontAwesomeIcon icon={faIndustry} fixedWidth className="mr-2" />
                Proveedores
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/proveedores/pedido">
              <Nav.Link>
                <FontAwesomeIcon icon={faIndustry} fixedWidth className="mr-2" />
                Pedidos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Reportes</span>
        </h6>

        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/reportes-diarios">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileAlt} fixedWidth className="mr-2" />
                Reporte Diario
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/reporte-clientes">
              <Nav.Link>
                <FontAwesomeIcon icon={faUserTie} fixedWidth className="mr-2" />
                Reportes de clientes
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/reporte-ventas">
              <Nav.Link>
                <FontAwesomeIcon icon={faUserTie} fixedWidth className="mr-2" />
                Reportes de ventas
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/reporte-stock">
              <Nav.Link>
                <FontAwesomeIcon icon={faUserTie} fixedWidth className="mr-2" />
                Reportes de stock
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Administración</span>
        </h6>
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/usuarios">
              <Nav.Link>
                <FontAwesomeIcon icon={faUsers} fixedWidth className="mr-2" />
                Usuarios
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <div className="my-3 px-3">
          <small className="text-muted">{`v${projectPackage.version}`}</small>
        </div>
      </div>
    </Nav>
  );
}

export default SideMenu;
