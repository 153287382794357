import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import APIClient from '../../services/APIClient';
import { DataTable, FormInputField, FormSelectField } from '../../components';
import { columnsProviderDeliveryList as columns, utils } from './utils';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

class ProviderDeliveryList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDataLoading: false,
      providerDeliveries: [],
      providers: [],
      providerId: '',
      deliveryDate: '',
      orderDate: '',
      states: [],
      state: 0
    };
  }

  handleDataChange = (e) => {
    const { id, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  async componentDidMount() {
    this.setState({
      isDataLoading: true,
    });

    const providerDeliveries = await APIClient.get('/provider-delivery');
    const providers = await APIClient.get('/proveedores');
    const states = await APIClient.get('/provider-delivery-state');

    this.setState({
      providerDeliveries: utils.parseDatesDeliveryList(providerDeliveries.data.data),
      providers: providers.data.data,
      states: states.data.data,
      isDataLoading: false,
    });
  }

  applyFilters = async () => {
    const { toastManager } = this.props;
    let { orderDate, deliveryDate } = this.state;
    const { providerId, state } = this.state; 
    try {

      // parse query
      const queryParams = [];

      if (providerId !== '' && providerId !== null) {
        queryParams.push(`filter[provider_id][eq]=${providerId}`);
      }

      if (state !== 0 && state !== null) {
        queryParams.push(`filter[state_code][eq]=${state}`);
      }

      if (orderDate !== '' && orderDate !== null) {
        queryParams.push(`filter[created_date][gt]=${moment(orderDate).format()}`);
      }

      if (deliveryDate !== '' && deliveryDate !== null) {
        queryParams.push(`filter[delivery_date][gt]=${moment(deliveryDate).format()}`);
      }

      const providerDeliveries = await APIClient.get(`/provider-delivery?${queryParams.join('&')}`);

      // save response in state
      this.setState({
        providerDeliveries: utils.parseDatesDeliveryList(providerDeliveries.data.data),
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error.message}`, {
        appearance: error.appearance || 'error',
        autoDismiss: true,
      });
    }
  }

  render() {
    const {
      providerDeliveries,
      providers,
      providerId,
      deliveryDate,
      isDataLoading,
      states,
      state,
      orderDate
    } = this.state;

    return (
      <div>
        <h1 className="page-title"> Pedidos a proveedores </h1>
        <Card className="mb-5 mt-5">
          <Card.Header>Filtros</Card.Header>
          <Card.Body>
            <Row>
              <Col md={3}>
                <FormInputField
                  id="orderDate"
                  type="date"
                  label="Ordenes desde:"
                  value={orderDate}
                  onChange={this.handleDataChange}
                />
              </Col>
              <Col md={3}>
                <FormInputField
                  id="deliveryDate"
                  type="date"
                  label="Entregas desde:"
                  value={deliveryDate}
                  onChange={this.handleDataChange}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="state"
                  value={state}
                  label="Estado"
                  onChange={this.handleDataChange}
                  choices={states}
                  choiceIdField="code"
                  choiceLabelField="description"
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="providerId"
                  value={providerId}
                  label="Proveedor"
                  onChange={this.handleDataChange}
                  choices={providers}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-center">
                  <Button
                    disabled={isDataLoading}
                    className="d-flex py-2 m-1 my-3"
                    variant="primary"
                    onClick={this.applyFilters}
                  >
                    {
                      !isDataLoading
                        ? <p className="m-0">Buscar</p>
                        : <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                    }
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <DataTable
          columns={columns}
          data={providerDeliveries || []}
          keyField="id"
          isDataLoading={isDataLoading}
          addButton="/proveedores/pedido/nuevo"
          showExport={false}
        />
      </div>
    )
  }
}

export default withToastManager(ProviderDeliveryList);
