/* External libraries */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { Button, Col, Row } from 'react-bootstrap';
import 'react-table/react-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';

/* local imports */
import { DataTable, FormSelectField, FormInputField } from '../../components';
import Subtable from '../../components/PendienteEntrega/Subtable';
import APIClient from '../../services/APIClient';

const columnsExcel = [
  {
    dataField: 'CódigoEan',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Articulo',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Linea',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Cantidad_de_pendientes',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Solicitud',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Fecha_de_alta',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Cantidad',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Entregados',
    sort: false,
    hidden: true,
  },
];

class PendienteEntrega extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      curFromDate: '',
      curToDate: '',
      isDataLoading: true,
      clientes: [],
      selectedClienteIds: [],
      selectedVendedorIds: [],
      articulosPendientes: [],
      windowHeight: 0,
      cols: columnsExcel,
      razonSocial: [],
      vendedores: [],
    };
  }

  componentDidMount() {
    this.loadSelectData();
    this.updateWindowDimensions();

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  loadSelectData = async () => {
    const { toastManager } = this.props;
    try {
      this.setState({ isDataLoading: true });
      // get clientes
      const clientesRes = await APIClient.get('/clientes?sortField=razonSocial&sortDir=asc');
      // get vendedores
      const vendedoresRes = await APIClient.get('/vendedores?sortField=nombre&sortDir=asc');
      const vendedores = vendedoresRes.data.data;
      vendedores.forEach((vend) => {
        vend.selected = false;
      });
      const clientes = clientesRes.data.data;
      this.setState({ vendedores, clientes, isDataLoading: false });
    } catch (err) {
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  handleDataChange = (e) => {
    const {
      id, value, options, multiple,
    } = e.target;
    if (multiple) {
      const values = [...options].filter(opt => opt.selected).map(opt => opt.value);
      this.setState(prevState => ({
        ...prevState,
        [id]: values,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  handleSelectAll = (e) => {
    const { id } = e.target;
    this.setState((prevState) => {
      const { clientes, vendedores } = prevState;
      let { selectedClienteIds, selectedVendedorIds } = prevState;
      if (id === 'select-all-clientes') {
        selectedClienteIds = clientes.map(cli => cli.id);
      }
      if (id === 'select-all-vendedores') {
        selectedVendedorIds = vendedores.map(vend => vend.id);
      }
      return { ...prevState, selectedClienteIds, selectedVendedorIds };
    });
  };

  retrievePendientes = async () => {
    const { toastManager } = this.props;
    const { clientes, vendedores } = this.state;
    let {
      selectedClienteIds, selectedVendedorIds, curFromDate, curToDate,
    } = this.state;

    this.setState({ isDataLoading: true });
    try {
      if (curToDate === '') {
        // toDate is empty
        curToDate = moment().add(14, 'd').format('YYYY-MM-DD');
      }
      if (curFromDate === '') {
        // fromDate is empty
        curFromDate = moment().format('YYYY-MM-DD');
      }
      if (selectedClienteIds.length === clientes.length) {
        selectedClienteIds = [];
      }
      if (selectedVendedorIds.length === vendedores.length) {
        selectedVendedorIds = [];
      }
      const queryParameters = `from_date=${curFromDate}&to_date=${curToDate}&clienteIds=${selectedClienteIds.join(
        ',',
      )}&vendedorIds=${selectedVendedorIds.join(',')}`;
      const artPendientes = await APIClient.get(`/reportes-articulos-pendientes?${queryParameters}`);
      this.setState(prevState => ({
        ...prevState,
        articulosPendientes: artPendientes.data.data,
        curFromDate,
        curToDate,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`No se pudo obtener el listado de pendientes. ${error}`, {
        appearance: 'error',
      });
    }
  };

  // get excel
  getExportData = async () => {
    const { toastManager } = this.props;
    const { selectedClienteIds } = this.state;

    try {
      if (selectedClienteIds.length === 0) {
        return [];
      }
      const queryParams = `clientId=${selectedClienteIds}`;
      const apiResponse = await APIClient.get(`/reportes-articulos-pendientes/detalles-solicitudes?${queryParams}`);
      return apiResponse.data.data[0];
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  updateWindowDimensions = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  renderSelectAllButtons = (entityName) => {
    if (entityName === 'clientes') {
      return (
        <p className="m-0">
          {entityName.substr(0, 1).toUpperCase()}
          {entityName.substr(1)}
          (
          <button
            id={`select-all-${entityName}`}
            type="submit"
            className="link-button text-primary"
            onClick={e => this.handleSelectAll(e, 'selectedClienteIds')}
          >
            Seleccionar todos
          </button>
          )
        </p>
      );
    }
    if (entityName === 'vendedores') {
      return (
        <p className="m-0">
          {entityName.substr(0, 1).toUpperCase()}
          {entityName.substr(1)}
          (
          <button
            id={`select-all-${entityName}`}
            type="submit"
            className="link-button text-primary"
            onClick={e => this.handleSelectAll(e, 'selectedVendedorIds')}
          >
            Seleccionar todos
          </button>
          )
        </p>
      );
    }
    return '';
  };

  render() {
    const {
      articulosPendientes,
      cols,
      curFromDate,
      curToDate,
      isDataLoading,
      clientes,
      selectedClienteIds,
      selectedVendedorIds,
      vendedores,
      razonSocial,
    } = this.state;

    const columns = [
      {
        dataField: 'CódigoEan',
        text: 'CódigoEan',
        sort: true,
        editable: false,
      },
      {
        dataField: 'descripcion',
        text: 'Articulo',
        sort: true,
        editable: false,
      },
      {
        dataField: 'linea',
        text: 'Línea',
        sort: true,
        editable: false,
      },
      {
        dataField: 'pendienteEntrega',
        text: 'Cant. Pendiente',
        sort: true,
        editable: false,
      },
    ];

    const expandRow = {
      showExpandColumn: true,
      renderer: row => <Subtable row={row} clientId={selectedClienteIds} />,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
    };

    const selectRowProps = {
      mode: 'checkbox',
      clickToSelect: true,
      hideSelectColumn: true,
    };
    return (
      <div>
        <h1 className="page-title">Articulos Pendientes de Entrega</h1>
        <Row>
          <Col md={3}>
            <FormInputField
              id="curFromDate"
              type="date"
              label="Fecha desde:"
              defaultValue={curFromDate !== '' ? curFromDate : null}
              onChange={this.handleDataChange}
              required
            />
          </Col>
          <Col md={3}>
            <FormInputField
              id="curToDate"
              type="date"
              label="Fecha hasta:"
              onChange={this.handleDataChange}
              defaultValue={curToDate !== '' ? curToDate : null}
              min={curFromDate !== '' ? curFromDate : null}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormSelectField
              id="selectedClienteIds"
              value={selectedClienteIds}
              label={this.renderSelectAllButtons('clientes')}
              onChange={this.handleDataChange}
              choices={clientes}
              choiceIdField="id"
              choiceLabelField="razonSocial"
              multiple
            />
          </Col>
          <Col md={6}>
            <FormSelectField
              id="selectedVendedorIds"
              label={this.renderSelectAllButtons('vendedores')}
              value={selectedVendedorIds}
              onChange={this.handleDataChange}
              choices={vendedores}
              choiceIdField="id"
              choiceLabelField="nombre"
              multiple
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex flex-row justify-content-center">
              <Button
                disabled={isDataLoading}
                className="d-flex py-2 m-1 my-3"
                variant="primary"
                onClick={this.retrievePendientes}
              >
                {
                  !isDataLoading
                    ? <p className="m-0">Buscar</p>
                    : <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                }
              </Button>
            </div>
          </Col>
        </Row>

        <div>
          <DataTable
            isDataLoading={isDataLoading}
            selectRow={selectRowProps}
            columns={columns}
            data={articulosPendientes}
            keyField="articuloId"
            showSearch
            showExport
            enablePagination={false}
            expandRow={expandRow}
            getExportData={this.getExportData}
            cols={cols}
            exportFileName={`Lista Articulos pendientes con detalles Cliente #${razonSocial}`}
          />
        </div>
      </div>
    );
  }
}

export default withToastManager(PendienteEntrega);
