import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormSelectField, FormInputField, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';

class StockList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.apiParams = '';
    const { page, filterNextToExpire } = Utils.sanitizeQuery(['page', 'filterNextToExpire'], props.location.search);
    // const { proximosVencimientos } = Utils.sanitizeQuery(['proximosVencimientos'], props.location.search).page;
    const getPageNumber = parseInt(page);
    const pageNumber = Utils.isPositiveInteger(getPageNumber) ? getPageNumber : 1;
    this.state = {
      articulos: [],
      isDataLoading: true,
      isDataLoadingActualizarStock: false,
      totalSize: 0,
      // FILTROS Y ROW EDIT
      marcas: [],
      lineas: [],
      proveedores: [],
      selectedMarcaIds: [],
      selectedLineaIds: [],
      selectedProveedorIds: [],
      filterStringMarca: '',
      filterStringLinea: '',
      filterStringProveedor: '',
      pageNumber,
      proyeccionDias: '',
      lastActualizationDateApiStock: '',
      freeText: '',
      pagination: { limit: 10, offset: 0, page: 1 },
      sorting: {},
      vencidos: '',
      nextToExpire: filterNextToExpire || '',
    };

    this.loadStock = this.loadStock.bind(this);
  }

  componentDidMount() {
    this.loadStock();
    this.getFilterAndEditData();
  }

  /**
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * receives table changes, makes custom API calls and saves results to state.
   */
  onTableUpdate = async (queryParameters) => {
    await this.getLastActualizeDateApiStock();
    const vencidosONoVencidos = this.state.vencidos;
    const { nextToExpire } = this.state;
    const { toastManager } = this.props;
    const { state } = this;
    let freeText, pagination, sorting;
    if (!queryParameters) {
      pagination = state.pagination;
      sorting = state.sorting;
      freeText = state.freeText;
    } else {
      freeText = queryParameters.freeText;
      pagination = queryParameters.pagination;
      sorting = queryParameters.sorting;
    }

    const { selectedMarcaIds, selectedLineaIds, selectedProveedorIds, proyeccionDias } = this.state;
    try {
      let { direction, field } = sorting;
      const filterQuery = `marcaCodigos=${selectedMarcaIds}&lineaIds=${selectedLineaIds}&proveedorIds=${selectedProveedorIds}&vencidos=${vencidosONoVencidos}&nextToExpire=${nextToExpire}`;
      const proyeccionStringQuery = `proyeccionDias=${proyeccionDias}`;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || 'codigoEan13'}&sortDir=${
        direction || 'desc'
      }&excludeAssocFields=imagenes`;
      const articulosRes = await APIClient.get(
        `/reportes-stock/articulos?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}&${filterQuery}&${proyeccionStringQuery}`,
      );
      const stock = articulosRes.data.data;
      window.history.pushState({ page: pagination.page }, '', `?page=${pagination.page}`);
      await this.setState({
        stock,
        totalSize: articulosRes.data.meta.total,
        pageNumber: pagination.page,
        freeText,
        sorting,
        pagination,
        vencidos: vencidosONoVencidos,
      });
      this.getReportRows();
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  onChangeTable = () => {
    const { stock } = this.state;
  };

  getReportRows = async () => {
    const { toastManager } = this.props;
    const { stock } = this.state;
    this.setState({ isDataLoading: true });
    try {
      const getTotalResult = await this.getTotals(stock);
      this.setState({ reportRows: getTotalResult, stock: getTotalResult });
    } catch (err) {
      console.error('Error al obtener la información del reporte: ', err);
      toastManager.add(`No se pudo obtener el listado de stock. ${err}`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };

  getTotals = async (rowsArray) => {
    rowsArray.map((art) => {
      let cantidad = 0;
      let disponible = 0;
      let reservado = 0;
      const proxVenc = [];

      art.stock.forEach((artStock) => {
        cantidad += artStock.cantidad;
        disponible += artStock.disponible;
        reservado += artStock.reservado;
        artStock.parseDate = new Date(Date.parse(artStock.vencimiento)).toLocaleDateString('es-AR');
        proxVenc.push(Date.parse(artStock.vencimiento));
      });
      art.cantidadTotal = cantidad - reservado;
      art.disponibleTotal = disponible;
      art.reservadoTotal = reservado;
      const now = Date.now();
      const compareDatesArray = proxVenc.map((venc) => {
        if (now <= venc) {
          return venc - now;
        } else {
          return now;
        }
      });
      const minimum = Math.min(...compareDatesArray);
      const dateString = new Date(proxVenc.find((venc) => now + minimum === venc));
      const date = proxVenc.find((venc) => now + minimum === venc);
      art.proximoVenc = date ? dateString.toLocaleDateString('es-AR') : 'vencido';
      art.diasRequerido = art.promedioVentaDiario && art.promedioVentaDiario > 0 ? Math.round(disponible / art.promedioVentaDiario) : 0;
      art.stockSinPend = disponible - art.pendientes;
      art.proyeccionDeCompra = art.proyeccionDeCompra ? art.proyeccionDeCompra - disponible : 0;
      return art;
    });
    return rowsArray;
  };

  getExportData = async () => {
    const vencidosONoVencidos = this.state.vencidos;
    const { toastManager } = this.props;
    const { selectedMarcaIds, selectedLineaIds, selectedProveedorIds, totalSize, freeText, sorting, proyeccionDias } = this.state;
    const { direction, field } = sorting;
    const proyeccionStringQuery = `proyeccionDias=${proyeccionDias}`;
    const filterQuery = `marcaCodigos=${selectedMarcaIds}&lineaIds=${selectedLineaIds}&proveedorIds=${selectedProveedorIds}&vencidos=${vencidosONoVencidos}`;
    try {
      const articulosRes = await APIClient.get(
        `/reportes-stock/articulos?sortField=${field ? field : 'codigoEan13'}&sortDir=${
          direction ? direction : 'desc'
        }&limit=${totalSize}&offset=0&freeText=${freeText && `%${freeText}%`}&${filterQuery}&${proyeccionStringQuery}`,
      );
      const getTotalForExportResult = await this.getTotals(articulosRes.data.data);

      return getTotalForExportResult;
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  async loadStock() {
    this.getLastActualizeDateApiStock();
    const { toastManager } = this.props;
    const { pageNumber } = this.state;
    const offset = (pageNumber - 1) * 10;
    try {
      const articulosRes = await APIClient.get(
        `/reportes-stock/articulos?limit=10&sortField=codigoEan13&sortDir=desc&offset=${offset}&excludeAssocFields=imagenes`,
      );

      this.setState({
        stock: articulosRes.data.data,
        isDataLoading: false,
        totalSize: articulosRes.data.meta.total,
      });
      this.getReportRows();
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  // FILTROS

  getFilterAndEditData = async () => {
    const { toastManager } = this.props;
    try {
      // get marcas
      const marcasRes = await APIClient.get('/marcas');
      // get lineas
      const lineasRes = await APIClient.get('/lineas');
      // get proveedores
      const proveedoresRes = await APIClient.get('/proveedores');

      this.setState({
        marcas: marcasRes.data.data,
        lineas: lineasRes.data.data,
        proveedores: proveedoresRes.data.data,
      });
    } catch (err) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  createSelectAllButtons = (entityName) => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)}(
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}>
        Seleccionar todos
      </button>
      )
    </p>
  );

  handleDataChange = (e) => {
    const { id, value, options, multiple } = e.target;
    if (multiple) {
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState((prevState) => ({
        ...prevState,
        [id]: values,
      }));
    } else {
      this.setState(async (prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  handleSelectAll = (entityName) => {
    this.setState((prevState) => {
      const { marcas, lineas, proveedores } = prevState;
      let { selectedMarcaIds, selectedLineaIds, selectedProveedorIds, filterStringMarca, filterStringLinea, filterStringProveedor } =
        prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'marcas':
          selectedMarcaIds = marcas.map((cla) => cla.codigo);
          filterStringMarca = Utils.prepareQueryToFilter('marcaCodigo', 'or', selectedMarcaIds);
          break;
        case 'lineas':
          selectedLineaIds = lineas.map((cli) => cli.id);
          filterStringLinea = Utils.prepareQueryToFilter('lineaId', 'or', selectedLineaIds);
          break;
        case 'proveedores':
          selectedProveedorIds = proveedores.map((col) => col.id);
          filterStringProveedor = Utils.prepareQueryToFilter('proveedorId', 'or', selectedProveedorIds);
          break;
        default:
          break;
      }

      return {
        ...prevState,
        selectedMarcaIds,
        selectedLineaIds,
        selectedProveedorIds,
        filterStringMarca,
        filterStringLinea,
        filterStringProveedor,
      };
    });
  };

  filtrarVencidos = async (v) => {
    this.setState({ vencidos: v.target.value ? '' : 'on' });
  };

  filterNextToExpire = async (e) => {
    this.setState({ nextToExpire: e.target.value ? '' : 'on' });
  };

  renderSelectedMarcas = () => {
    const { selectedMarcaIds, marcas } = this.state;
    const selected = marcas.filter((mar) => selectedMarcaIds.includes(mar.codigo));
    return selected.length > 0
      ? selected.map((mar, i) => (
          <h4 key={`mar-${i}`} className="d-inline">
            <Badge variant="primary" className="ml-1">
              {mar.descripcion}
            </Badge>
          </h4>
        ))
      : '(ninguno)';
  };

  renderSelectedLineas = (size) => {
    const { selectedLineaIds, lineas } = this.state;
    const selectedLineaIdsToNumber = selectedLineaIds.map((selArt) => parseInt(selArt));
    const selected = lineas.filter((lin) => selectedLineaIdsToNumber.includes(lin.id));
    return selected.length > 0
      ? selected.map((lin, i) => (
          <h4 key={`lin-${i}`} className="d-inline">
            <Badge variant="primary" className="ml-1">
              {lin.descripcion}
            </Badge>
          </h4>
        ))
      : '(ninguno)';
  };

  renderSelectedProveedores = () => {
    const { selectedProveedorIds, proveedores } = this.state;
    const selectedProveedorIdsToNumber = selectedProveedorIds.map((selArt) => parseInt(selArt));
    const selected = proveedores.filter((pro) => selectedProveedorIdsToNumber.includes(pro.id));
    return selected.length > 0
      ? selected.map((pro, i) => (
          <h4 key={`pro-${i}`} className="d-inline">
            <Badge variant="primary" className="ml-1">
              {pro.nombre}
            </Badge>
          </h4>
        ))
      : '(ninguno)';
  };

  // FIN_FILTROS

  handleInputChange = (e) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      proyeccionDias: value,
    }));
  };

  actualizeStockApiData = async () => {
    const { toastManager } = this.props;
    this.setState({ isDataLoadingActualizarStock: true });
    try {
      const date = await APIClient.get('/reportes-stock/actualizar');
      this.onTableUpdate({
        freeText: '',
        pagination: { limit: 10, offset: 0, page: 1 },
        sorting: {},
      });
      this.setState({ lastActualizationDateApiStock: date.data.data });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoadingActualizarStock: false });
    }
  };

  getLastActualizeDateApiStock = async () => {
    const { toastManager } = this.props;
    try {
      const date = await APIClient.get('/reportes-stock/get-date');
      this.setState({ lastActualizationDateApiStock: date.data.data });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  render() {
    const {
      stock,
      marcas,
      lineas,
      proveedores,
      isDataLoading,
      isDataLoadingActualizarStock,
      lastActualizationDateApiStock,
      totalSize,
      selectedMarcaIds,
      selectedLineaIds,
      selectedProveedorIds,
      pageNumber,
      vencidos,
      nextToExpire,
      freeText,
    } = this.state;
    const dateObject = new Date(parseInt(lastActualizationDateApiStock, 10));
    const dateActualizationApiStockStringLocale = dateObject.toLocaleDateString('es-AR');
    const timeActualizationApiStockStringLocale = dateObject.toLocaleTimeString('es-AR');
    const events = {
      onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
        e.target.style.cursor = `pointer`;
      },
      onMouseLeave: (e, column, columnIndex, row, rowIndex) => {},
    };
    const columns = [
      {
        dataField: 'proveedor.nombre',
        text: 'Proveedor',
        sort: true,
        events: events,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
        events: events,
      },
      {
        dataField: 'linea.descripcion',
        text: 'Línea',
        sort: true,
        events: events,
      },
      {
        dataField: 'erpCodigo',
        text: 'Codigo',
        sort: true,
        events: events,
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
        // sort: true,
        events: events,
      },
      {
        dataField: 'codigoEan13',
        text: 'ean13',
        sort: true,
        events: events,
      },
      {
        dataField: 'proximoVenc',
        text: 'vencimiento',
        sort: true,
        hidden: true,
        events: events,
      },
      {
        dataField: 'cantidadTotal',
        text: 'Unidades en stock',
        sort: true,
        events: events,
        classes: 'text-center',
      },
      {
        dataField: 'pendientes',
        text: 'Pendientes en Unidades',
        events: events,
        classes: 'text-center',
        formatter: (cellValue) => {
          if (cellValue) {
            return cellValue;
          } else {
            return 0;
          }
        },
      },
      {
        dataField: 'stockSinPend',
        text: 'Stock descontando pendientes',
        events: events,
        classes: 'text-center',
        formatter: (cellValue) => {
          if (cellValue) {
            return cellValue;
          } else {
            return 0;
          }
        },
      },
      {
        dataField: 'diasRequerido',
        text: 'Días de stock',
        events: events,
        classes: 'text-center',
        formatter: (cellValue) => {
          if (cellValue) {
            return cellValue;
          } else {
            return '-';
          }
        },
      },
      {
        dataField: 'proyeccionDeCompra',
        text: 'Proyección de compra',
        events: events,
        classes: 'text-center',
        formatter: (cellValue) => {
          if (cellValue) {
            return cellValue;
          } else {
            return '-';
          }
        },
      },
      {
        dataField: 'promedioVentaDiario',
        text: 'Promedio de venta diario',
        events: events,
        classes: 'text-center',
        formatter: (cellValue) => {
          if (cellValue) {
            return cellValue.toFixed(1);
          } else {
            return 0;
          }
        },
      },
    ];

    const expandRow = {
      showExpandColumn: true,
      renderer: (row) => (
        <table className="w-100">
          <thead className="font-weight-bold">
            <tr>
              <th>Descripción en stock</th>
              <th>ean13</th>
              <th>Línea</th>
              <th>Marca</th>
              <th>Proveedor</th>
              <th className="sortable">Cantidad</th>
              <th className="sortable">Disponible</th>
              <th className="sortable">Reservado</th>
              <th className="sortable">Estado</th>
              <th className="sortable">Vencimiento</th>
            </tr>
          </thead>
          <tbody>
            {row.stock.map((det, index) => (
              <tr key={`${det.id}_${index}`}>
                <td>{det.nombreprod}</td>
                <td>{det.partida}</td>
                <td>{det.linea ? det.linea.descripcion : ''}</td>
                <td>{det.marca ? det.marca.descripcion : ''}</td>
                <td>{det.proveedorObj ? det.proveedorObj.nombre : ''}</td>
                <td className="text-center">{det.cantidad}</td>
                <td className="text-center">{det.disponible}</td>
                <td className="text-center">{det.reservado}</td>
                <td>{det.estadomer}</td>
                <td>{det.parseDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
    };

    return (
      <div>
        <h1 className="page-title">Stock</h1>
        <Row>
          <Col>
            <h4>
              Fecha de última actualización {dateActualizationApiStockStringLocale} {timeActualizationApiStockStringLocale}
            </h4>
            <Button
              disabled={isDataLoading || isDataLoadingActualizarStock}
              className="d-flex py-2 m-1 my-3"
              variant="primary"
              onClick={() => this.actualizeStockApiData()}>
              {!(isDataLoading || isDataLoadingActualizarStock) ? (
                <p className="m-0">Actualizar</p>
              ) : (
                <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
              )}
            </Button>
          </Col>
        </Row>

        <Card>
          <Card.Header>Filtros</Card.Header>
          <Card.Body>
            <Row className="mb-2">
              <Col md={6} className="mb-3">
                <FormSelectField
                  id="selectedMarcaIds"
                  label={this.createSelectAllButtons('marcas')}
                  value={selectedMarcaIds}
                  onChange={this.handleDataChange}
                  choices={marcas}
                  choiceIdField="codigo"
                  choiceLabelField="descripcion"
                  placeholder="(ninguno)"
                  multiple
                />
                marcas seleccionadas:
                {this.renderSelectedMarcas()}
              </Col>

              <Col md={6} className="mb-3">
                <FormSelectField
                  id="selectedLineaIds"
                  label={this.createSelectAllButtons('lineas')}
                  value={selectedLineaIds}
                  onChange={this.handleDataChange}
                  choices={lineas}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  placeholder="(ninguno)"
                  multiple
                />
                <div>
                  líneas seleccionadas:
                  {this.renderSelectedLineas()}
                </div>
              </Col>

              <Col md={6}>
                <FormSelectField
                  id="selectedProveedorIds"
                  label={this.createSelectAllButtons('proveedores')}
                  value={selectedProveedorIds}
                  onChange={this.handleDataChange}
                  choices={proveedores}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                  placeholder="(ninguno)"
                  multiple
                />
                proveedores seleccionados:
                {this.renderSelectedProveedores()}
              </Col>
              <Col md={6}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <Card>
                          <Card.Header>Filtros de vencimiento</Card.Header>
                          <Card.Body>
                            <FormCheckField
                              id="nextToExpire"
                              value={nextToExpire}
                              label="Filtrar próximos a vencer"
                              defaultChecked={nextToExpire}
                              onChange={(e) => this.filterNextToExpire(e)}
                            />
                            <FormCheckField
                              id="vencidos"
                              name="subscribe"
                              value={vencidos}
                              label="Filtrar vencidos"
                              defaultChecked={vencidos}
                              onChange={(e) => this.filtrarVencidos(e)}
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card>
                          <Card.Header>Proyección de compra (días)</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={6}>
                                <FormInputField
                                  id="diasProyeccion"
                                  label=""
                                  type="text"
                                  defaultValue="0"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </Col>
                              <Col md={6}>
                                <Button
                                  disabled={isDataLoading}
                                  className="d-flex py-2 m-1 my-3"
                                  variant="primary"
                                  onClick={() => this.onTableUpdate()}>
                                  {!isDataLoading ? (
                                    <p className="m-0">Calcular</p>
                                  ) : (
                                    <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row>
          <Col>
            <div className="d-flex flex-row justify-content-center">
              <Button
                disabled={isDataLoading}
                className="d-flex py-2 m-1 my-3"
                variant="primary"
                onClick={() =>
                  this.onTableUpdate({
                    freeText: `${freeText ? freeText : ''}`,
                    pagination: { limit: 10, offset: 0, page: 1 },
                    sorting: {},
                    // sorting: { field: "erpCodigo", direction: "ASC" },
                  })
                }>
                {!isDataLoading ? <p className="m-0">Buscar</p> : <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />}
              </Button>
            </div>
          </Col>
        </Row>

        <DataTable
          remote={{
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false,
          }}
          totalSize={totalSize}
          columns={columns}
          data={stock ? stock : []}
          onTableUpdate={this.onTableUpdate}
          isDataLoading={isDataLoading}
          keyField="id"
          getExportData={this.getExportData}
          onChange={() => this.onChangeTable()}
          //  exportURL={`/articulos/export.csv?${this.apiParams}`}
          //     addButton="/articulos/nuevo"
          //      updateRowField={this.updateRowField}
          pageNumber={pageNumber}
          expandRow={expandRow}
          exportFileName="Stock"
        />
      </div>
    );
  }
}

export default withToastManager(StockList);
