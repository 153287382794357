import React, { Component } from 'react';
import { Card, Tabs, Tab, Col, Row } from 'react-bootstrap';

import Reporte from '../../components/ReporteDiario/Reporte';

export default class ReporteDiario extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Row className="d-flex align-items-center page-title">
          <Col md={6}>
            <h1>Reporte Diario</h1>
          </Col>
          <Col md={6} className="d-flex justify-content-center">
            <p className="my-auto text-dark">Este reporte tiene en cuenta las entregas facturadas</p>
          </Col>
        </Row>
        <Card.Header>Filtros</Card.Header>
        <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
          <Tab eventKey="general" title="General">
            <Reporte entidad="General" />
          </Tab>
          <Tab eventKey="cliente" title="Cliente">
            <Reporte entidad="Clientes" />
          </Tab>
          <Tab eventKey="articulo" title="Articulo">
            <Reporte entidad="Articulos" />
          </Tab>
          <Tab eventKey="proveedores" title="Proveedores">
            <Reporte entidad="Proveedores" />
          </Tab>
          <Tab eventKey="marca" title="Marca">
            <Reporte entidad="Marcas" />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
