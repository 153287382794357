import React from 'react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import APIClient from '../services/APIClient';
import {
  generateWorkbookObject, s2ab, CSVToArrays, parseDataToExport, getNowDateLocale, getNestedRange
} from './utils';

import config from '../config';


const DataTableExportCSVButton = (props) => {
  const {
    getExportData, url, cols, propsTP,
    exportCustomHeaders,
    exportExtraFields,
    exportFileName,
    disabled
  } = props;

  const handleClick = async () => {
    let formatedDataToExport;
    let filename;
    if (url) {
      const response = await APIClient.get(url);
      if (response.data.data && typeof response.data.data !== 'string') {
        filename = `${exportFileName}_${getNowDateLocale()}.${config.export.extension}`;
        formatedDataToExport = await parseDataToExport(response.data.data, cols, exportExtraFields, exportCustomHeaders);
      } else if (response.data && typeof response.data === 'string') {
        filename = response.headers['content-disposition'].split(';')[1].substr(10);
        formatedDataToExport = await CSVToArrays(response.data);
      }
    } else if (getExportData()) {
      filename = `${exportFileName}_${getNowDateLocale()}.${config.export.extension}`;
      const exportData = await getExportData();
      formatedDataToExport = await parseDataToExport(exportData, cols, exportExtraFields, exportCustomHeaders);
    } else {
      propsTP.csvProps.onExport();
      return;
    }
    const wbout = await generateWorkbookObject(formatedDataToExport);
    const s2abOut = await s2ab(wbout);
    const blobObject = new Blob([s2abOut], { type: 'application/octet-stream' });
    saveAs(blobObject, filename);
  };

  return (
    <div>
      <Button disabled={disabled} variant="primary" onClick={handleClick}>
        <FontAwesomeIcon icon={faFileExcel} fixedWidth />
         Export to EXCEL
       </Button>
    </div>
  );
};



DataTableExportCSVButton.propTypes = {
  getExportData: PropTypes.func,
  url: PropTypes.string,
  exportFileName: PropTypes.string,
  cols: PropTypes.arrayOf(PropTypes.object),
  exportCustomHeaders: PropTypes.arrayOf(PropTypes.string),
  exportExtraFields: PropTypes.arrayOf(PropTypes.string),
  propsTP: PropTypes.object,
};

DataTableExportCSVButton.defaultProps = {
  getExportData: () => { },
  url: '',
  exportFileName: '',
  cols: [{}],
  exportCustomHeaders: [''],
  exportExtraFields: [''],
  propsTP: {},
};

export default DataTableExportCSVButton;
