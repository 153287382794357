import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button, Col, Form, Row
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FormInputField } from '../components';
import APIClient from '../services/APIClient';


class PasswordChange extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmedPassword: '',
      isDataLoading: false,
      isMatch: false,
      isValid: false,
      newPassword: '',
      oldPassword: '',
    };
  }

  onFormChange = (e) => {
    const { id, value } = e.target;
    this.setState(prevState => {
      let { isMatch, isValid } = prevState
      if(id.match(/(new|confirmed)Password/)){
        isMatch = this.validatePasswords(value);
      }
      if (id === 'newPassword') {
        if (value.length >= 6) {
          isValid = true;
        } else {
          isValid = false
        }
      }
      return { isMatch, isValid, [id]: value }
    })
  }


  onFormSubmit = async (e) => {
    e.preventDefault(e);
    e.stopPropagation();
    const { toastManager } = this.props;
    const { newPassword, oldPassword } = this.state;
    const body = { newPassword, oldPassword };
    this.setState({ isDataLoading: true });
    try {
      await APIClient.post('/me/cambiar-contrasena', body);
      toastManager.add('Se cambió la contraseña correctamente.', { appearance: 'success' });
      this.setState({ confirmedPassword: '', isMatch: false, isValid: false, newPassword: '', oldPassword: '' });
    } catch (err) {
      if (err.response.status === 400) {
        toastManager.add(`Ocurrió un error: ${err.response.data.message}`, { appearance: 'warning' });
      } else {
        toastManager.add(`Ocurrió un error al intentar actualizar la contraseña: ${err.message}`, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  validatePasswords = (confirmedPassword) => {
    const { newPassword } = this.state;
    return (newPassword === confirmedPassword);
  }

  render() {
    const {
      confirmedPassword, isDataLoading, isMatch, isValid, generatePassword, newPassword, oldPassword,
    } = this.state;
    return (
      <div>
        <h1 className="page-title">Cambiar Contraseña</h1>

        <Form onSubmit={this.onFormSubmit} onChange={this.onFormChange}>
          <Row>
            <Col md={4}>
              <FormInputField
                id="oldPassword"
                label="Contraseña Actual"
                type="password"
                value={oldPassword}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormInputField
                id="newPassword"
                label="Nueva Contraseña"
                type="password"
                value={newPassword}
                isValid={isValid}
                isInvalid={!isValid && newPassword.length > 0}
                invalidText="La contraseña debe tener un mínimo de 6 caracteres."
                disabled={isDataLoading}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormInputField
                id="confirmedPassword"
                label="Confirmar Contraseña"
                type="password"
                value={confirmedPassword}
                isValid={isMatch && isValid}
                isInvalid={!isMatch && confirmedPassword.length > 0}
                invalidText="Las contraseñas no coinciden."
                disabled={isDataLoading}
                required
              />
            </Col>
          </Row>
          <Button type="submit" variant="primary" disabled={isDataLoading || (generatePassword ? false : !(isMatch && isValid))}>
            {isDataLoading ? <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" /> : <FontAwesomeIcon icon={faCheck} fixedWidth className="mr-1" />}
            Cambiar
          </Button>
        </Form>
      </div>
    );
  }
}


export default withToastManager(PasswordChange);
