import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export const columnsProviderDelivery = [
  {
    dataField: 'erpCodigo',
    text: 'Código',
    sort: true,
    editable: false,
  },
  {
    dataField: 'descripcion',
    text: 'Descripción',
    sort: true,
    editable: false,
  },
  {
    dataField: 'quantity',
    text: 'Cantidad',
    sort: true,
    editable: false,
    align: 'right',
  },
];

export const columnsProviderDeliveryList = [
  {
    dataField: 'provider.nombre',
    text: 'Proveedor',
    sort: true,
    editable: false,
  },
  {
    dataField: 'orderNumber',
    text: 'Número de orden',
    sort: true,
    editable: false,
    align: 'right',
  },
  {
    dataField: 'state.description',
    text: 'Estado',
    sort: true,
    editable: false,
  },
  {
    dataField: 'createdDate',
    text: 'Fecha de orden',
    sort: true,
    editable: false,
  },
  {
    dataField: 'deliveryDate',
    text: 'Fecha de entrega',
    sort: true,
    editable: false,
  },
  {
    dataField: 'actions',
    isDummyField: true,
    text: '',
    formatter: (cellContent, row) => (
      <ButtonToolbar>
        <ButtonGroup>
          <LinkContainer to={`/proveedores/pedido/${row.id}`}>
            <Button size="sm" variant="outline-primary" title="Editar">
              <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
            </Button>
          </LinkContainer>
        </ButtonGroup>
      </ButtonToolbar>
    ),
  },
]

export const utils = {
  parseDatesDelivery: (providerDelivery) => {
    providerDelivery.createdDate = moment(providerDelivery.createdDate).format('YYYY-MM-DD');
    providerDelivery.deliveryDate = moment(providerDelivery.deliveryDate).format('YYYY-MM-DD');
    return providerDelivery;
  },

  parseDatesDeliveryList: (poroviderDeliveryList) => {
    poroviderDeliveryList.forEach((delivery) => {
      delivery.createdDate = moment(delivery.createdDate).format('YYYY-MM-DD');
      delivery.deliveryDate = moment(delivery.deliveryDate).format('YYYY-MM-DD');
      return delivery;
    })
    return poroviderDeliveryList;
  },

  parseItems: (items) => {
    items.forEach(item => {
      item.erpCodigo = item.article.erpCodigo;
      item.descripcion = item.article.descripcion;
    });
    return items;
  },

  buildArticlesToDeleteArray: (newArticles, previousArticles) => {
    const articlesToDelete = [];

    previousArticles.forEach(previousArticle => {
      let isToDeleteArticle = true;

      newArticles.forEach(newArticle => {
        if (previousArticle.articleId === newArticle.articleId) {
          isToDeleteArticle = false;

          if (previousArticle.quantity !== newArticle.quantity) {
            isToDeleteArticle = true;
          }
        }
      });

      if (isToDeleteArticle) {
        articlesToDelete.push(previousArticle.id);
      }
    });

    return articlesToDelete;
  },

  buildArticlesToAddArray: (newArticles, previousArticles) => {
    const articlesToAdd = [];

    newArticles.forEach(newArticle => {
      let isToAdd = false;

      let article = previousArticles.find(previousArticle => previousArticle.articleId === newArticle.articleId);
  
      if (article) {
        if (article.quantity !== newArticle.quantity) {
          isToAdd = true;
        }
      } else {
        isToAdd = true;
      }

      if (isToAdd) {
        articlesToAdd.push(newArticle);
      }

    });

    return articlesToAdd;
  },

  validations: (entity) => {
    const validation = {
      isOk: true,
      message: ''
    }

    if (entity.orderNumber === '' || entity.orderNumber === undefined || entity.orderNumber === null) {
      validation.isOk = false;
      validation.message = 'Por favor escriba un número de orden'
    }

    if (entity.createdDate === '' || entity.createdDate === undefined || entity.createdDate === null) {
      validation.isOk = false;
      validation.message = 'Por favor seleccione una fecha de factura'
    }

    if (entity.deliveryDate === '' || entity.deliveryDate === undefined || entity.deliveryDate === null) {
      validation.isOk = false;
      validation.message = 'Por favor seleccione una fecha de entrega'
    }

    if (entity.stateCode === '' || entity.stateCode === undefined || entity.stateCode === null) {
      validation.isOk = false;
      validation.message = 'Por favor seleccione un estado de pedido'
    }

    return validation;
  }

}
