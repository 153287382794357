import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField } from '../../components';
import APIClient from '../../services/APIClient';

class ZonaEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get linea
    const lineaRes = await APIClient.get(`/zonas/${id}`);
    const entity = lineaRes.data.data;

    this.setState({ entity });
    return entity;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/zonas', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/zonas/${id}`, entityToSave);
    }

    toastManager.add(
      `Zona ${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/zonas'),
    );
    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Zona nueva' : `Zona #${id}`}</h1>

        <EntityEditForm
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
        >
          <>
            <Row>
              <Col>
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  type="text"
                  defaultValue={entity && entity.descripcion}
                  required
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ZonaEdit);
