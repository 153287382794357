import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Form, InputGroup, Modal, Row,
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  DataTable, EntityEditForm, FormAddressInput, FormInputField, FormSelectField, FormSwitch,
} from '../../components';

import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
class ClienteEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;
    const pageNumbUrl = Utils.sanitizeQuery(['page'], props.location.search).page;
    const pageNum = parseInt(pageNumbUrl);
    const page = Utils.isPositiveInteger(pageNum) ? pageNum : 1;
    this.state = {
      id,
      page,
      isAdding: typeof id === 'undefined',
      selectedLista: {},
      entity: {},
      tipos: [],
      vendedores: [],
      listasPrecio: [],
      puntosEntrega: [],
      selectedListaId: null,
      isDataLoading: typeof id === 'undefined',
      showModal: false,
      condicionesPago: [],
      zonas: [],
      estados:true
      
    };
  }

  getListaPrecioDetailModal = () => {
    // create table for lista precio
    const { isDataLoading, selectedLista, showModal } = this.state;
    const columns = [
      {
        dataField: 'articulo.descripcion',
        text: 'Artículo',
        sort: true,
      },
      {
        dataField: 'talleNombre',
        text: 'Talle',
        sort: true,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        classes: 'text-right',
      },
    ];

    return (
      <Modal size="lg" show={showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          {selectedLista !== undefined && selectedLista !== {} ? (
            <Modal.Title>{selectedLista.descripcion}</Modal.Title>
          ) : (
              <Modal.Title>Lista de Precio</Modal.Title>
            )}
        </Modal.Header>
        <Modal.Body>
          {selectedLista !== undefined && selectedLista !== {} ? (
            <DataTable isDataLoading={isDataLoading} columns={columns} data={selectedLista.detalles || []} keyField="id" />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

  handleActivo = ()=>{
    const estado = !this.state.estados
      this.setState({
        estados:estado
      });
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  saveSelectedListaId = (e) => {
    this.setState({ selectedListaId: e.target.value });
  };

  showModalAndGetArticulos = async () => {
    const { selectedListaId } = this.state;
    // get articulos
    const selectedLista = await APIClient.get(`/listas-precio/${selectedListaId}`);
    this.setState({ selectedLista: selectedLista.data.data, showModal: true });
  };

  onLoadForm = async () => {
    const { clienteId } = this.state;
    // get cliente tipos
    const tiposRes = await APIClient.get('/cliente-tipos');
    // get vendedores
    const vendedoresRes = await APIClient.get('/vendedores');
    // get lista precio
    const listasPrecioRes = await APIClient.get('/listas-precio');
    // get lista precio
    const puntosEntregaRes = await APIClient.get(
      `/clientes/${clienteId}/puntos-entrega`
    );
    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${!pe.depto ? '' : ' ' + pe.depto}
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`
      return pe;
    });
    // get provincias
    const provinciasRes = await APIClient.get('/provincias');
    // get condiciones pago
    const condicionesPago = await APIClient.get('/condiciones-pago');
    // get zonas
    const zonasRes = await APIClient.get('/zonas');
    this.setState({
      tipos: tiposRes.data.data,
      vendedores: vendedoresRes.data.data,
      listasPrecio: listasPrecioRes.data.data,
      provincias: provinciasRes.data.data,
      puntosEntrega: puntosDeEntrega,
      condicionesPago: condicionesPago.data.data,
      zonas: zonasRes.data.data,
    });
  };
  onRetrieveEntity = async () => {
    const { id } = this.state;
    // get cliente
    const clienteRes = await APIClient.get(`/clientes/${id}`);
    const puntosEntregaRes = await APIClient.get(
      `/clientes/${id}/puntos-entrega`
    );
    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${!pe.depto ? '' : ' ' + pe.depto}
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`
      return pe;
    })
    this.setState({
      entity: clienteRes.data.data,
      selectedListaId: clienteRes.data.data.listaPrecioId,
      puntosEntrega: puntosDeEntrega,
    });
    return clienteRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, page, isAdding, estados } = this.state;
    const { history, toastManager } = this.props;
    
    
    let saveResponse = null;
    if (!isAdding && estados == false ) {
       saveResponse = await APIClient.patch(`/clientes/${id}`, {
        estadoId:1,
        ...entityToSave});
      APIClient.get('/clientes/actualizarCodigoInterno'); 

      history.push(`/prospectos?page=${page}`);
      toastManager.add(`Cambio de Estado a Cliente Activo ${saveResponse.data.data.id} exitoso`, {
        appearance: 'success',
        autoDismiss: true,

      });
          return saveResponse.data.data; 

    }if (isAdding && estados == true ) {
       saveResponse = await APIClient.post('/clientes', {
        estadoId:3,
        ...entityToSave});
      APIClient.get('/clientes/actualizarCodigoInterno'); 

      history.push(`/prospectos?page=${page}`);
    toastManager.add(`Cliente Prospecto ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
      return saveResponse.data.data;
      
    } else if(!isAdding && estados == true){
       saveResponse = await APIClient.patch(`/clientes/${id}`,{
        estadoId:3,
        ...entityToSave}); 

        history.push(`/prospectos?page=${page}`);
        toastManager.add(`Cliente Prospecto ${saveResponse.data.data.id} actualizado con éxito`, {
          appearance: 'success',
          autoDismiss: true,
        });
          return saveResponse.data.data;
    }
  
    
  };
  
  render() {
    const {
      id,
      entity,
      tipos,
      vendedores,
      listasPrecio,
      provincias,
      puntosEntrega,
      isAdding,
      selectedListaId,
      condicionesPago,
      zonas,
    } = this.state;
    
    return (
      
      <div>
        {isAdding ?
          <h1 className="page-title"> Cliente Prospecto nuevo </h1> :
          <Row className="page-title">
            <Col className="d-flex justify-content-between pr-0" md={12}>
              <h1 className="d-flex my-3"> Cliente #{id}
                {entity && entity.estado ?
                  <div className="ml-3 d-flex">
                    {UIUtils.getClienteEstadoBadge(entity.estado.descripcion)}
                  </div>
                  : null}
              </h1>
            </Col>
          </Row>
        }
        {this.getListaPrecioDetailModal()}

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
        >
          
          <>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Información Principal</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormInputField id="razonSocial" label="Razón Social" type="text" defaultValue={entity.razonSocial} required />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="documentoNumero" label="CUIT" type="text" defaultValue={entity.documentoNumero} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="nombreFantasia" label="Nombre de Fantasía" type="text" defaultValue={entity.nombreFantasia} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="codigoInterno" label="Código interno" type="text" defaultValue={entity.codigoInterno} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="tipoId"
                      label="Tipo"
                      defaultValue={entity.tipoId}
                      choices={tipos}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>  
                  <Col className="d-flex align-items-center" md={6}>
                  <Form.Check 
                      type="switch"
                      id="estadoId"
                      label="Pasar a Cliente"
                      onChange={this.handleActivo}
                      
                    />
                  </Col>
        
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Información de Contacto</Card.Title>
                
                <Row>
                  <Col md={6}>
                    <FormInputField id="contacto" label="Contacto Principal" defaultValue={entity.contacto} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="telefono" label="Teléfono Principal" type="text" defaultValue={entity.telefono} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="celular" label="Teléfono móvil" type="text" defaultValue={entity.celular} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="email" label="Email Principal" type="text" defaultValue={entity.email} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="contactoComercial" label="Contacto Comercial" type="text" defaultValue={entity.contactoComercial} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="telefonoComercial" label="Teléfono Comercial" type="tel" defaultValue={entity.telefonoComercial} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="emailComercial" label="Email Comercial" type="text" defaultValue={entity.emailComercial} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="contactoCobranzas" label="Contacto Cobranzas" type="text" defaultValue={entity.contactoCobranzas} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="telefonoCobranzas" label="Teléfono Cobranzas" type="tel" defaultValue={entity.telefonoCobranzas} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="emailCobranzas" label="Email Cobranzas" type="text" defaultValue={entity.emailCobranzas} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormAddressInput provincias={provincias} addMode={isAdding} entity={entity} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="longitud" label="Longitud" type="text" defaultValue={entity.longitud} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="latitud" label="Latitud" type="text" defaultValue={entity.latitud} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Información Comercial</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="vendedorId"
                      label="Ejecutivo comercial"
                      defaultValue={entity.vendedorId}
                      choices={vendedores}
                      choiceIdField="id"
                      choiceLabelField="nombre"
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="listaPrecioId">
                      <Form.Label>Lista de Precio</Form.Label>
                      <InputGroup>
                        <Form.Control
                          as="select"
                          onChange={this.saveSelectedListaId}
                          name="listaPrecioId"
                          defaultValue={entity.listaPrecioId}
                        >
                          {isAdding && <option value="">(Seleccione)</option>}
                          {listasPrecio.map(lista => (
                            <option key={lista.id} value={lista.id}>
                              {lista.descripcion}
                            </option>
                          ))}
                        </Form.Control>
                        {selectedListaId === null || selectedListaId === '' ? null : (
                          <InputGroup.Prepend>
                            <Button onClick={this.showModalAndGetArticulos} size="sm" variant="outline-secondary" title="Detalle">
                              <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="sm" />
                            </Button>
                          </InputGroup.Prepend>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="puntoEntregaId"
                      label="Punto de Entrega"
                      defaultValue={entity.puntoEntregaId}
                      choices={puntosEntrega}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <FormSelectField
                      id="zonaId"
                      label="Zona"
                      defaultValue={entity.zonaId}
                      choices={zonas}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                  <Col md={4}>
                    <FormInputField id="entregaHorario" label="Horario de Entrega" defaultValue={entity.entregaHorario} />
                  </Col>
                  <Col md={2}>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label className="form-label">Días de Entrega</label>
                          <Row className="align-items-center">
                            <Col md={6}>
                              <input
                                id="diasEntrega"
                                name="diasEntrega"
                                type="number"
                                className="form-control text-right"
                                defaultValue={entity.diasEntrega}
                                min={0}
                              />
                            </Col>
                            <Col md={6}>
                              <p className="mb-0">dias.</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormSelectField
                      id="condicionPagoId"
                      label="Condición de Pago"
                      defaultValue={entity.condicionPagoId}
                      choices={condicionesPago}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormSwitch
                      id="excludeFromAverage-switch"
                      label={`Excluir del promedio de venta diario.
                      ${entity.excludeFromAverageDailySale ? ' La funcionalidad está activada para este cliente' : ' La funcionalidad no está activada para este cliente'}`}
                      checked={entity.excludeFromAverageDailySale}
                      labelField="excludeFromAverageDailySale"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ClienteEdit);
