import React from 'react';
import { Route } from 'react-router-dom';
import LineaList from './LineaList';
import LineaEdit from './LineaEdit';

const routes = () => [
  <Route path="/lineas/nuevo" exact component={LineaEdit} key="/lineas/nuevo" />,
  <Route path="/lineas/:id(\d+)" component={LineaEdit} key="/lineas/:id" />,
  <Route path="/lineas" exact component={LineaList} key="/lineas/:id" />,
].map(route => route);

export default routes;
