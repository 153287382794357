import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';
import { PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import DataTableExportCSVButton from './DataTableExportCSVButton';

const DataTableFooter = (props) => {
 const {
  showExport, paginationProps, propsTP, cols, getExportData,
  exportExtraFields, exportCustomHeaders, exportFileName, exportURL, ...otherProps
 } = props;


 return (
  <Card.Footer>
   {otherProps.total && otherProps.total > 0 ? (
    <Row className="mb-2">
     <Col md={{ span: 4, offset: 7 }} className="text-right">
      Total:
                    {' '}
     </Col>
     <Col>{otherProps.total.toFixed(2)}</Col>
    </Row>
   ) : null}
   <Row>
    <Col md={6}>
     {paginationProps && (
      <>
       <SizePerPageDropdownStandalone {...paginationProps} />
       <PaginationListStandalone {...paginationProps} />
      </>
     )}
    </Col>
    {showExport ? (
     <Col md={6} className="text-right">
      <DataTableExportCSVButton
       getExportData={getExportData}
       url={exportURL}
       // {...propsTP.csvProps}
       propsTP={propsTP}
       cols={cols}
       exportExtraFields={exportExtraFields}
       exportCustomHeaders={exportCustomHeaders}
       exportFileName={exportFileName}
       otherProps={otherProps}
      />
     </Col>
    ) : null}
   </Row>
  </Card.Footer>
 );
};

DataTableFooter.propTypes = {
 paginationProps: PropTypes.object,
 propsTP: PropTypes.object,
 otherProps: PropTypes.object,
 showExport: PropTypes.bool,
 cols: PropTypes.arrayOf(PropTypes.object),
 getExportData: PropTypes.func,
 exportURL: PropTypes.string,
 exportExtraFields: PropTypes.arrayOf(PropTypes.string),
 exportCustomHeaders: PropTypes.arrayOf(PropTypes.string),
 exportFileName: PropTypes.string,
};
DataTableFooter.defaultProps = {
 showExport: true,
 paginationProps: null,
 otherProps: null,
 cols: [{}],
 getExportData: () => { },
 exportURL: '',
 exportExtraFields: [''],
 exportCustomHeaders: [''],
 exportFileName: '',
 propsTP: {},
}

export default DataTableFooter;
