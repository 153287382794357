// empty position 0 is added because the days start from position 1
export const days = [
    {
      key: 'LU',
      label: 'Lunes'
    },
    {
      key: 'MA',
      label: 'Martes'
    },
    {
      key: 'MI',
      label: 'Miércoles'
    },
    {
      key: 'JU',
      label: 'Jueves'
    },
    {
      key: 'VI',
      label: 'Viernes'
    },
    {
      key: 'SA',
      label: 'Sábado'
    },
]

/**
 * Return a string with delivery days
 * @param {JSON string} data delivery days information
 */
export function parseDayData(data) {
    if (data && data !== '') {
        const parseData = JSON.parse(data);
        let daysToShow = '';
    
        parseData.forEach(keyDay => {
            const day = days.find(day => day.key === keyDay);
            daysToShow = daysToShow + ` ${day.label}`;
        });
    
        return daysToShow;
    }
    return '';
}

/**
 * Return an array with the selected days
 * @param {Array} data delivery days information
 */
export function checkDayData(data) {
    if (data.length > 0) {
        const checkDayData = days.map(day => {
            const isDaySelected = data.some(d => d === day.key);
            if (isDaySelected) {
                day.checked = true;
                return day;
            } else {
                day.checked = false;
                return day;
            }
        });
    
        return checkDayData;
    };

    return days.map(day => {
        day.checked = false;
        return day;
    });
}