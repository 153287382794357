import React from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  EntityEditForm, FormInputField, FormCheckField, DataTable,
} from '../../components';
import APIClient from '../../services/APIClient';

// these constants will indicate which load modal will be open
const MODAL_MANUAL = 'modal_manual';
const MODAL_EXCEL = 'modal_excel';

class ListaPrecioEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      articulosList: [],
      entity: {},
      id,
      isAdding: typeof id === 'undefined',
      detalles: [],
      deletedDetalles: [],
      isDataLoading: typeof id !== 'undefined',
      newDetalles: [],
      selectedDetalleRowDel: {},
      selectedArticulos: [],
      showAddModal: false,
      showDeleteModal: false,
      showUploadModal: false,
      updatedDetalles: [],
    };
  }

  afterSaveNewDetalle = (row) => {
    // check if row belongs to articulo or ListaPrecioDetalle
    this.onSaveNewDetalle(row);
  };

  afterSaveUpdatedDetalle = (oldValue, newValue, row) => {
    this.onSaveUpdatedDetalle(row, newValue);
  };

  handleUploadModalClose = () => {
    this.setState({ showUploadModal: false });
  };

  uploadExcelModal = () => (
    <Modal size="xl" show={this.state.showUploadModal} onHide={this.handleUploadModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Carga de precios desde un documento excel</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <input name="file" type="file" accept=".xlsx" onChange={this.onReadFile} />
        <Button variant="primary" onClick={this.saveNewDetalles}>
          Listo
        </Button>
        <Button variant="secondary" onClick={this.handleUploadModalClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  onReadFile = (e) => {
    const { toastManager } = this.props;
    if (e.target.files.length > 0) {
      try {
        this.setState({ operationInProgress: true, fileLoadProgress: 0 });
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = function read(e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          this.loadFileToTable(sheet);
        }.bind(this);

        reader.readAsArrayBuffer(file);
      } catch (error) {
        toastManager.add('Por favor verifique el archivo', {
          appearance: 'error',
        });
      }
    }
  };

  loadFileToTable = (data) => {
    const { articulosList } = this.state;
    const toAddProducts = articulosList.filter((e) => {
      for (let i = 1; i < data.length; i += 1) {
        if (data[i][0] === Number(e.codigoEan13)) {
          e.precio = String(data[i][3]);
          return e;
        }
      }
    });
    toAddProducts.forEach((articulo) => {
      this.onSelectArticulo(articulo, true);
    });
  };

  articulosListModal = () => {
    // render articulos modal
    const { articulosList } = this.state;

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => (
          <div>
            {row.erpCodigo}
            <br />
            <small title="EAN">{row.codigoEan13}</small>
          </div>
        ),
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
        editable: false,
      },
      {
        dataField: 'linea.descripcion',
        text: 'Línea',
        sort: true,
        editable: false,
      },
      {
        dataField: 'coleccion.descripcion',
        text: 'Colección',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      // clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectArticulo,
    };

    return (
      <Modal size="xl" show={this.state.showAddModal} onHide={this.handleAddModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Artículos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {articulosList !== undefined && articulosList !== {} && (
            <DataTable
              isDataLoading={this.state.isDataLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={articulosList || []}
              keyField="id"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.saveNewDetalles}>
            Listo
          </Button>
          <Button variant="secondary" onClick={this.handleAddModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  deleteDetalleModal = () => (
    <Modal size="md" show={this.state.showDeleteModal} onHide={this.handleDeleteModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar este detalle?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.onConfirmDelete}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={this.handleDeleteModalClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  handleAddModalClose = () => {
    // close modal
    this.setState({ showAddModal: false });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  prepareToSave = (entityToSave) => {
    /*
    const { updatedDetalles } = this.state;
    entityToSave.detalles = updatedDetalles;
    */
    const { detalles } = this.state;
    entityToSave.detalles = detalles;
    return entityToSave;
  };

  saveNewDetalles = () => {
    // copy detalles and newDetalles from state and merge them
    this.setState(prevState => ({
      detalles: [...prevState.newDetalles, ...prevState.detalles],
      newDetalles: [],
      updatedDetalles: prevState.newDetalles,
      showAddModal: false,
      showUploadModal: false,
    }));
  };

  showAddDetalleModal = async (modal) => {
    const { detalles } = this.state;
    // get articulos and add precio and isSelected to all objects in array. then update state
    const detalleArticuloIds = detalles.map(detalle => detalle.articuloId);
    const articulosListRes = await APIClient.get(`/articulos?filter[id][notIn]=${detalleArticuloIds}&filter[eliminadoFlag][eq]=0`);
    const articulosList = articulosListRes.data.data;
    articulosList.forEach((articulo) => {
      articulo.precio = '';
      articulo.isSelected = false;
    });
    if (modal === MODAL_MANUAL) {
      this.setState({ showAddModal: true, isDataLoading: false, articulosList });
    } else if (modal === MODAL_EXCEL) {
      this.setState({ showUploadModal: true, isDataLoading: false, articulosList });
    }
  };

  onClickingDelete = (row) => {
    this.setState({ showDeleteModal: true, selectedDetalleRowDel: row });
  };

  onClickingUndo = (row) => {
    row.deletedRow = false;
    this.setState((prevState) => {
      const { detalles, deletedDetalles } = prevState;
      const newDetalles = [...detalles];
      const newDeletedDetalles = [...deletedDetalles];
      const detalleIndex = newDeletedDetalles.findIndex(det => det.id === row.id);
      newDeletedDetalles.splice(detalleIndex, 1);
      for (let i = 0; i < newDetalles.length; i += 1) {
        const curDetalle = newDetalles[i];
        if (row.articuloId === curDetalle.articuloId) {
          newDetalles.splice(i, 1);
        }
        if (row.id < curDetalle.id) {
          newDetalles.splice(i, 0, row);
          break;
        }
      }
      return { detalles: [...newDetalles], deletedDetalles: [...newDeletedDetalles] };
    });
  };

  onConfirmDelete = () => {
    // delete detalle from detalles list and add to deletedDetalles
    this.setState((prevState) => {
      const detalles = [...prevState.detalles];
      const deletedDetalles = [...prevState.deletedDetalles];
      const selectedDetalleRowDel = { ...prevState.selectedDetalleRowDel };
      const updatedDetalles = [...prevState.updatedDetalles];

      selectedDetalleRowDel.deletedRow = true;
      deletedDetalles.push(selectedDetalleRowDel);

      const selectedIndex = detalles.findIndex(detalle => detalle.id === selectedDetalleRowDel.id);
      detalles.splice(selectedIndex, 1);
      const updatedIndex = updatedDetalles.findIndex(detalle => detalle.id === selectedDetalleRowDel.id);
      updatedDetalles.splice(updatedIndex, 1);

      return {
        detalles: prevState.entity.id ? [selectedDetalleRowDel, ...detalles] : detalles,
        deletedDetalles,
        selectedDetalleRowDel: {},
        showDeleteModal: false,
      };
    });
  };

  onLoadForm = async () => {
    // get marcas
    // const marcasRes = await APIClient.get('/marcas');
    // this.setState({
    // });
  };

  onRetrieveEntity = async () => {
    const { toastManager } = this.props;
    const { id } = this.state;

    try {
      // get listas precio
      const listasPrecioRes = await APIClient.get(`/listas-precio/${id}`);
      const listaPrecio = listasPrecioRes.data.data;

      listaPrecio.detalles.forEach((detalle) => {
        detalle.isSelected = false;
      });

      const detalles = [...listaPrecio.detalles];

      this.setState({
        detalles,
        entity: listaPrecio,
        isDataLoading: false,
      });
      return listaPrecio;
    } catch (error) {
      this.setState({ isDataLoading: false });
      return toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const {
      id, isAdding, deletedDetalles, detalles,
    } = this.state;
    const { history, toastManager } = this.props;
    try {
      // entityToSave.detalles = updatedDetalles;

      let validator = true;

      // price field validation
      detalles.map((e) => {
        if (e.precio === '') {
          validator = false;
        }
        return e;
      });

      if (validator === false) {
        return toastManager.add('Debe rellenar los campos de precios en la tabla de los productos', {
          appearance: 'warning',
          autoDismiss: true,
        });
      }

      if (deletedDetalles.length > 0) {
        const deletedDetallesIds = deletedDetalles.map(detalle => detalle.id);
        entityToSave.assocToDelete = { detalles: [...deletedDetallesIds] };
      }
      let saveResponse = null;
      if (isAdding) {
        entityToSave.detalles.forEach(detalle => delete detalle.articulo);
        saveResponse = await APIClient.post('/listas-precio', entityToSave);
      } else {
        saveResponse = await APIClient.patch(`/listas-precio/${id}`, entityToSave);
      }

      history.push('/listas-precio');
      return toastManager.add(`Lista de Precio ${saveResponse.data.data.id} guardada con éxito`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      return toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  onSaveNewDetalle = (articulo) => {
    const { id } = this.state;

    this.setState((prevState) => {
      let newDetalles = [...prevState.newDetalles];

      // loking for Articulo in newDetalles
      let articuloExists = false;
      for (let i = 0; i < newDetalles.length; i += 1) {
        if (newDetalles[i].articuloId === articulo.id) {
          articuloExists = true;
        }
      }
      // if articulo exists, it's not created as newDetalle
      if (articuloExists === true) {
        if (articulo.isSelected === false) {
          newDetalles = newDetalles.filter(detalle => detalle.articulo.id !== articulo.id);
          return { ...prevState, newDetalles };
        }

        return false;
      }
      const newDetalle = {
        articulo,
        articuloId: articulo.id,
        precio: articulo.precio.replace(',', '.'),
        listaPrecioId: id,
        talleNombre: 'ST',
        addedRow: true,
      };

      newDetalles.push(newDetalle);

      return { ...prevState, newDetalles };
    });
  };

  onSaveUpdatedDetalle = async (articulo, newValue) => {
    // search for existing detalles and save updates
    const { updatedDetalles } = this.state;
    const newUpdatedDetalles = [...updatedDetalles];
    const newDetalle = {};
    const existingDetalle = newUpdatedDetalles.find(detalle => detalle.id === articulo.articuloId);
    if (existingDetalle === undefined) {
      newDetalle.id = articulo.articuloId;
      newDetalle.articuloId = articulo.articuloId;
      newDetalle.precio = articulo.precio;
      newUpdatedDetalles.push(newDetalle);
    } else {
      existingDetalle.precio = newValue;
    }
    this.setState({ updatedDetalles: newUpdatedDetalles });
  };

  onSelectArticulo = (row, isSelect) => {
    // toggle isSelected value from articulo
    if (isSelect === true) {
      row.isSelected = true;
      this.afterSaveNewDetalle(row);
    } else {
      row.isSelected = false;
      this.afterSaveNewDetalle(row);
    }
  };

  render() {
    const {
      id, detalles, entity, isAdding, isDataLoading,
    } = this.state;

    const columns = [
      {
        dataField: 'articulo.erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'articulo.descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'talleNombre',
        text: 'Talle',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
        formatter: cell => cell.replace('.', ','),
      },
      {
        dataField: 'isSelected',
        text: '',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        formatter: (cell, row) => {
          if (row.deletedRow) {
            return <FontAwesomeIcon icon={faUndo} onClick={() => this.onClickingUndo(row)} fixedWidth size="xs" />;
          }
          return <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.onClickingDelete(row)} fixedWidth size="xs" />;
        },
      },
    ];

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: false,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const rowClasses = (row) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
      return false;
    };

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Lista de Precio nueva' : `Lista de Precio #${id}`}</h1>
        {this.articulosListModal()}
        {this.deleteDetalleModal()}
        {this.uploadExcelModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}
        >
          <>
            <Row>
              <Col md={6}>
                <FormInputField id="tipo" label="Tipo" type="text" defaultValue={entity.tipo} />
              </Col>
              <Col md={6}>
                <FormInputField id="codigo" label="Código" type="text" defaultValue={entity.codigo} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField id="descripcion" label="Descripcion" type="text" defaultValue={entity.descripcion} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField id="eliminadoFlag" label="Inactivo" defaultChecked={entity.eliminadoFlag} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.afterSaveUpdatedDetalle,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={detalles || []}
                  keyField="articuloId"
                  isDataLoading={isDataLoading}
                  rowClasses={rowClasses}
                  addButton={() => this.showAddDetalleModal(MODAL_MANUAL)}
                  addSecondButton={() => this.showAddDetalleModal(MODAL_EXCEL)}
                  secondButtonText="Importar excel"
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ListaPrecioEdit);
