import React from 'react';
import { Route } from 'react-router-dom';
import ProspectList from './ProspectList';
import ProspectEdit from './ProspectEdit';

const routes = () => [
  <Route exact path="/clientes-prospectos/nuevo"  component={ProspectEdit} key="/clientes-prospectos/nuevo" />,
  <Route path="/prospectos/:id" component={ProspectEdit} key="/prospectos/:id" />,
  <Route path="/prospectos" exact component={ProspectList} key="/prospectos" />,
].map(route => route);

export default routes;
