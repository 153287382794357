import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import APIClient from '../../../services/APIClient';
import { withToastManager } from 'react-toast-notifications';
import { buildYearsOptions } from '../utils';
import { DataTable, FormSelectField } from '../../../components';
import { Graphic } from '../../../components/Reports/SaleReport/Graphic';

class SalesByProviderPerMonth extends Component {
    static propTypes = {
        toastManager: PropTypes.object.isRequired,
    };
    
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: '',
            details: [],
            isDataLoading: false,
            totalSize: 0
        };
    }

    handleFormChange = (e) => {
        const { value, name } = e.target;
        this.setState(prevState => ({
          ...prevState,
          [name]: value,
        }));
    };

    loadData = async () => {
        const { toastManager } = this.props;
        const { selectedYear } = this.state;
        try {
            this.setState(prevState => ({
                ...prevState,
                isDataLoading: true,
            }));
            const salesByProviderPerMonthReport = await APIClient.get(`/reportes/sales-by-provider?year=${selectedYear}`);
            console.log(salesByProviderPerMonthReport.data.data)
            this.setState(prevState => ({
                ...prevState,
                details: salesByProviderPerMonthReport.data.data,
                totalSize: salesByProviderPerMonthReport.data.data.length,
                isDataLoading: false,
            }));
        } catch (error) {
        toastManager.add('Ocurrió un error', {
            appearance: 'error',
            autoDismiss: true,
        });
        }
    }

    render() {
        const { selectedYear, details, isDataLoading, totalSize } = this.state;
        const columns = [
            {
              dataField: 'provider',
              text: 'Cliente',
              sort: true,
            },
            {
              dataField: 'january',
              text: 'Enero',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'february',
              text: 'Febrero',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'march',
              text: 'Marzo',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'april',
              text: 'Abril',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'may',
              text: 'Mayo',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'june',
              text: 'Junio',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'july',
              text: 'Julio',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'august',
              text: 'Agosto',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'september',
              text: 'Septiembre',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'october',
              text: 'Octubre',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'november',
              text: 'Noviembre',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
            {
              dataField: 'december',
              text: 'Diciembre',
              sort: true,
              align: 'right',
              headerAlign: 'right',
            },
        ];
        return (
        <div>
            <Card>
                <Card.Body>
                        <Card.Title>Reporte mensual de ventas por proveedor</Card.Title>
                        <Row>
                            <Col md={6}>
                                <FormSelectField
                                    id="selectedYear"
                                    label="Selecciona un periodo de comprarativa"
                                    value={selectedYear}
                                    onChange={this.handleFormChange}
                                    choices={buildYearsOptions()}
                                    choiceIdField="year"
                                    choiceLabelField="year"
                                />
                            </Col>
                            <Col md={1}>
                                <Button variant="primary" size="md" className="mt-4" onClick={this.loadData}>
                                Buscar
                                </Button>
                            </Col>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={details || []}
                            keyField="provider"
                            isDataLoading={isDataLoading}
                            totalSize={totalSize}
                        />
                        <Graphic dataProviders={details}/>
                </Card.Body>
            </Card>
        </div>
        )
    }
}

export default withToastManager(SalesByProviderPerMonth);