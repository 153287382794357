import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faRedo } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';

class UsuarioList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      usuarios: [],
      backupUsers: [],
    };

    this.loadUsuarios = this.loadUsuarios.bind(this);
  }

  componentDidMount() {
    this.loadUsuarios();
  }

  onResetPassword = async (event) => {
    const { toastManager } = this.props;
    const { value: usuarioId } = event.currentTarget;

    const confirm = window.confirm(
      `¿Está seguro que desea reiniciar la contraseña del Usuario #${usuarioId}?`,
    );

    if (confirm) {
      try {
        const resetResponse = await APIClient.post(`/usuarios/${usuarioId}/reset-password`);
        toastManager.add(
          <p>
            Se ha generado automáticamente la contraseña:
            <br />
            <strong>{resetResponse.data.data.newPassword}</strong>
          </p>,
          {
            appearance: 'success',
          },
        );
      } catch (err) {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
      }
    }
  };

  loadUsuarios() {
    const { toastManager } = this.props;

    APIClient.get('/usuarios')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          usuarios: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  hiddenUsersIactive = (e) => {
    const { checked } = e.target
    const { usuarios, backupUsers } = this.state;

    let usersToShow = [];
    let auxBackupUsers = [];
    
    if (checked) {
      auxBackupUsers = usuarios;
      usersToShow = usuarios.filter(e => e.isActive === true);
    } else {
      auxBackupUsers = [];
      usersToShow = backupUsers;
    }

    this.setState({
      usuarios: usersToShow,
      backupUsers: auxBackupUsers,
    });
  }

  render() {
    const { usuarios, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'username',
        text: 'Username / E-mail',
        sort: true,
        filterValue: (cell, row) => `${row.username} ${row.emailAddress}`,
        formatter: (cellContent, row) => (
          <>
            {cellContent}
            <br />
            <a href={`mailto:${row.emailAddress}`}>{row.emailAddress}</a>
          </>
        ),
      },
      {
        dataField: 'firstName',
        text: 'Nombre',
        sort: true,
        filterValue: (cell, row) => `${row.firstName} ${row.lastName}`,
        formatter: (cellContent, row) => <>{`${row.firstName} ${row.lastName}`}</>,
      },
      {
        dataField: 'perfil.cliente.razonSocial',
        text: 'Cliente',
        sort: true,
        formatter: (cellContent, row) => (
          <>{row.perfil && row.perfil.cliente && row.perfil.cliente.razonSocial}</>
        ),
      },
      {
        dataField: 'lastLogin',
        text: 'Último acceso',
        sort: true,
        formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'isActive',
        text: 'Activo',
        sort: true,
        align: 'center',
        formatter: (cellContent, row) => (
          <>{row.isActive && <FontAwesomeIcon icon={faCheck} fixedWidth />}</>
        ),
      },
      {
        dataField: 'isSuperAdmin',
        text: 'Admin',
        sort: true,
        align: 'center',
        formatter: (cellContent, row) => (
          <>{row.isSuperAdmin && <FontAwesomeIcon icon={faCheck} fixedWidth />}</>
        ),
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/usuarios/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
              <Button
                size="sm"
                variant="outline-primary"
                title="Resetear contraseña"
                value={row.id}
                onClick={this.onResetPassword}
              >
                <FontAwesomeIcon icon={faRedo} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Usuarios</h1>
        <FormCheckField
          label="Ocultar inactivos"
          onChange={this.hiddenUsersIactive}
        />
        <DataTable columns={columns} data={usuarios} isDataLoading={isDataLoading} keyField="id" addButton="/usuarios/nuevo" />
      </div>
    );
  }
}

export default withToastManager(UsuarioList);
