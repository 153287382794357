import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Row, Modal, Spinner } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Type } from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormSelectField, EntityEditForm, FormInputField } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';

class ClienteList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const pageNumbUrl = Utils.sanitizeQuery(['page'], props.location.search).page;
    const PageNum = parseInt(pageNumbUrl);
    const pageNumber = Utils.isPositiveInteger(PageNum) ? PageNum : 1;
    this.state = {
      clientes: [],
      isDataLoading: true,
      totalSize: 0,
      //FILTROS
      tipos: [],
      vendedores: [],
      estados: [],
      listasPrecio: [],
      puntosEntrega: [],
      selectedTipoIds: [],
      selectedVendedorIds: [],
      selectedEstadoIds: [],
      filterStringTipo: "",
      filterStringVendedor: "",
      filterStringEstado: "",
      showModal: false,
      modalWarning: false,
      rowEditModal: {},
      columnEditModal: {},
      pageNumber: pageNumber,
    };

    this.loadClientes = this.loadClientes.bind(this);

  }

  async componentDidMount() {
    this.getFilterData();
    await this.loadClientes();
    this.loadPuntosDeEntrega();
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting, } = queryParameters;
    const { filterStringTipo, filterStringVendedor, filterStringEstado } = this.state;
    try {
      const { direction, field } = sorting;
      //const filterQuery = `${filterStringTipo}&${filterStringVendedor}&${filterStringEstado}`;
      
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || 'id'}&sortDir=${direction || 'asc'}&excludeAssocFields=imagenes`;

      const clientesRes = await APIClient.get(`/clientes?filter[$estado.descripcion$][eq]=Prospecto&limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
     
      window.history.pushState({ page: pagination.page }, "", `?page=${pagination.page}`);
      this.setState({
        clientes: clientesRes.data.data,
        totalSize: clientesRes.data.meta.total,
        puntosEntrega: [],
        pageNumber: pagination.page,
      });
      await this.loadPuntosDeEntrega();
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  }

  async loadClientes() {
    const { toastManager } = this.props;
    const { pageNumber } = this.state;
    const offset = (pageNumber - 1) * 10;

    try {
      const clientesRes = await APIClient.get(`/clientes?&filter[$estado.descripcion$][eq]=Prospecto&limit=10&offset=${offset}&sortField=id&sortDir=asc`);
 

      this.setState({
        clientes: clientesRes.data.data,
        isDataLoading: false,
        totalSize: clientesRes.data.meta.total,
      });
      this.apiParams = 'sortField=id&sortDir=asc&excludeAssocFields=imagenes';
    } catch (err) {
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };

  async loadPuntosDeEntrega() {
    const { clientes } = this.state;
    let puntosEntregaRes, puntosEntrega = [];
    for (let i = 0; i < clientes.length; i++) {
      puntosEntregaRes = await APIClient.get(
        `/clientes/${clientes[i].id}/puntos-entrega`
      );
      puntosEntrega.push({ clienteId: clientes[i].id, pDEs: puntosEntregaRes.data.data });
    };
    this.setState({
      puntosEntrega: puntosEntrega,
      modalWarning: false,
    });
  };
  /////// %%%%%%% FILTROS %%%%%%% ///////

  getFilterData = async () => {
    const { toastManager } = this.props;
    try {
      // get tipos
      const tiposRes = await APIClient.get('/cliente-tipos');
      // get vendedores
      const vendedoresRes = await APIClient.get('/vendedores');
      // get estados solo Propspect
      const estadosRes = await APIClient.get(`/cliente-estados?filter[id][eq]=3`);
      // get listas de precio
      const listasPrecioRes = await APIClient.get('/listas-precio');
      this.setState({
        tipos: tiposRes.data.data,
        vendedores: vendedoresRes.data.data,
        estados: estadosRes.data.data,
        listasPrecio: listasPrecioRes.data.data,
      });
    } catch (err) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  createSelectAllButtons = entityName => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)}
      {' '}
(
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}
      >
        Seleccionar todos
      </button>
      )
    </p>
  );

  handleDataChange = (e) => {
    const {
      id, value, options, multiple,
    } = e.target;
    let filterName = '';
    let fieldName = '';
    switch (id) {
      case 'selectedTipoIds':
        fieldName = 'tipoId';
        filterName = 'filterStringTipo';
        break;
      case 'selectedVendedorIds':
        fieldName = 'vendedorId';
        filterName = 'filterStringVendedor';
        break;
      case 'selectedEstadoIds':
        fieldName = 'estadoId';
        filterName = 'filterStringEstado';
        break;
      default:
        break;
    };
    if (multiple) {
      const values = [...options].filter(opt => opt.selected).map(opt => opt.value);
      this.setState(prevState => ({
        ...prevState,
        [id]: values,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', values),
      }));
    } else {
      this.setState(async prevState => ({
        ...prevState,
        [id]: value,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', [value]),
      }));
    }
  };

  handleSelectAll = (entityName) => {
    this.setState((prevState) => {
      const {
        tipos, vendedores, estados,
      } = prevState;
      let {
        selectedTipoIds,
        selectedVendedorIds,
        selectedEstadoIds,
        filterStringTipo,
        filterStringVendedor,
        filterStringEstado,
      } = prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'tipos':
          selectedTipoIds = tipos.map(cla => cla.id);
          filterStringTipo = Utils.prepareQueryToFilter('tipoId', 'or', selectedTipoIds);
          break;
        case 'vendedores':
          selectedVendedorIds = vendedores.map(cli => cli.id);
          filterStringVendedor = Utils.prepareQueryToFilter('vendedorId', 'or', selectedVendedorIds);
          break;
        case 'estados':
          selectedEstadoIds = estados.map(col => col.id);
          filterStringEstado = Utils.prepareQueryToFilter('estadoId', 'or', selectedEstadoIds);
          break;
        default:
          break;
      }

      return {
        ...prevState,
        selectedTipoIds,
        selectedVendedorIds,
        selectedEstadoIds,
        filterStringTipo,
        filterStringVendedor,
        filterStringEstado,
      };
    });
  };

  /////////// %%%%%%%%%%% FIN_FILTROS %%%%%%%%%%%  ///////////

  //////////%%%%%%%%%% EDIT CELLS %%%%%%%%%%//////////
  updateRowField = async (row, column, newValue) => {
    const { toastManager } = this.props;
    const { clientes, rowEditModal } = this.state;
    row.id = rowEditModal.id || row.id;
    const fieldToUpdate = rowEditModal.id ? row : {id: row.id, [column.dataField]: newValue};
    try {
      //call api UPDATE
      await APIClient.patch(`/clientes/${row.id}`, fieldToUpdate);
      toastManager.add(`Cambio guardado`, {
        appearance: 'success',
      });
      // Update the component's state with the changes
      const clienteToUpdateState = await APIClient.get(`/clientes/${row.id}`);
      const arrayToUpdateState = clientes.map((cliente) => {
        if (cliente.id === row.id) { 
          return clienteToUpdateState.data.data 
        }
        return cliente;
      });

      this.setState(prevState => ({
        ...prevState,
        clientes: arrayToUpdateState,
        rowEditModal: {},
        columnEditModal: {},
        showModal: false,
      }));
    }
    catch (err) {
      console.error('Error al actualizar el campo: ', err);
      toastManager.add(`No se pudo guardar la información. Inténtelo nuevamente. ${err}`, {
        appearance: 'error',
      });
    };
  };

  editModal = (e, column, columnIndex, row, rowIndex) => {
    this.setState({ showModal: true });
  };
  onRetrieveEntity = () => { };
  handleClose = (modal) => {
    this.setState({ [modal]: false, });
  };
  //////////////%%%%%%%%%%%%%%%FIN EDIT_CELLS%%%%%%%%%%%%%%%//////////////

  render() {
    const {
      clientes,
      tipos,
      vendedores,
      estados,
      listasPrecio,
      puntosEntrega,
      isDataLoading,
      totalSize,
      selectedTipoIds,
      selectedVendedorIds,
      selectedEstadoIds,
      showModal,
      modalWarning,
      rowEditModal,
      columnEditModal,
      pageNumber,
    } = this.state;

    const events = {
      onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
        e.target.style.cursor = `pointer`;
        // e.target.classList.add('bg-primary');
        // e.target.style.color = 'white';
      },
      onMouseLeave: (e, column, columnIndex, row, rowIndex) => {
        // e.target.classList.remove('bg-primary');
        // e.target.style.color = 'black';
      },
    };
    const showEditModal = {
      onDoubleClick: (e, column, columnIndex, row, rowIndex) => {
        this.setState(prevState => ({
          ...prevState,
          rowEditModal: row,
          columnEditModal: column,
        }));
        this.editModal(e, column, columnIndex, row, rowIndex);
        return;
      },
      onMouseEnter: UIUtils.bgBlueOnMouseEnter.onMouseEnter,
      onMouseLeave: UIUtils.bgBlueOnMouseEnter.onMouseLeave,

    };

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'codigoInterno',
        text: 'Código',
        sort: true,
        editable: (cell, row, rowIndex, colIndex) => {
          return false;
        },
      },
      {
        dataField: 'razonSocial',
        text: 'Razón Social',
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            {row.razonSocial}
            <br />
            <small title="Nombre de Fantasía">{row.nombreFantasia}</small>
          </div>
        ),
        events: UIUtils.bgBlueOnMouseEnter,
      },
      {
        dataField: 'documentoNumero',
        text: 'CUIT',
        events: events,
        sort: true,
      },
      {
        dataField: 'estadoId',
        text: 'Estado',
        sort: true,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const estadoFound = estados.find((estado) => cellContent === estado.id);

          const estado = estadoFound ? estadoFound.descripcion : row.estado.descripcion;
          return UIUtils.getClienteEstadoBadge(estado);
        },
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            return estados.map((estado) => {
              return { value: estado.id, label: estado.descripcion }
            })
          },
        },
      },
      {
        dataField: 'tipoId',
        text: 'Tipo',
        sort: true,
        events: events,
        csvFormatter: (cellContent) => {
          if (cellContent === null) {
            return '';
          }
        },
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const tipoFound = tipos.find((tipo) => cellContent === tipo.id);
          const tipo = tipoFound ? tipoFound.descripcion : row.tipo.descripcion;
          return tipo;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            return tipos.map((tipo) => {
              return { value: tipo.id, label: tipo.descripcion }
            })
          },
        },
      },
      {
        dataField: 'vendedorId',
        text: 'Vendedor',
        sort: true,
        events: events,
        hidden: true,
        csvFormatter: (cellContent) => {
          if (cellContent === null) {
            return '';
          }
        },
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const vendedorFound = vendedores.find((vendedor) => cellContent === vendedor.id);
          const vendedor = vendedorFound ? vendedorFound.nombre : row.vendedor.nombre;
          return vendedor;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            return vendedores.map((vendedor) => {
              return { value: vendedor.id, label: vendedor.nombre }
            })
          },
        },
      },
      {
        dataField: 'listaPrecioId',
        text: 'Lista de precio',
        sort: true,
        events: events,
        csvFormatter: (cellContent) => {
          if (cellContent === null) {
            return '';
          }
        },
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const expresoFound = listasPrecio.find((lista) => cellContent === lista.id);
          const listaPrecio = expresoFound ? expresoFound.descripcion : row.listaPrecio.descripcion;
          return listaPrecio;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            return listasPrecio.map((lista) => {
              return { value: lista.id, label: lista.descripcion }
            })
          },
        },
      },
      {
        dataField: 'telefonoComercial',
        text: 'Contacto comercial',
        sort: true,
        events: showEditModal,
        editable: (cell, row, rowIndex, colIndex) => {
          return false;
        },
        formatter: (cellContent, row) => {
          if (row.contactoComercial || row.emailComercial || row.telefonoComercial) {
            return (
              <div>
                <span >{row.contactoComercial}</span>
                <br />
                <a href="mailto:{row.email}">{row.emailComercial}</a>
                <br />
                <a href="tel:{row.telefono}">{row.telefonoComercial}</a>
              </div>
            );
          };
          return '';
        },
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'contactoCobranzas',
        text: 'Contacto cobranza',
        sort: true,
        events: showEditModal,
        editable: (cell, row, rowIndex, colIndex) => {
          return false;
        },
        formatter: (cellContent, row) => {
          if (row.contactoCobranzas || row.emailCobranzas || row.telefonoCobranzas) {
            return (
              <div>
                <span >{row.contactoCobranzas}</span>
                <br />
                <a href="mailto:{row.email}">{row.emailCobranzas}</a>
                <br />
                <a href="tel:{row.telefono}">{row.telefonoCobranzas}</a>
              </div>
            );
          };
          return '';
        },
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'telefono',
        text: 'Teléfono',
        sort: false,
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'celular',
        text: 'Celular',
        sort: false,
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'domicilio',
        isDummyField: true,
        text: 'Domicilio',
        hidden: true,
        csvFormatter: (cellContent, row) => `${row.calle ? row.calle : 'S/C'} ${row.numero ? row.numero : 'S/N'}${row.piso ? ` ${row.piso}` : ''}${row.depto ? ` ${row.depto}` : ''}, ${row.localidad ? `${row.localidad}, ` : ''}${row.provincia ? row.provincia.descripcion : ''}${row.codigoPostal ? `, ${row.codigoPostal}` : ''}`,
      },
      {
        dataField: 'coeficienteFacturacion',
        text: 'Coeficiente Facturación',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'diasEntrega',
        text: 'Dias Entrega',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'eliminadoFlag',
        text: 'Eliminado Flag',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'fax',
        text: 'Fax',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'latitud',
        text: 'Latitud',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'longitud',
        text: 'Longitud',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'puntoEntregaId',
        text: 'Punto de entrega',
        sort: true,
        events: {
          onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
            e.target.style.cursor = `pointer`;
          },
        },
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
        },
        formatter: (cellContent, row) => {
          if (!cellContent) {
            return '';
          }
          const rowPDE = puntosEntrega.find(pDE => pDE.clienteId === row.id);
          if (!rowPDE) { return <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" /> };
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const rowPDEFound = rowPDE.pDEs.find((pDE) => cellContent === pDE.id);
          return rowPDEFound ? <div>{rowPDEFound.descripcion}  <br /><small>{rowPDEFound.expreso ? rowPDEFound.expreso.descripcion : '(Sin expreso)'}</small></div> : <div>{row.puntoEntrega.descripcion} <br /><small>{row.puntoEntrega.expreso ? row.puntoEntrega.expreso.descripcion : '(Sin expreso)'}</small></div>;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            const rowPDE = puntosEntrega.find(pDE => pDE.clienteId === row.id);
            if (puntosEntrega.length === 0 || rowPDE === undefined) {
              return this.setState({ modalWarning: true });
            };
            return rowPDE.pDEs.map((pDE) => {
              return { value: pDE.id, label: pDE.descripcion }
            })
          },
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/prospectos/${row.id}?page=${pageNumber}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        {/* WARNING MODAL */}
        <Modal size="lg" show={modalWarning} onHide={() => this.handleClose('modalWarning')}>
          <Modal.Header closeButton>
            <h4 className="ml-3">Puntos de entrega cargándose</h4>
            <Spinner animation="grow" />
          </Modal.Header>
          <Modal.Body>
          </Modal.Body>
        </Modal>

        {/* EDIT MODAL */}
        <Modal size="lg" show={showModal} onHide={() => this.handleClose('showModal')}>
          <Modal.Header closeButton>
            <h4>{rowEditModal.razonSocial} - {columnEditModal.text} </h4>
          </Modal.Header>
          <Modal.Body>
            <EntityEditForm
              // onLoadForm={this.onLoadForm}
              onRetrieveEntity={this.onRetrieveEntity}
              onSaveEntity={this.updateRowField}
            >
              {
                columnEditModal.text === 'Contacto comercial' ?
                  (
                    <React.Fragment>
                      <Row>
                        <Col md={6}>
                          <FormInputField id="contactoComercial" label="Nombre" type="text" defaultValue={rowEditModal.contactoComercial} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormInputField id="emailComercial" label="Email" type="email" defaultValue={rowEditModal.emailComercial} />
                        </Col>
                        <Col md={6}>
                          <FormInputField id="telefonoComercial" label="Teléfono" type="text" defaultValue={rowEditModal.telefonoComercial} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  ) :
                  (
                    <React.Fragment>
                      <Row>
                        <Col md={6}>
                          <FormInputField id="contactoCobranzas" label="Nombre" type="text" defaultValue={rowEditModal.contactoCobranzas} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormInputField id="emailCobranzas" label="Email" type="email" defaultValue={rowEditModal.emailCobranzas} />
                        </Col>
                        <Col md={6}>
                          <FormInputField id="telefonoCobranzas" label="Teléfono" type="text" defaultValue={rowEditModal.telefonoCobranzas} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
              }
            </EntityEditForm>
          </Modal.Body>
          <Modal.Footer>

            {/* <Button variant="primary" onClick={this.handleClose}>
        Save Changes
      </Button> */}
          </Modal.Footer>
        </Modal>
        {/* FIN EDIT MODAL */}



        <h1 className="page-title">Clientes Prospectos</h1>
     
        <DataTable
          remote={{
            filter: true, pagination: true, sort: true, cellEdit: false,
          }}
          totalSize={totalSize}
          columns={columns}
          data={clientes}
          onTableUpdate={this.onTableUpdate}
          isDataLoading={isDataLoading}
          keyField="id"
          addButton="/clientes-prospectos/nuevo"
          exportURL={`/clientes/export.csv?${this.apiParams}`}
          defaultSorted={[{ dataField: 'codigo', order: 'desc', }]}
          updateRowField={this.updateRowField}
          pageNumber={pageNumber}
        />
      </div>
    );
  }
}

export default withToastManager(ClienteList);
