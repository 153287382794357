import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { EntityEditForm, FormCheckField, DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { colsDetalles } from './colsDataExcel';
import { Articulo, ColumnTable, ListaPrecio, Props } from '../Interfaces/interfaces';
import { ArticuloCombo, ComboDetalleEntity, ComboEditState, EntityToSave } from './comboInterface';
import FormInput from '../../components/componentsTs/FormInput';
import FormSelect from '../../components/componentsTs/FormSelect';

class ComboEdit extends React.Component<Props, ComboEditState> {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      articulosList: [],
      entity: {},
      id,
      isAdding: typeof id === 'undefined',
      detalles: [],
      isDataLoading: typeof id !== 'undefined',
      newDetalles: [],
      selectedDetalleRowDel: {},
      selectedArticulos: [],
      showAddModal: false,
      showDeleteModal: false,
      updatedDetalles: [],
      listaPrecio: [],
      listaPrecioDescripcion: '',
      listaPrecioId: undefined,
      nombreCombo: '',
      isActive: undefined,
      disable: true,
      cols: colsDetalles,
      comboCodigoEan: '',
    };
  }

  afterSaveNewDetalle = (row: ArticuloCombo) => {
    this.onSaveNewDetalle(row);
  };

  afterSaveUpdatedDetalle = () => {
    this.onSaveUpdatedDetalle();
  };

  //Modal de articulos
  articulosListModal = () => {
    const { articulosList } = this.state;

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
        formatter: (cellContent: any, row: Articulo) => (
          <div>
            {row.erpCodigo}
            <br />
            <small title="EAN">{row.codigoEan13}</small>
          </div>
        ),
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
        editable: false,
      },

      {
        dataField: 'linea.descripcion',
        text: 'Línea',
        sort: true,
        editable: false,
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      // clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectArticulo,
    };

    return (
      <Modal size="xl" show={this.state.showAddModal} onHide={this.handleAddModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Artículos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            isDataLoading={this.state.isDataLoading}
            selectRow={selectRowProps}
            columns={columns}
            data={articulosList || []}
            keyField="id"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.saveNewDetalles}>
            Listo
          </Button>
          <Button variant="secondary" onClick={this.handleAddModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // Close modals Articulos
  handleAddModalClose = () => {
    this.setState({ showAddModal: false, newDetalles: [] });
  };

  //Modal Close Delete
  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false, selectedDetalleRowDel: {} });
  };

  /*  Prepara el objeto que creara o actualizara el combo 5 */
  prepareToSave = (entityToSave: EntityToSave) => {
    const { updatedDetalles, listaPrecioId, nombreCombo, entity, isActive, comboCodigoEan } = this.state;
    entityToSave.detalles = updatedDetalles;
    entityToSave.listaPrecioId = listaPrecioId;
    //en update toma desde entity si no hay modificaciones
    //en new  toma toma desde el state porque entity es {}
    entityToSave.ean = comboCodigoEan;
    entityToSave.descripcion = nombreCombo || entity.descripcion;
    entityToSave.isActive = isActive || entity.isActive;
    return entityToSave;
  };

  /*  Guarda nuevo Modificacion de detalle o detalles nuevos 4 */
  saveNewDetalles = () => {
    this.setState((prevState: ComboEditState) => ({
      detalles: [...prevState.newDetalles, ...prevState.detalles],
      newDetalles: [],
      updatedDetalles: [...prevState.newDetalles, ...prevState.detalles],
      showAddModal: false,
    }));
  };

  loadData = async () => {
    const detalleArticuloIds = this.state.detalles.map((detalle) => detalle.articuloId);
    const articulosListRes = await APIClient.get(`/articulos?filter[id][notIn]=${detalleArticuloIds}&filter[eliminadoFlag][eq]=0`);
    const articulosList = articulosListRes.data.data;
    return articulosList;
  };

  // Modal de Articulos
  showAddDetalleModal = async () => {
    // get articulos and add precio and isSelected to all objects in array. then update state
    const detalleArticuloIds = this.state.detalles.map((detalle) => detalle.articuloId);
    const articulosListRes = await APIClient.get(`/articulos?filter[id][notIn]=${detalleArticuloIds}&filter[eliminadoFlag][eq]=0`);
    const articulosList = articulosListRes.data.data;
    articulosList.forEach((articulo: ArticuloCombo) => {
      articulo.isSelected = false;
    });
    this.setState({
      showAddModal: true,
      isDataLoading: false,
      articulosList,
    });
  };

  // Abre el modal y guarda la row a eliminar
  onClickingDelete = (row: ArticuloCombo) => {
    this.setState({ showDeleteModal: true, selectedDetalleRowDel: row });
  };

  //borra la row/registro/articuloDetallCombo de state y de db
  onConfirmDelete = async () => {
    const { isAdding, id, detalles, selectedDetalleRowDel } = this.state;
    const { toastManager } = this.props;
    //id del articulo a eliminar
    const isDeletedDetalleNew: ArticuloCombo | undefined = detalles.find(
      (detalle) => detalle.articuloId === selectedDetalleRowDel.articuloId,
    );
    const articuloId = isDeletedDetalleNew?.articuloId;
    //si es nuevo solo lo elimina del state , si existia lo elimina de la db
    if (isAdding === false && !selectedDetalleRowDel.addedRow) {
      //id del detalle a eliminar ( ingresa id del articulo y id del combo )
      const detalleId = await APIClient.get(`/combo/${id}/articulo/${articuloId}`);
      //elimina el regitro
      await APIClient.delete(`/combo/articulo/${detalleId.data.data[0][0].id}`);
    }
    //lo eliminia del state
    const rowDeleted = detalles.filter((elem) => elem.articuloId !== articuloId);
    this.setState({
      detalles: rowDeleted,
      updatedDetalles: rowDeleted,
      showDeleteModal: false,
    });
    toastManager.add(`Articulo ${articuloId} eliminado del Combo `, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  /*  Trae los combos guardados y sus detalles para modificar 7 */
  onRetrieveEntity = async () => {
    const { id } = this.state;
    try {
      // get listas precio
      const comboRes = await APIClient.get(`/combo/${id}`);
      const combo = comboRes.data.data;
      combo.detalles.forEach((detalle: ArticuloCombo) => (detalle.isSelected = false));
      const detalles = [...combo.detalles];
      this.setState({
        detalles,
        entity: combo,
        comboCodigoEan: combo.ean,
        isDataLoading: false,
        isAdding: false,
        nombreCombo: combo.descripcion,
      });
      return combo;
    } catch (error) {
      console.error(error);
      this.setState({ isDataLoading: false });
    }
  };

  /* Valida y Guarda el combo en DB 6 */
  onSaveEntity = async (entityToSave: EntityToSave) => {
    const { id, isAdding, listaPrecio, detalles, entity, isActive, comboCodigoEan, articulosList } = this.state;
    const { history, toastManager } = this.props;
    let saveResponse = null;
    let message = '';
    const unitValueInvalid = entityToSave.detalles.filter((elem: any) => isNaN(elem.units));
    const unitsNumberNegativo = entityToSave.detalles.filter((elem: any) => elem.units <= 0);
    const unitsDecimal = entityToSave.detalles.filter((elem: any) => elem.units % 1 !== 0);
    const toastConfigs: any = {};

    //new "articulo" for this combo in articulo table
    let articuloCombo: Partial<Articulo> = {};
    articuloCombo.descripcion = entityToSave.descripcion;
    articuloCombo.codigoEan13 = entityToSave.ean;
    articuloCombo.isCombo = true;

    let errorCodDuplicated = false;
    if (isAdding === true) {
      for (let i = 0; i < articulosList.length; i++) {
        if (articulosList[i].codigoEan13 === comboCodigoEan) {
          errorCodDuplicated = true;
        }
      }
    }

    //search articulo id for update
    let artId = null;
    if (isAdding === false) {
      const articulos = await this.loadData();
      for (let i = 0; i < articulos.length; i++) {
        if (articulos[i].codigoEan13 === entity.ean) {
          artId = articulos[i].id;
        }
      }
    }

    try {
      if (isAdding === true && entityToSave.listaPrecioId === undefined) {
        toastConfigs.message = `Combo sin lista de precio asignada`;
        toastConfigs.appearance = 'error';
      } else if (entityToSave.detalles.length === 0 && detalles.length === 0) {
        toastConfigs.message = `Combo sin articulos asignados`;
        toastConfigs.appearance = 'error';
      } else if (unitsNumberNegativo.length > 0 || unitValueInvalid.length > 0 || unitsDecimal.length > 0) {
        toastConfigs.message = `Combo sin articulos asignados`;
        toastConfigs.appearance = 'error';
      } else if (isAdding === true && errorCodDuplicated === true) {
        toastConfigs.message = `El código EAN ${entityToSave.ean} ya fue asignado`;
        toastConfigs.appearance = 'error';
      }

      //Si pasa todas las validaciones
      else {
        //nuevo registro
        if (isAdding === true) {
          saveResponse = await APIClient.post('/articulos', articuloCombo);
          if (saveResponse != null) {
            saveResponse = null;
            entityToSave.detalles.forEach((detalle) => delete detalle.articulo);
            isActive === undefined ? (entityToSave.isActive = false) : (entityToSave.isActive = isActive);
            saveResponse = await APIClient.post('/combo', entityToSave);
            history.push('/combo');
            toastConfigs.message = `Combo ${saveResponse.data.data.id} guardado con éxito`;
            toastConfigs.appearance = 'success';
          }
        }
        //edicion de registro
        else {
          entityToSave.detalles.forEach((detalle) => delete detalle.articulo);
          //si no hay cambio en detalles deja deja los antiguos
          if (entityToSave.detalles.length === 0) {
            entityToSave.detalles = detalles;
          }
          //detecta si cambio de estado el combo
          isActive === undefined ? (entityToSave.isActive = entity.isActive) : (entityToSave.isActive = isActive);
          //si no hay cambio en lista de precio deja la antigua
          if (entityToSave.listaPrecioId === undefined) {
            const listaSinModificar: ListaPrecio | undefined = listaPrecio.find((elem) => elem.id === entity.listaPrecioId);
            entityToSave.detalles.forEach((detalle) => (detalle.descripcion = listaSinModificar?.descripcion));
          }

          if (artId != null) {
            //update articulo
            saveResponse = await APIClient.patch(`/articulos/${artId}`, articuloCombo);
            if (saveResponse != null) {
              saveResponse = null;
              saveResponse = await APIClient.patch(`/combo/${id}`, entityToSave);
              history.push('/combo');
              toastConfigs.message = `Combo ${saveResponse.data.data.id} Actualizado con éxito`;
              toastConfigs.appearance = 'success';
            }
          }
        }
      }
      toastManager.add(toastConfigs.message, {
        appearance: toastConfigs.appearance,
        autoDismiss: true,
      });
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  /*  Guarda nuevo detalle  2 */
  onSaveNewDetalle = async (articulo: ArticuloCombo) => {
    const { listaPrecioDescripcion, newDetalles } = this.state;
    //si esta seleccionado lo agregar y setea nuevas propiedades al guardar
    if (articulo.isSelected === true) {
      this.setState((prevState:ComboEditState) => {
        const newDetalles: Array<ArticuloCombo> = [...prevState.newDetalles];
        const newDetalle = {
          articulo,
          articuloId: articulo.id,
          descripcion: listaPrecioDescripcion,
          units: 0,
          comboId: 0,
          addedRow: true,
        };
        newDetalles.push(newDetalle);
        return { ...prevState, newDetalles };
      });
    } else if (articulo.isSelected === false) {
      this.setState({
        newDetalles: newDetalles.filter((elem) => elem.articuloId !== articulo.id),
      });
    }
  };

  /*  Guarda nuevo Modificacion de detalle  3 */
  onSaveUpdatedDetalle = async () => {
    const { detalles, id, listaPrecioDescripcion, listaPrecioId } = this.state;
    //en cada modificacion , agrega o actualiza propiedades necesarias para entityToSave
    detalles.forEach((detalles: any) => (detalles.comboId = id));
    detalles.forEach((detalles: any) => (detalles.descripcion = listaPrecioDescripcion));
    detalles.forEach((detalles: any) => (detalles.listaPrecioId = listaPrecioId));
    this.setState({ updatedDetalles: detalles });
  };

  /* Seleccionadores 1 */

  onSelectArticulo = (row: ComboDetalleEntity, isSelect: boolean) => {
    // toggle isSelected value from articulo
    const articulosList: Array<ArticuloCombo> = [...this.state.articulosList];
    const articulo: ArticuloCombo | undefined = articulosList.find((articulo) => articulo.id === row.id);
    if (articulo?.isSelected !== undefined) {
      articulo.isSelected = isSelect;
    }
    this.setState({ articulosList });
    this.afterSaveNewDetalle(row);
  };

  // Delete articulos modals
  deleteDetalleModal = () => (
    <Modal size="lg" show={this.state.showDeleteModal} onHide={this.handleDeleteModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar este detalle?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.onConfirmDelete}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={this.handleDeleteModalClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  onSelectListaPrecio = (e: any) => {
    const { listaPrecio, detalles } = this.state;
    const { value } = e.target;

    const listaSelect = listaPrecio.filter((e) => e.id.toString() === value);
    detalles.forEach((detalles) => (detalles.descripcion = listaSelect[0]?.descripcion));

    if (value === '') {
      this.setState({
        listaPrecioDescripcion: '',
        listaPrecioId: undefined,
      });
    } else {
      this.setState({
        listaPrecioDescripcion: listaSelect[0].descripcion,
        listaPrecioId: value,
      });
    }
  };
  handleChangeName = (e: any) => {
    const { name, value } = e.target;

    if (name === 'comboCodigoEan') {
      this.setState({
        comboCodigoEan: value,
      });
    }
    if (name === 'descripcion') {
      this.setState({
        nombreCombo: value,
      });
    }
  };
  onChangeCheck = (e: any) => {
    const { checked } = e.target;
    this.setState({
      isActive: checked,
    });
  };

  //carga lista de precios del select
  onLoadForm = async () => {
    const listaPrecio = await APIClient.get(`/listas-precio`);
    this.setState({
      listaPrecio: listaPrecio.data.data,
      isDataLoading: false,
    });
  };

  //get excel
  getExportData = async () => {
    const { id, isAdding } = this.state;
    const { toastManager } = this.props;
    try {
      if (isAdding) {
        toastManager.add(`Combo sin detalles guardados`, {
          appearance: 'error',
          autoDismiss: true,
        });
        return [];
      } else {
        const queryParams = `combo=detalles&id=${id}`;
        const apiResponse = await APIClient.get(`/combo/combo-scv?${queryParams}`);
        return apiResponse.data.data[0];
      }
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  render() {
    const { id, detalles, entity, isAdding, isDataLoading, listaPrecio, cols, nombreCombo, comboCodigoEan } = this.state;

    const columns: Array<ColumnTable> = [
      {
        dataField: 'articulo.erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },

      {
        dataField: 'articulo.descripcion',
        text: 'Descripcion',
        sort: true,
        editable: false,
      },
      {
        dataField: 'units',
        text: 'Cantidad',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
      },
      {
        dataField: 'isSelected',
        text: '',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        formatter: (cell: any, row: ArticuloCombo) => {
          return <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.onClickingDelete(row)} fixedWidth size="xs" />;
        },
      },
    ];

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: false,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const rowClasses = (row: ArticuloCombo) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
    };

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Combo Nuevo' : `Combo ${nombreCombo}`}</h1>
        {this.articulosListModal()}
        {this.deleteDetalleModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}>
          <>
            <Row>
              <Col md={6}>
                <FormInput
                  id="descripcion"
                  label="Nombre"
                  type="text"
                  required
                  placeholder="Nombre del combo"
                  defaultValue={entity.descripcion}
                  onChange={this.handleChangeName}
                />
              </Col>
              <Col md={6}>
                <FormSelect
                  id="listaPrecioId"
                  label="Lista de Precios"
                  choices={listaPrecio}
                  onChange={this.onSelectListaPrecio}
                  defaultValue={entity.listaPrecioId}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                />
              </Col>
              <Col md={6}>
                <FormInput
                  id="comboCodigoEan"
                  label="Código EAN (buscar este combo en artículos por el ean ingresado)"
                  type="text"
                  required
                  placeholder="Código EAN"
                  value={comboCodigoEan}
                  onChange={this.handleChangeName}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField id="isActive" label="Activo" onChange={this.onChangeCheck} defaultChecked={entity.isActive} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.afterSaveUpdatedDetalle,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={detalles || []}
                  keyField="articuloId"
                  isDataLoading={isDataLoading}
                  rowClasses={rowClasses}
                  addButton={this.showAddDetalleModal}
                  /* Excel */
                  cols={cols}
                  getExportData={this.getExportData}
                  exportFileName={`Detalle de combo #${id}`}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ComboEdit);
