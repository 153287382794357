import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';

class ExpresoList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      expresos: [],
    };

    this.loadExpresos = this.loadExpresos.bind(this);
  }

  componentDidMount() {
    this.loadExpresos();
  }

  loadExpresos() {
    const { toastManager } = this.props;

    APIClient.get('/expresos')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          expresos: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { isDataLoading, expresos } = this.state;

    const columns = [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'calle',
        text: 'Dirección',
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            {`${row.calle} ${row.numero}`}
            <br />
            {`${row.localidad} ${row.codigoPostal === null ? '' : `(${row.codigoPostal})`}`}
          </div>
        ),
      },
      {
        dataField: 'telefono',
        text: 'Teléfono',
        sort: true,
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
      },
      {
        dataField: 'provinciaCodigo',
        text: 'Cód. Provincia',
        sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/expresos/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Expresos</h1>

        <DataTable
          columns={columns}
          data={expresos}
          isDataLoading={isDataLoading}
          keyField="id"
          addButton="/expresos/nuevo"
        />
      </div>
    );
  }
}

export default withToastManager(ExpresoList);
