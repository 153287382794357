const modosCalculo = [
  { id: 'A', nombre: 'Acumulable sobre último' },
  { id: 'B', nombre: 'Aplica a valor base' },
];

const modosCalculoMap = {};
modosCalculo.forEach((modoCalculo) => {
  modosCalculoMap[modoCalculo.id] = modoCalculo.nombre;
});

export default {
  modosCalculo,
  modosCalculoMap,
};
