import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faCheck, faAngleRight, faStop, faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import Moment from 'react-moment';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import PrecioService from '../../services/Precio';

class PrecioModificadorList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      precioModificadores: [],
      isDataLoading: true,
    };

    this.loadPrecioModificadores = this.loadPrecioModificadores.bind(this);
  }

  componentDidMount() {
    this.loadPrecioModificadores();
  }

  loadPrecioModificadores() {
    const { toastManager } = this.props;

    APIClient.get('/modificadores-precio')
      .then((res) => {
        this.setState({
          precioModificadores: res.data.data,
          isDataLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({ isDataLoading: false });
      });
  }

  render() {
    const { precioModificadores, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'orden',
        text: 'Orden',
        sort: true,
      },
      {
        dataField: 'nombre',
        text: 'Nombre',
        sort: true,
      },
      {
        dataField: 'vigenciaDesde',
        text: 'Vigencia',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {row.vigente ? (
              <FontAwesomeIcon icon={faPlay} color="green" className="mr-2" />
            ) : (
              <FontAwesomeIcon icon={faStop} color="red" className="mr-2" />
            )}
            <span>
              {row.vigenciaDesde === null ? (
                '-'
              ) : (
                <Moment format="DD/MM/YYYY">{row.vigenciaDesde}</Moment>
              )}
            </span>
            <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
            <span>
              {row.vigenciaHasta === null ? (
                '-'
              ) : (
                <Moment format="DD/MM/YYYY">{row.vigenciaHasta}</Moment>
              )}
            </span>
          </>
        ),
      },
      {
        dataField: 'modoCalculo',
        text: 'Cálculo',
        sort: true,
        formatter: (cellContent, row) => <>{PrecioService.modosCalculoMap[cellContent]}</>,
      },
      {
        dataField: 'stopEjecucion',
        text: 'Frena ejec.',
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { textAlign: 'center' },
        formatter: (cellContent, row) => <>{cellContent && <FontAwesomeIcon icon={faCheck} />}</>,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={!row.isBulk ? `/modificadores-precio/${row.id}` : `/modificadores-precio-masivo/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];
    return (
      <div>
        <h1 className="page-title">Modificadores de Precio</h1>
        <DataTable
          isDataLoading={isDataLoading}
          columns={columns}
          data={precioModificadores}
          keyField="id"
          addButton="/modificadores-precio/nuevo"
          addSecondButton="/modificadores-precio-masivo"
          secondButtonText='Descuento masivo'
        />
      </div>
    );
  }
}

export default withToastManager(PrecioModificadorList);
