import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
//Librerias externas
import { Button, Card, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import { cols } from '../../views/ReporteDiario/colsDataExcel';
//Componentes internos
import apiClient from '../../services/APIClient';
import FormInputField from '../FormInputField';
import FormSelectField from '../FormSelectField';
import Loading from '../Loading';
import { BarChart } from './BarChart';
import DataTableExportCSVButton from '../DataTableExportCSVButton';
class Reporte extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fromDay: '',
      toDay: '',
      dataSelect: [],
      idSelect: [],
      valorEjeY: [''],
      valorEjeX: [''],
      entidad: this.props.entidad,
      titulo: [''],
      //excel
      cols: cols,
      titulo: '',
      disabled: true,
    };
    this.loadCargaSelect = this.loadCargaSelect.bind(this);
  }
  componentDidMount() {
    this.loadCargaSelect();
  }
  //Load de carga selec
  loadCargaSelect = async () => {
    const { entidad } = this.state;
    const url = `${
      entidad === 'General'
        ? 'entregas?&excludeAssocFields=detalles'
        : entidad === 'Clientes'
        ? 'clientes?&sortField=razonSocial&sortDir=asc'
        : entidad.toLowerCase()
    }`;
    const dataSelectRes = await apiClient.get(`/${url}`);
    this.setState({
      ...this.state,
      dataSelect: dataSelectRes.data.data,
      isLoading: false,
    });
  };
  //Captura Fecha
  handleDataChange = (e) => {
    const { id, value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  //Captura Select de busqueda
  handleSubmit = (e) => {
    const { value, options } = e.target;
    const opcionSelect = [...options].filter((opt) => opt.selected).map((opt) => opt.textContent);
    {
      opcionSelect == '(Seleccione)'
        ? this.setState({
            idSelect: value,
            titulo: opcionSelect,
            disabled: true,
          })
        : this.setState({
            idSelect: value,
            titulo: opcionSelect,
            disabled: false,
          });
    }
  };

  //get excel
  getExportData = async () => {
    const { toastManager } = this.props;
    const { fromDay, toDay, entidad, idSelect } = this.state;
    try {
      const fromDate = moment(fromDay).format('YYYY/MM/DD HH:mm');
      const toDate = moment(toDay).format('YYYY/MM/DD HH:mm');
      const dateFilters = `fromDate=${fromDate}&toDate=${toDate}`;
      const searchImputValueID = `id=${idSelect}`;
      const entity = `entity=${entidad}`;
      const getDataScv = `getDataScv=${true}`;
      const queryParams = `${dateFilters}&${searchImputValueID}&${entity}&${getDataScv}`;
      const apiResponse = await apiClient.get(`/reportes-diarios/sell-out?${queryParams}`);
      return apiResponse.data.data;
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };
  
  //Envio de datos por GET
  handleDataChangaSubmit = async () => {
    const { fromDay, toDay, entidad, idSelect } = this.state;
    const { toastManager } = this.props;
    if (fromDay > toDay) {
      const toastMessage = `La fecha final no puede ser menor a la fecha de inicio`;
      toastManager.add(toastMessage, {
        appearance: 'warning',
        autoDismiss: false,
      });
    } else if (entidad !== 'General' && idSelect == '') {
      const toastMessage = `Debes seleccionar ${entidad}`;
      toastManager.add(toastMessage, {
        appearance: 'warning',
        autoDismiss: false,
      });
    } else if (fromDay === '' || toDay === '') {
      const toastMessage = `Ingresa un rango de fecha `;
      toastManager.add(toastMessage, {
        appearance: 'warning',
        autoDismiss: false,
      });
    } else {
      // parse month values into full dates.
      const fromDate = moment(fromDay).format('YYYY/MM/DD HH:mm');
      const toDate = moment(toDay).format('YYYY/MM/DD HH:mm');
      const dateFilters = `fromDate=${fromDate}&toDate=${toDate}`;
      const searchImputValueID = `id=${idSelect}`;
      const entity = `entity=${entidad}`;
      const queryParams = `${dateFilters}&${searchImputValueID}&${entity}`;
      const dataSelectRes = await apiClient.get(`/reportes-diarios?${queryParams}`);

      const noFacturado = dataSelectRes.data.data.arrayDataChartX.every((elem) => elem === '0.00');
      if (noFacturado === true) {
        const toastMessage = `al parecer no hay entregas facturadas para este reporte ${entidad} en el rango de fecha especificado`;
        toastManager.add(toastMessage, {
          appearance: 'warning',
          autoDismiss: false,
        });
      }

      this.setState({
        valorEjeY: dataSelectRes.data.data.arrayDataChartY,
        valorEjeX: dataSelectRes.data.data.arrayDataChartX,
      });
    }
  };
  shouldComponentUpdate(prevProps, prevtState) {
    if (prevtState.toDay != this.state.toDay || prevtState.fromDay != this.state.fromDay || prevtState.titulo != this.state.titulo) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const { toDay, fromDay, disabled, dataSelect, titulo, entidad, valorEjeY, valorEjeX, cols, isLoading } = this.state;
    return (
      <div>
        <Card.Body>
          <Row className="d-flex align-items-center">
            <Col md={3}>
              <FormInputField
                id="fromDay"
                type="date"
                label="Fecha desde:"
                value={fromDay !== '' ? fromDay : null}
                onChange={this.handleDataChange}
                required
              />
            </Col>
            <Col md={3}>
              <FormInputField
                id="toDay"
                type="date"
                label="Fecha hasta:"
                onChange={this.handleDataChange}
                value={toDay !== '' ? toDay : null}
                min={fromDay !== '' ? fromDay : null}
                required
              />
            </Col>
            {entidad === 'General' ? (
              <div></div>
            ) : (
              <Fragment>
                <Col className="d-flex justify-content-end" md={5}>
                  {entidad === 'Clientes' && (
                    <FormSelectField
                      id="clientes"
                      label={entidad}
                      type="text"
                      choices={dataSelect}
                      choiceIdField="id"
                      choiceLabelField="razonSocial"
                      onChange={this.handleSubmit}
                      custom
                    />
                  )}
                  {entidad === 'Proveedores' && (
                    <FormSelectField
                      id="proveedores"
                      label={entidad}
                      type="text"
                      choices={dataSelect}
                      choiceIdField="id"
                      choiceLabelField="nombre"
                      onChange={this.handleSubmit}
                      custom
                    />
                  )}
                  {entidad === 'Articulos' && (
                    <FormSelectField
                      id="articulos"
                      label={entidad}
                      type="text"
                      choices={dataSelect}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      onChange={this.handleSubmit}
                      custom
                    />
                  )}
                  {entidad === 'Marcas' && (
                    <FormSelectField
                      id="marcas"
                      label={entidad}
                      type="text"
                      choices={dataSelect}
                      choiceIdField="codigo"
                      choiceLabelField="descripcion"
                      onChange={this.handleSubmit}
                      custom
                    />
                  )}
                </Col>
              </Fragment>
            )}
            <div style={{ 'margin-top': '0.8rem' }}>
              <Button variant="primary" size="md" onClick={this.handleDataChangaSubmit}>
                Buscar
              </Button>
            </div>
          </Row>
        </Card.Body>

        {isLoading == true ? (
          <Loading />
        ) : (
          <div>
            <BarChart titulo={titulo} entidad={entidad} valorEjeY={valorEjeY} valorEjeX={valorEjeX} />
            {entidad == 'Proveedores' && (
              <Col className="d-flex justify-content-end mt-4" md={12}>
                <DataTableExportCSVButton
                  getExportData={this.getExportData}
                  disabled={disabled}
                  cols={cols}
                  exportFileName={`Sell Out ${titulo} MaxReatial  ${moment(fromDay).format('MMM')}-${moment(fromDay).format('YYYY')}`}
                />
              </Col>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default withToastManager(Reporte);
