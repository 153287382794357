import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { withToastManager } from 'react-toast-notifications';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import APIClient from '../../../services/APIClient';
import { DataTable, FormSelectField } from '../../../components';
import { buildYearsOptions } from '../utils';

class CustomerPurchasedPerMonth extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      details: [],
      isDataLoading: false,
      totalSize: 0,
      selectedYear: '',
    };
  }

  handleFormChange = (e) => {
    const { value, name } = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  onLoadData = async () => {
    const { toastManager } = this.props;
    const { selectedYear } = this.state;
    try {
      this.setState(prevState => ({
        ...prevState,
        isDataLoading: true,
      }));

      const purchasedPerMonth = await APIClient.get(`/reportes/customer-product-by-month?year=${selectedYear}`);
      this.setState(prevState => ({
        ...prevState,
        details: purchasedPerMonth.data.data,
        totalSize: purchasedPerMonth.data.data.length,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  render() {
    const {
      details, selectedYear, isDataLoading, totalSize,
    } = this.state;

    const columns = [
      {
        dataField: 'customer',
        text: 'Cliente',
        sort: true,
      },
      {
        dataField: 'status',
        text: 'Estado',
        sort: true,
      },
      {
        dataField: 'brand',
        text: 'Marca',
        sort: true,
      },
      {
        dataField: 'code',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'product',
        text: 'Artículo',
        sort: true,
      },
      {
        dataField: 'january',
        text: 'Enero',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'february',
        text: 'Febrero',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'march',
        text: 'Marzo',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'april',
        text: 'Abril',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'may',
        text: 'Mayo',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'june',
        text: 'Junio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'july',
        text: 'Julio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'august',
        text: 'Agosto',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'september',
        text: 'Septiembre',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'october',
        text: 'Octubre',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'november',
        text: 'Noviembre',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'december',
        text: 'Diciembre',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title className="mb-4">Productos pedidos mes a mes</Card.Title>
            <Row className="d-flex justify-content-between">
              <Col md={6}>
                <FormSelectField
                  id="selectedYear"
                  label="Selecciona un año"
                  value={selectedYear}
                  onChange={this.handleFormChange}
                  choices={buildYearsOptions()}
                  choiceIdField="year"
                  choiceLabelField="year"
                />
              </Col>
              <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.onLoadData}>
                  Buscar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details || []}
              keyField="code"
              isDataLoading={isDataLoading}
              totalSize={totalSize}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(CustomerPurchasedPerMonth);