module.exports.cols = [

  {
    dataField: 'Fecha',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Comprobante',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Cliente',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Cuit',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Direccion',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Localidad',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Provincia',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Producto_Servicio',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Codigo_ean',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Lista_de_precios',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Cantidad',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Precio_lista',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Importe_bruto',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Descuento',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Importe_descuento',
    sort: false,
    hidden: true,
  },
  {
    dataField: 'Importe_neto',
    sort: false,
    hidden: true,
  },
]