import React from 'react';
import { Route } from 'react-router-dom';
import PuntoEntregaList from './PuntoEntregaList';
import PuntoEntregaEdit from './PuntoEntregaEdit';

const routes = () => [
  <Route path="/puntos-entrega/nuevo" exact component={PuntoEntregaEdit} key="/puntos-entrega/nuevo" />,
  <Route path="/puntos-entrega/:id" component={PuntoEntregaEdit} key="/puntos-entrega/:id" />,
  <Route path="/puntos-entrega" exact component={PuntoEntregaList} key="/puntos-entrega" />,
].map(route => route);

export default routes;
