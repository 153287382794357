import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { colsLista } from './colsDataExcel';
import { ColumnTable, Props } from '../Interfaces/interfaces';
import { ComboListState } from './comboInterface';

class ComboList extends React.Component<Props, ComboListState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isDataLoading: true,
      isPriceDataLoading: true,
      comboDetalle: [],
      combos: [],
      selectedCombo: {},
      listaPrecio: [],
      //excel
      cols: colsLista,
    };

    this.loadCombos = this.loadCombos.bind(this);
  }

  componentDidMount() {
    this.loadCombos();
  }

  async loadCombos() {
    const { toastManager } = this.props;

    try {
      const comboRes = await APIClient.get('/combo');
      const listaPrecio = await APIClient.get(`/listas-precio`);
      this.setState({
        combos: comboRes.data.data,
        listaPrecio: listaPrecio.data.data,
      });
    } catch (err: any) {
      this.setState({ isDataLoading: false });
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  //get excel
  getExportData = async () => {
    const apiResponse = await APIClient.get(`/combo/combo-Scv`);
    return apiResponse.data.data[0];
  };

  render() {
    const { combos, isDataLoading, listaPrecio, cols } = this.state;

    const columns: Array<ColumnTable> = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'ean',
        text: 'Código EAN',
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
        sort: true,
      },
      {
        dataField: 'listaPrecioId',
        text: 'Lista de precios',
        sort: true,
        editable: false,
        formatter: (cellContent: any, row: any) => `${listaPrecio.find((elem) => elem.id === row.listaPrecioId)?.descripcion}`,
      },
      {
        dataField: 'isActive',
        text: 'Activo',
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { textAlign: 'center' },
        formatter: (cellContent: any, row: any) => <>{cellContent && <FontAwesomeIcon icon={faTimes} />}</>,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent: any, row: any) => (
          <ButtonToolbar className="justify-content-center">
            <ButtonGroup>
              <LinkContainer to={`/combo/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];
    return (
      <div>
        <h1 className="page-title">Combos</h1>
        <DataTable
          isDataLoading={isDataLoading}
          columns={columns}
          data={combos}
          keyField="id"
          addButton="/combo/nuevo"
          /* Excel */
          cols={cols}
          getExportData={this.getExportData}
          exportFileName={'Lista_de_Combos'}
        />
      </div>
    );
  }
}

export default withToastManager(ComboList);
