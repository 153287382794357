import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { DataTable, FormSelectField } from '../../../components';
import APIClient from '../../../services/APIClient';
import DataTableExportCSVButton from '../../../components/DataTableExportCSVButton';

class ValuedStock extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      priceLists: [],
      selectedPriceList: '',
      details: [],
      totalSize: 0,
      isDataLoading: true,
      totalSize: 0,
      disabled: true
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { toastManager } = this.props;
    try {
      const priceListsRes = await APIClient.get('/listas-precio?filter[eliminadoFlag][eq]=0');
      this.setState(prevState => ({
        ...prevState,
        priceLists: priceListsRes.data.data,
        isDataLoading: false
      }));
    } catch (error) {
      toastManager.add('Ocurrió un error', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  onSubmit = async () => {
    const { toastManager } = this.props;
    const { selectedPriceList } = this.state;
    try {
      const valuedStockReport =  await APIClient.get(`/reportes-stock/valued-stock?priceListId=${selectedPriceList}`);
      this.setState(prevState => ({
        ...prevState,
        details: valuedStockReport.data.data,
        totalSize: valuedStockReport.data.data.length,
        disabled: !(valuedStockReport.data.data.length > 0)
      }));
    } catch (error) {
      toastManager.add(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  handleFormChange = (e) => {
    const { id, value } = e.target;
    this.setState(prevState => ({
        ...prevState,
        [id]: value,
    }));
  };

  getExportData = () => {
    const { details } = this.state;
    return details;
  }

  render() {
    const { details, totalSize, isDataLoading, disabled, priceLists, selectedPriceList } = this.state;

    const columns = [
      {
        dataField: 'articulo',
        text: 'Artículo',
        sort: true,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        align: 'right',
        headerAlign: 'right',
        sort: true,
      },
      {
        dataField: 'total',
        text: 'En stock',
        align: 'right',
        headerAlign: 'right',
        sort: true,
      },
      {
        dataField: 'valor_total',
        text: 'Valorizado',
        align: 'right',
        headerAlign: 'right',
        sort: true,
      },
      {
        dataField: 'disponible',
        text: 'Disponibles',
        align: 'right',
        headerAlign: 'right',
        sort: true,
      },
      {
        dataField: 'valor_disponible',
        text: 'Valorizado',
        align: 'right',
        headerAlign: 'right',
        sort: true,
      }
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title>Reporte de productos próximos a vencer</Card.Title>
            <Row>
                <Col md={6}>
                <FormSelectField
                  id="selectedPriceList"
                  label="Selecciona una lista de precio"
                  value={selectedPriceList}
                  onChange={this.handleFormChange}
                  choices={priceLists}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                />
                </Col>
                <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.onSubmit}>
                  Generar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details}
              totalSize={totalSize}
              isDataLoading={isDataLoading}
              keyField="id"
              showExport={false}
            />
            <DataTableExportCSVButton
              getExportData={this.getExportData}
              disabled={disabled}
              cols={columns}
              exportFileName={`stock_valorizado_lista_precio_${selectedPriceList}`}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(ValuedStock);
