import React from 'react';

/**
 * Devuelve el nombre de un estado dado el codigo
 * @param {string} codigo Codigo de estado
 */
function getSolicitudEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'NE':
      nombre = 'No Entregado';
      variant = 'secondary';
      break;
    case 'SA':
      nombre = 'En Carga';
      variant = 'warning';
      break;
    case 'SB':
      nombre = 'Cancelado';
      variant = 'danger';
      break;
    case 'SC':
      nombre = 'Cerrado';
      variant = 'secondary';
      break;
    case 'SE':
      nombre = 'Envío Completo';
      variant = 'success';
      break;
    case 'SP':
      nombre = 'Confirmado';
      variant = 'info';
      break;
    case 'SF':
      nombre = 'Facturado';
      variant = 'primary';
      break;
    default:
      break;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function getEntregaEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'EA':
      nombre = 'En Carga';
      variant = 'warning';
      break;
    case 'EC':
      nombre = 'Confirmada';
      variant = 'success';
      break;
      case 'EF':
      nombre = 'Facturada';
      variant = 'info';
      break;
    default:
      break;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}
/////////ARTICULOS
function getArticulosEstadoBadge(fieldStatus, settingsObject) {
  return fieldStatus ? <span className={`badge badge-${settingsObject.color.true}`}>{settingsObject.text.true}</span> : <span className={`badge badge-${settingsObject.color.false}`}>{settingsObject.text.false}</span>;
};

/////////////FIN ARTICULOS

function getClienteEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'Activo':
      nombre = codigo;
      variant = 'success';
      break;
    case 'Inactivo':
      nombre = codigo;
      variant = 'danger';
      break;
    case 'Prospecto':
      nombre = codigo;
      variant = 'warning';
      break;
    default:
      break;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function generateHours() {
  const newHours = [];
  for (let i = 0; i < 24; i++) {
    let hour = i.toString();
    if (i < 10) {
      hour = hour.padStart(2, '0');
    }
    newHours.push({ hour: `${hour}:00` });
    newHours.push({ hour: `${hour}:30` });
  }
  return newHours;
}

function generateRandomColors(n) {
  const randomColors = [];
  for (let i = 0; i < n; i++) {
    let randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    if (randomColor.length < 7) {
      const newColor = generateRandomColors(1)[0];
      randomColor = newColor;
    }
    randomColors.push(randomColor);
  }
  return randomColors;
};
const bgBlueOnMouseEnter = {
  onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
    e.target.style.cursor = `pointer`;
    // if (e.target.tagName === 'TD') {
    //   e.target.classList.add('bg-primary');
    //   e.target.style.color = 'white';
    // }
    // if (e.target.tagName === 'DIV') {
    //   e.target.parentNode.classList.add('bg-primary');
    //   e.target.parentNode.style.color = 'white';
    // }
    // if (e.target.tagName === 'SMALL') {
    //   e.target.parentNode.parentNode.classList.add('bg-primary');
    //   e.target.parentNode.parentNode.style.color = 'white';
    // }
    // if (e.target.tagName === 'A') {
    //   e.target.parentNode.parentNode.classList.add('bg-primary');
    // }
  },
  onMouseLeave: (e, column, columnIndex, row, rowIndex) => {
    // if (e.target.tagName === 'TD') {
    //   e.target.classList.remove('bg-primary');
    //   e.target.style.color = 'black';
    // }
    // if (e.target.tagName === 'DIV') {
    //   e.target.parentNode.classList.remove('bg-primary');
    //   e.target.parentNode.style.color = 'black';
    // }
    // if (e.target.tagName === 'SMALL') {
    //   e.target.parentNode.parentNode.classList.remove('bg-primary');
    //   e.target.parentNode.parentNode.style.color = 'black';
    // }
    // if (e.target.tagName === 'A') {
    //   e.target.parentNode.parentNode.classList.remove('bg-primary');
    //   e.target.style.color = '#007BFF';
    // }
  },
};

export default {
  getSolicitudEstadoBadge,
  generateHours,
  generateRandomColors,
  getEntregaEstadoBadge,
  getClienteEstadoBadge,
  bgBlueOnMouseEnter,
  getArticulosEstadoBadge,
};
