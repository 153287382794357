import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DataTable, EntityEditForm, FormAddressInput, FormSwitch } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import { ClientEditState, FullPuntoEntrega, InitSocialMedia } from './clientInterface';
import { Props, PuntosEntrega, Zona } from '../Interfaces/interfaces';
import FormInput from '../../components/componentsTs/FormInput';
import FormSelect from '../../components/componentsTs/FormSelect';

class ClienteEdit extends React.Component<Props, ClientEditState> {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);

    const { id } = props.match.params ;
    const pageNumbUrl = Utils.sanitizeQuery(['page'], props.location.search).page;
    const pageNum = parseInt(pageNumbUrl);
    const page = Utils.isPositiveInteger(pageNum) ? pageNum : 1;
    // init values for social media array
    const initSocialMedia = { urlUsername: '', socialMediaCode: '' };
    this.state = {
      id,
      page,
      isAdding: typeof id === 'undefined',
      selectedLista: {},
      entity: {},
      tipos: [],
      vendedores: [],
      listasPrecio: [],
      puntosEntrega: [],
      selectedListaId: null,
      isDataLoading: typeof id === 'undefined',
      showModal: false,
      condicionesPago: [],
      zonas: [],
      estados: [],
      socialMediaOptions: [],
      socialMedia: [initSocialMedia],
      provincias: [],
      clienteId: id,
    };
  }

  getListaPrecioDetailModal = () => {
    // create table for lista precio
    const { isDataLoading, selectedLista, showModal } = this.state;
    const columns = [
      {
        dataField: 'articulo.descripcion',
        text: 'Artículo',
        sort: true,
      },
      {
        dataField: 'talleNombre',
        text: 'Talle',
        sort: true,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        classes: 'text-right',
      },
    ];

    return (
      <Modal size="lg" show={showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          {selectedLista !== undefined && selectedLista !== {} ? (
            <Modal.Title>{selectedLista.descripcion}</Modal.Title>
          ) : (
            <Modal.Title>Lista de Precio</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {selectedLista !== undefined && selectedLista !== {} ? (
            <DataTable isDataLoading={isDataLoading} columns={columns} data={selectedLista.detalles || []} keyField="id" />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  saveSelectedListaId = (e: any) => {
    this.setState({ selectedListaId: e.target.value });
  };

  showModalAndGetArticulos = async () => {
    const { selectedListaId } = this.state;
    // get articulos
    const selectedLista = await APIClient.get(`/listas-precio/${selectedListaId}`);
    this.setState({ selectedLista: selectedLista.data.data, showModal: true });
  };

  onLoadForm = async () => {
    // VERRR
    const { clienteId } = this.state;
    // get cliente estado
    const estadosRes = await APIClient.get('/cliente-estados?filter[id][or]=1&filter[id][or]=2');
    // get cliente tipos
    const tiposRes = await APIClient.get('/cliente-tipos');
    // get vendedores
    const vendedoresRes = await APIClient.get('/vendedores');
    // get lista precio
    const listasPrecioRes = await APIClient.get('/listas-precio');
    // get lista precio
    const puntosEntregaRes = await APIClient.get(`/clientes/${clienteId}/puntos-entrega`);
    // get social media options
    const socialMediaRes = await APIClient.get('/social-media');

    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe: FullPuntoEntrega) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${
        !pe.depto ? '' : ' ' + pe.depto
      }
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`;
      return pe;
    });

    // get provincias
    const provinciasRes = await APIClient.get('/provincias');
    // get condiciones pago
    const condicionesPago = await APIClient.get('/condiciones-pago');
    // get zonas
    const zonasRes = await APIClient.get('/zonas');
    this.setState({
      tipos: tiposRes.data.data,
      estados: estadosRes.data.data,
      vendedores: vendedoresRes.data.data,
      listasPrecio: listasPrecioRes.data.data,
      provincias: provinciasRes.data.data,
      puntosEntrega: puntosDeEntrega,
      condicionesPago: condicionesPago.data.data,
      zonas: zonasRes.data.data.filter((e: Zona) => e.isVisible === true),
      socialMediaOptions: socialMediaRes.data.data,
    });
  };

  onAddSocialMedia = () => {
    const { socialMedia, isAdding, id } = this.state;
    const newSocialMedia: InitSocialMedia = {
      urlUsername: '',
      socialMediaCode: '',
      // this property is to identify if an existing customer
      // will have a new social media
      newForUpdate: !isAdding,
    };
    if (isAdding === false && id) {
      newSocialMedia.customerId = id;
    }
    socialMedia.push(newSocialMedia);
    const newSocialMediaArray = socialMedia;
    this.setState({
      socialMedia: newSocialMediaArray,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;
    // get cliente
    const clienteRes = await APIClient.get(`/clientes/${id}`);
    const puntosEntregaRes = await APIClient.get(`/clientes/${id}/puntos-entrega`);
    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe: PuntosEntrega) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${
        !pe.depto ? '' : ' ' + pe.depto
      }
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`;
      return pe;
    });
    this.setState({
      entity: clienteRes.data.data,
      selectedListaId: clienteRes.data.data.listaPrecioId,
      puntosEntrega: puntosDeEntrega,
      socialMedia: clienteRes.data.data.socialMedias,
    });
    return clienteRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave: any) => {
    const { id, page, isAdding, socialMedia } = this.state;
    const { history, toastManager } = this.props;

    // parse customer social media data
    const entityToSaveKeys = Object.keys(entityToSave);
    entityToSaveKeys.forEach((e) => {
      // find if any customer social media will be updated
      const isUsernameUpdate = /urlUsername/;
      const issocialMediaCodeUpdate = /socialMediaCode/;
      // organize data in array
      if (isUsernameUpdate.test(e)) {
        const index = Number(e.slice(12));
        socialMedia[index].urlUsername = entityToSave[e];
      }
      if (issocialMediaCodeUpdate.test(e)) {
        const index = Number(e.slice(16));
        socialMedia[index].socialMediaCode = entityToSave[e];
      }
    });
    entityToSave.socialMedias = socialMedia;
    let saveResponse;
    try {
      if (isAdding) {
        saveResponse = await APIClient.post('/clientes', entityToSave);
        APIClient.get('/clientes/actualizarCodigoInterno');
      } else {
        saveResponse = await APIClient.patch(`/clientes/${id}`, entityToSave);
      }
      history.push(`/clientes?page=${page}`);
      toastManager.add(`Cliente ${saveResponse.data.data.id} guardado con éxito`, {
        appearance: 'success',
        autoDismiss: true,
      });
      return saveResponse.data.data;
    } catch (error) {
      return toastManager.add(`Ocurrió un error al momento de guardar el cliente ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  render() {
    const {
      id,
      entity,
      tipos,
      estados,
      vendedores,
      listasPrecio,
      provincias,
      puntosEntrega,
      isAdding,
      selectedListaId,
      condicionesPago,
      zonas,
      socialMediaOptions,
      socialMedia,
    } = this.state;

    return (
      <div>
        {isAdding ? (
          <h1 className="page-title"> Cliente nuevo </h1>
        ) : (
          <Row className="page-title">
            <Col className="d-flex justify-content-between pr-0" md={12}>
              <h1 className="d-flex my-3">
                {' '}
                Cliente #{id}
                {entity && entity.estado ? (
                  <div className="ml-3 d-flex">{UIUtils.getClienteEstadoBadge(entity.estado.descripcion)}</div>
                ) : null}
              </h1>
            </Col>
          </Row>
        )}
        {this.getListaPrecioDetailModal()}

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Información Principal</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormInput id="razonSocial" label="Razón Social" type="text" defaultValue={entity.razonSocial} required />
                  </Col>
                  <Col md={6}>
                    <FormInput id="documentoNumero" label="CUIT" type="text" defaultValue={entity.documentoNumero} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInput id="nombreFantasia" label="Nombre de Fantasía" type="text" defaultValue={entity.nombreFantasia} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="codigoInterno" label="Código interno" type="text" defaultValue={entity.codigoInterno} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormSelect
                      id="tipoId"
                      label="Tipo"
                      defaultValue={entity.tipoId}
                      choices={tipos}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>

                  <Col md={6}>
                    <FormSelect
                      id="estadoId"
                      label="Estado"
                      defaultValue={entity.estadoId}
                      choices={estados}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      required
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Información de Contacto</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormInput id="contacto" label="Contacto Principal" defaultValue={entity.contacto} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="telefono" label="Teléfono Principal" type="text" defaultValue={entity.telefono} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInput id="celular" label="Teléfono móvil" type="text" defaultValue={entity.celular} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="email" label="Email Principal" type="text" defaultValue={entity.email} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInput id="contactoComercial" label="Contacto Comercial" type="text" defaultValue={entity.contactoComercial} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="telefonoComercial" label="Teléfono Comercial" type="tel" defaultValue={entity.telefonoComercial} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="emailComercial" label="Email Comercial" type="text" defaultValue={entity.emailComercial} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInput id="contactoCobranzas" label="Contacto Cobranzas" type="text" defaultValue={entity.contactoCobranzas} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="telefonoCobranzas" label="Teléfono Cobranzas" type="tel" defaultValue={entity.telefonoCobranzas} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="emailCobranzas" label="Email Cobranzas" type="text" defaultValue={entity.emailCobranzas} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormAddressInput provincias={provincias} addMode={isAdding} entity={entity} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInput id="longitud" label="Longitud" type="text" defaultValue={entity.longitud} />
                  </Col>
                  <Col md={6}>
                    <FormInput id="latitud" label="Latitud" type="text" defaultValue={entity.latitud} />
                  </Col>
                </Row>
                <Row md={12}>
                  <Col>
                    <Card>
                      <Card.Body>
                        <div className="d-flex justify-content-between">
                          <Card.Title>Redes sociales</Card.Title>
                          <Button onClick={this.onAddSocialMedia}>Agregar más</Button>
                        </div>
                        {socialMedia.map((arg, index) => (
                          <Row key={index}>
                            <Col md={6}>
                              <FormSelect
                                id={`socialMediaCode-${index}`}
                                label="Red social"
                                choices={socialMediaOptions}
                                choiceIdField="code"
                                choiceLabelField="description"
                                defaultValue={arg.socialMediaCode}
                              />
                            </Col>
                            <Col md={6}>
                              <FormInput id={`urlUsername-${index}`} label="Url o perfil" type="text" defaultValue={arg.urlUsername} />
                            </Col>
                          </Row>
                        ))}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Información Comercial</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormSelect
                      id="vendedorId"
                      label="Ejecutivo comercial"
                      defaultValue={entity.vendedorId}
                      choices={vendedores}
                      choiceIdField="id"
                      choiceLabelField="nombre"
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="listaPrecioId">
                      <Form.Label>Lista de Precio</Form.Label>
                      <InputGroup>
                        <Form.Control
                          as="select"
                          onChange={this.saveSelectedListaId}
                          name="listaPrecioId"
                          defaultValue={entity.listaPrecioId}>
                          {isAdding && <option value="">(Seleccione)</option>}
                          {listasPrecio.map((lista) => (
                            <option key={lista.id} value={lista.id}>
                              {lista.descripcion}
                            </option>
                          ))}
                        </Form.Control>
                        {selectedListaId === null || selectedListaId === '' ? null : (
                          <InputGroup.Prepend>
                            <Button onClick={this.showModalAndGetArticulos} size="sm" variant="outline-secondary" title="Detalle">
                              <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="sm" />
                            </Button>
                          </InputGroup.Prepend>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormSelect
                      id="puntoEntregaId"
                      label="Punto de Entrega"
                      defaultValue={entity.puntoEntregaId}
                      choices={puntosEntrega}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <FormSelect
                      id="zonaId"
                      label="Zona"
                      defaultValue={entity.zonaId}
                      choices={zonas}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                  <Col md={4}>
                    <FormInput id="entregaHorario" label="Horario de Entrega" defaultValue={entity.entregaHorario} />
                  </Col>
                  <Col md={2}>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label className="form-label">Días de Entrega</label>
                          <Row className="align-items-center">
                            <Col md={6}>
                              <input
                                id="diasEntrega"
                                name="diasEntrega"
                                type="number"
                                className="form-control text-right"
                                defaultValue={entity.diasEntrega}
                                min={0}
                              />
                            </Col>
                            <Col md={6}>
                              <p className="mb-0">dias.</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormSelect
                      id="condicionPagoId"
                      label="Condición de Pago"
                      defaultValue={entity.condicionPagoId}
                      choices={condicionesPago}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormSwitch
                      id="excludeFromAverage-switch"
                      label={`Excluir del promedio de venta diario.
                        ${
                          entity.excludeFromAverageDailySale
                            ? ' La funcionalidad está activada para este cliente'
                            : ' La funcionalidad no está activada para este cliente'
                        }`}
                      checked={entity.excludeFromAverageDailySale}
                      labelField="excludeFromAverageDailySale"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ClienteEdit);
