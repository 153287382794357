import React from 'react';
import { Bar } from 'react-chartjs-2';

export const BarChart = (props) => {
  const { titulo, entidad, valorEjeY, valorEjeX } = props;
  return (
    <div>
      <Bar
        data={{
          labels: valorEjeY,
          datasets: [
            {
              label: `Ganancia en Pesos - ${entidad}  ${titulo} `,
              data: valorEjeX,
              backgroundColor: ['rgba(255, 99, 132, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)'],
              borderWidth: 1,
            },
          ],
        }}
        height={400}
        width={600}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            labels: {
              fontSize: 25,
            },
          },
        }}
      />
    </div>
  );
};
