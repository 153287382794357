import React from 'react';
import { Route } from 'react-router-dom';
import ListaPrecioList from './ListaPrecioList';
import ListaPrecioEdit from './ListaPrecioEdit';

const routes = () => [
  <Route path="/listas-precio/nuevo" exact component={ListaPrecioEdit} key="/listas-precio/nuevo" />,
  <Route path="/listas-precio/:id" component={ListaPrecioEdit} key="/listas-precio/:id" />,
  <Route path="/listas-precio" exact component={ListaPrecioList} key="/listas-precio" />,
].map(route => route);

export default routes;
