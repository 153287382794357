import React from 'react';
import { Route } from 'react-router-dom';
import ZonaList from './ZonaList';
import ZonaEdit from './ZonaEdit';

const routes = () => [
  <Route path="/zonas/nueva" exact component={ZonaEdit} key="/zonas/nueva" />,
  <Route path="/zonas/:id(\d+)" component={ZonaEdit} key="/zonas/:id" />,
  <Route path="/zonas" exact component={ZonaList} key="/zonas/:id" />,
].map(route => route);

export default routes;
