import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateNotificationsState } from '../services/notifications/notification.splice';
import { areNotifications } from '../services/notifications/notificationsService';
import Layout from './Layout';

export default function NotificationLayout() {
  const dispatch = useDispatch();
  const history = useHistory();

  const notifications = async () => {
    const notificationState = await areNotifications();
    // set the redux state
    dispatch(updateNotificationsState(notificationState));
  };

  useEffect(() => {
    (async () => {
      // notification init
      await notifications();

      // every 5 minutes check if there are new notifications
      const interval = setInterval(async () => {
        await notifications();
      }, 300000);
      return () => clearInterval(interval);
    })();
  }, []);
  return <Layout history={history} />;
}
