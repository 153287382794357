const xubioService = {
  makeSplitFacturacionBody: function makeSplitFacturacionBody(facturasDesagregadasData, xubioBody) {
    const xubioBodyCloned = JSON.parse(JSON.stringify(xubioBody));
    const desagregadasCloned = JSON.parse(JSON.stringify(facturasDesagregadasData));
    const splittedProducts = desagregadasCloned.map(factura => factura.transaccionProductoItems);
    delete xubioBodyCloned.transaccionProductoItems;
    const head = xubioBodyCloned;
    return {
      head,
      mainProducts: xubioBody.transaccionProductoItems,
      splittedProducts,
    };
  },
};

export default xubioService;
