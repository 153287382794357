import React from 'react';
import { Route } from 'react-router-dom';
import ExpresoList from './ExpresoList';
import ExpresoEdit from './ExpresoEdit';

const routes = () => [
  <Route path="/expresos/nuevo" exact component={ExpresoEdit} key="/expresos/nuevo" />,
  <Route path="/expresos/:id" exact component={ExpresoEdit} key="/expresos/:id" />,
  <Route path="/expresos" exact component={ExpresoList} key="/expresos" />,
].map(route => route);

export default routes;
