import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row, Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { faCircleNotch, faEdit } from '@fortawesome/free-solid-svg-icons';
import Security from '../../services/Security';
import APIClient from '../../services/APIClient';
import { DataTable, FormInputField, FormSelectField } from '../../components';
import { flowOptions, currentDate, dictionary, chooseBgColor, filterValidations, parseInteractionsData, formValidations, addPropertyAlreadyUpload } from './Utils';
import AddInteractionModal from '../../components/interaction/AddInteractionModal';
import Utils from '../Utils';
import { interactionService } from '../../services/interactions/interactionService';

const session = Security.getSession();

class InteractionsList extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { cliente } = Utils.sanitizeQuery(['cliente'], props.location.search);
    this.state = {
      session,
      curFromDate: '',
      curToDate: '',
      customersFilterSelected: [],
      showAddInteractionModal: cliente ? true : false,
      // states of the modal
      isAdding: true,
      interactionEdit: {},
      customers: [],
      customerId: cliente || '',
      startDate: currentDate,
      dueDate: '',
      flowOptions,
      interactionFlow: 'entrante',
      stateOptions: [],
      interactionState: 100,
      typesOption: [],
      interactionType: 1,
      usersOptions: [],
      userId: session.user.id,
      isDataLoading: true,
      interactions: [],
      description: '',
      bgColor: dictionary.bgColor.NEW,
      // states bulk customers modal
      files: [],
      showAddCustomerModal: false,
      customersIdsForBulkInteraction: [],
    };
  }

  componentDidMount() {
    this.loadData();
    this.loadSelectData();
  }

  loadData = async () => {
    // load interactions
    const interactionsRes = await APIClient.get('/interaction/list');

    // parse date
    const parseInteractions = parseInteractionsData(interactionsRes.data.data);

    this.setState((prevState) => ({
      ...prevState,
      interactions: parseInteractions,
      isDataLoading: false,
      userId: session.user.id,
    }));
  };

  loadSelectData = async () => {
    const { toastManager } = this.props;
    try {
      this.setState((prevState) => ({
        ...prevState,
        isDataLoading: true,
      }));

      // get clientes
      const customersRes = await APIClient.get('/clientes?sortField=razonSocial&sortDir=asc');
      // get interaction types
      const interactionTypeRes = await APIClient.get('/interaction-types');
      // get interaction state
      const interactionStateRes = await APIClient.get('/interaction-states');
      // get users
      const usersRes = await APIClient.get('/usuarios?filter[is_super_admin][eq]=1&filter[is_active][eq]=1');
      // join name with last name for show in select
      const parseUsers = usersRes.data.data.map((e) => {
        e.name = `${e.firstName} ${e.lastName}`;
        return e;
      });

      this.setState((prevState) => ({
        ...prevState,
        customers: customersRes.data.data,
        stateOptions: interactionStateRes.data.data,
        typesOption: interactionTypeRes.data.data,
        usersOptions: parseUsers,
        isDataLoading: false,
      }));
    } catch (err) {
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  onEditInteraction = async (interactionId) => {
    const interactionRes = await APIClient.get(`/interaction/${interactionId}`);
    const interaction = interactionRes.data.data;
    const bgColor = chooseBgColor(interaction.interactionState.toString());
    const files = addPropertyAlreadyUpload(interaction.files);
    
    this.setState((prevState) => ({
      ...prevState,
      isAdding: false,
      interactionEdit: interaction,
      showAddInteractionModal: true,
      customerId: interaction.customerId,
      startDate: moment.utc(interaction.startDate).format('YYYY-MM-DD'),
      dueDate: moment.utc(interaction.dueDate).format('YYYY-MM-DD'),
      interactionFlow: interaction.interactionFlow,
      interactionState: interaction.interactionState,
      interactionType: interaction.interactionType,
      userId: interaction.userId,
      description: interaction.description,
      files,
      bgColor,
    }));

    if (interaction.isSeen === 0 && Number(interaction.userId) === Number(session.user.id)) {
      await this.updateIsSeenState(interaction);
    }
  };

  updateIsSeenState = async (interaction) => {
    const { toastManager } = this.props;
    try {
      await APIClient.patch(`/interaction/${interaction.id}`, { isSeen: 1 });
      await this.loadData();
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  handleDataChange = (e) => {
    let { id, value, options, multiple } = e.target;
    let values;
    if (multiple) {
      values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
    }

    this.setState((prevState) => ({
      ...prevState,
      [id]: multiple ? values : value,
    }));
  };

  handleInteractionStateInput = (e) => {
    const { value } = e.target;

    const bgColor = chooseBgColor(value);
    this.setState((prevState) => ({
      ...prevState,
      interactionState: Number(value),
      bgColor,
    }));
  };

  handleFileChange = (e) => {
    const { toastManager } = this.props;
    const newFile = e.target.files[0];
    if (newFile) {
      if (newFile.size > 5000000) {
        toastManager.add('El archivo debe ser menor a 5 MB', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        this.setState((prevState) => {
          const { files } = prevState;
          const newFileToUpload = {};
          const fileTempUrl = URL.createObjectURL(newFile);
          newFileToUpload.name = newFile.name;
          newFileToUpload.filename = fileTempUrl;
          newFileToUpload.alreadyUpload = false;
          files.push(newFileToUpload);
          return { ...prevState, files };
        });
      }
    }
  }

  deleteFile = async (fileToDelete) => {
    const { toastManager } = this.props;
    const { interactionEdit } = this.state;
    try {
      if (fileToDelete.id) {
        await interactionService.deleteFile(interactionEdit.id, fileToDelete.id);
      }
      this.setState((prevState) => {
        const { files } = prevState;
        const newFiles = files.filter((file) => file.filename !== fileToDelete.filename);

        return {
          ...prevState,
          files: newFiles,
        };
      });
    } catch (error) {
      console.error(error);
      toastManager.add('Ocurrió un error', {
        appearance: 'error',
      });
    }
  };

  handleSelectAll = (e) => {
    const { id } = e.target;
    this.setState((prevState) => {
      const { customers } = prevState;
      let { customersFilterSelected } = prevState;
      if (id === 'select-all-customers') {
        customersFilterSelected = customers.map((cli) => cli.id);
      }
      return { ...prevState, customersFilterSelected };
    });
  };

  renderSelectAllButtons = (entityName) => {
    if (entityName === 'customers') {
      return (
        <p className="m-0">
          {entityName.substr(0, 1).toUpperCase()}
          {entityName.substr(1)}
          (
          <button
            id={`select-all-${entityName}`}
            type="submit"
            className="link-button text-primary"
            onClick={(e) => this.handleSelectAll(e, 'customersFilterSelected')}
          >
            Seleccionar todos
          </button>
          )
        </p>
      );
    }
    return '';
  };

  handleAddInteractionModal = (action) => {
    switch (action) {
      case 'open':
        this.setState({
          showAddInteractionModal: true,
        });
        break;
      case 'close':
        this.setState({
          interactionEdit: {},
          customerId: '',
          startDate: currentDate,
          dueDate: '',
          flowOptions,
          interactionFlow: 'entrante',
          interactionState: dictionary.state.NEW,
          interactionType: 1,
          description: '',
          bgColor: dictionary.bgColor.NEW,
          showAddInteractionModal: false,
          customersIdsForBulkInteraction: [],
        });
        break;
      case 'back':
        this.setState({
          showAddInteractionModal: false,
          showAddCustomerModal: true,
        });
        break;

      default:
        break;
    }
  };

  onSaveEntity = async () => {
    const { toastManager } = this.props;
    const {
      isAdding,
      interactionEdit,
      customerId,
      customersIdsForBulkInteraction,
      interactionType,
      startDate,
      dueDate,
      interactionFlow,
      userId,
      interactionState,
      description,
      files,
    } = this.state;

    const entity = {
      customerId,
      customersIdsForBulkInteraction,
      interactionType,
      startDate,
      dueDate,
      interactionFlow,
      userId,
      interactionState,
      description,
      files,
    };

    // files that have not been uploaded to the database
    const filesToUpload = files.filter(e => e.alreadyUpload === false);

    if (customersIdsForBulkInteraction.length === 0 && isAdding) {
      entity.customersIdsForBulkInteraction = [ customerId ];
      delete entity.customerId
    }

    try {
      const validations = formValidations(this.state);
      if (!validations.status) {
        throw validations;
      }

      let saveResponse;
      if (isAdding) {
        
        saveResponse = await APIClient.post('/interaction', entity);
      } else {
        delete entity.customersIdsForBulkInteraction;
        saveResponse = await APIClient.patch(`/interaction/${interactionEdit.id}`, entity);
      }
      
      if (filesToUpload.length > 0) {
        const interactionId = isAdding ? saveResponse.data.data[0].id : interactionEdit.id;
        await interactionService.loadFile(filesToUpload, interactionId);
      }

      // set state
      this.setState(() => ({
        isAdding: true,
        interactionEdit: {},
        showAddInteractionModal: false,
        customerId: '',
        startDate: currentDate,
        dueDate: '',
        interactionFlow: 'entrante',
        interactionState: 100,
        interactionType: 1,
        userId: session.user.id,
        isDataLoading: true,
        description: '',
        bgColor: dictionary.bgColor.NEW,
        customersIdsForBulkInteraction: [],
      }));

      toastManager.add(`La interacción ${saveResponse.data.data.id} se guardó con éxito`, {
        appearance: 'success',
        autoDismiss: true,
      });

      await this.loadData();
    } catch (error) {
      toastManager.add(error.message, {
        appearance: error.appearance || 'error',
        autoDismiss: true,
      });
    }
  };

  onApplyFilters = async () => {
    const { toastManager } = this.props;
    let { curFromDate, curToDate } = this.state;
    const { customersFilterSelected } = this.state;
    try {
      // validations
      const validations = filterValidations(curFromDate, curToDate, customersFilterSelected);
      if (validations !== true) {
        throw validations;
      }

      // parse dates
      curFromDate = moment(curFromDate).format('YYYY-MM-DD');
      curToDate = moment(curToDate).format('YYYY-MM-DD');

      // parse query
      const queryParams = `customersFilterSelected=${customersFilterSelected}&curToDate=${curToDate}&curFromDate=${curFromDate}`;
      const interactionsRes = await APIClient.get(`/interaction/list?${queryParams}`);

      // parse date
      const parseInteractions = parseInteractionsData(interactionsRes.data.data);

      // save response in state
      this.setState({
        interactions: parseInteractions,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error.message}`, {
        appearance: error.appearance || 'error',
        autoDismiss: true,
      });
    }
  }

  rowClasses = (row) => {
    switch (row.isSeen) {
      case dictionary.state.SEEN:
        return '';
      case dictionary.state.NOTSENN:
        return 'font-weight-bold';
      default:
        break;
    }
  };

  onSelectCustomer = (row, isSelect) => {
    const { customersIdsForBulkInteraction } = this.state;
    let newcustomersIdsForBulkInteraction = customersIdsForBulkInteraction;

    if (isSelect) {
      newcustomersIdsForBulkInteraction.push(row.id);
    } else {
      newcustomersIdsForBulkInteraction = newcustomersIdsForBulkInteraction.filter(e => e !== row.id)
    }

    this.setState({
      customersIdsForBulkInteraction: newcustomersIdsForBulkInteraction,
    });
  }
  
  handlerCustomersModal = (action) => {
    let stateForCustomerModal;
    switch (action) {
      case 'open':
        stateForCustomerModal = {
          showAddCustomerModal: true,
        };
        break;
      case 'close':
        stateForCustomerModal = {
          showAddCustomerModal: false,
          customersIdsForBulkInteraction: [],
        }
        break;
      case 'next':
        stateForCustomerModal = {
          showAddCustomerModal: false,
          showAddInteractionModal: true,
        }
      default:
        break;
    }
    this.setState(stateForCustomerModal);
  }

  customerListModal = () => {
    // render articulos modal
    const { customers, customersIdsForBulkInteraction } = this.state;

    const columns = [
      {
        dataField: 'codigoInterno',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'razonSocial',
        text: 'Razón social',
        sort: true,
        editable: false,
      },
      {
        dataField: 'documentoNumero',
        text: 'CUIT',
        sort: true,
        editable: false,
      },
    ];
  
    const selectRowProps = {
      mode: 'checkbox',
      selected: customersIdsForBulkInteraction,
      hideSelectAll: true,
      onSelect: this.onSelectCustomer,
    };

    return (
      <Modal size="xl" show={this.state.showAddCustomerModal} onHide={() => this.handlerCustomersModal('close')}>
        <Modal.Header closeButton>
          <Modal.Title>Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {customers !== undefined && customers !== {} && (
            <DataTable
              isDataLoading={this.state.isDataLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={customers || []}
              keyField="id"
              showExport={false}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.handlerCustomersModal('next')}>
            Listo
          </Button>
          <Button variant="secondary" onClick={() => this.handlerCustomersModal('close')}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const {
      curFromDate,
      curToDate,
      isDataLoading,
      customers,
      customersFilterSelected,
      interactions,
      showAddInteractionModal,
      startDate,
      interactionEdit,
      interactionFlow,
      bgColor,
      customerId,
      customersIdsForBulkInteraction,
      interactionType,
      files,
      typesOption,
      dueDate,
      userId,
      usersOptions,
      interactionState,
      stateOptions,
      description,
    } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'customer',
        text: 'Cliente',
        sort: true,
      },
      {
        dataField: 'assignedUser',
        text: 'Operador asignado',
        sort: true,
      },
      {
        dataField: 'state',
        text: 'Estado',
        sort: true,
      },
      {
        dataField: 'type',
        text: 'Tipo de interacción',
        sort: true,
      },
      {
        dataField: 'startDate',
        text: 'Fecha de alta',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Detalles',
        sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <Button size="sm" variant="outline-primary" title="Editar" onClick={() => this.onEditInteraction(row.id)}>
                <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];
    return (
      <div>
        {this.customerListModal()}
        <h1 className="page-title">Interacciones con clientes</h1>
        <AddInteractionModal
          showAddInteractionModal={showAddInteractionModal}
          startDate={startDate}
          interactionEdit={interactionEdit}
          interactionFlow={interactionFlow}
          bgColor={bgColor}
          customerId={customerId}
          customersIdsForBulkInteraction={customersIdsForBulkInteraction}
          customers={customers}
          interactionType={interactionType}
          files={files}
          typesOption={typesOption}
          dueDate={dueDate}
          userId={userId}
          usersOptions={usersOptions}
          interactionState={interactionState}
          stateOptions={stateOptions}
          description={description}
          flowOptions={flowOptions}
          handleDataChange={this.handleDataChange}
          handleInteractionStateInput={this.handleInteractionStateInput}
          handleAddInteractionModal={this.handleAddInteractionModal}
          onSaveEntity={this.onSaveEntity}
          handleFileChange={this.handleFileChange}
          deleteFile={this.deleteFile}
        />
        <Card className="mb-5 mt-5">
          <Card.Header>Filtros</Card.Header>
          <Card.Body>
            <Row>
              <Col md={3}>
                <FormInputField
                  id="curFromDate"
                  type="date"
                  label="Fecha desde:"
                  defaultValue={curFromDate !== '' ? curFromDate : null}
                  onChange={this.handleDataChange}
                  required
                />
              </Col>
              <Col md={3}>
                <FormInputField
                  id="curToDate"
                  type="date"
                  label="Fecha hasta:"
                  onChange={this.handleDataChange}
                  defaultValue={curToDate !== '' ? curToDate : null}
                  min={curFromDate !== '' ? curFromDate : null}
                  required
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="customersFilterSelected"
                  value={customersFilterSelected}
                  label={this.renderSelectAllButtons('customers')}
                  onChange={this.handleDataChange}
                  choices={customers}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                  multiple
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-center">
                  <Button
                    disabled={isDataLoading}
                    className="d-flex py-2 m-1 my-3"
                    variant="primary"
                    onClick={this.onApplyFilters}
                  >
                    {
                      !isDataLoading
                        ? <p className="m-0">Buscar</p>
                        : <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                    }
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <DataTable
          isDataLoading={isDataLoading}
          columns={columns}
          rowClasses={this.rowClasses}
          data={interactions || []}
          keyField="id"
          addButton={() => this.handleAddInteractionModal('open')}
          addSecondButton={() => this.handlerCustomersModal('open')}
          secondButtonText='Interacciones masivas'
        />
      </div>
    );
  }
}

export default withToastManager(InteractionsList);
