import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {
  Button, ButtonGroup, ButtonToolbar, Col, Form, Modal, ProgressBar, Row, Container,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch, faEdit, faTimes, faBroom, faTruck
} from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormSelectField, FormInputField } from '../../components';

import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';

class SolicitudList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      entityToCancel: {},
      uploadedFile: null,
      fileContentText: null,
      isDataLoading: true,
      isFileUploading: false,
      isEntityDataLoading: false,
      isModalLoadingData: false,
      showAnularModal: false,
      totalSize: 0,
      limit: 10,
      offset: 0,
      showUploadModal: false,
      fileFormatCode: null
    };

    this.loadSolicitudes = this.loadSolicitudes.bind(this);
  }

  componentDidMount() {
    this.loadSolicitudes();
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      const { field } = sorting;
      this.apiParams = `includeCantidad=true&freeText=${freeText && `%${freeText}%`}&sortField=${field || 'createdAt'}&sortDir=${direction || 'desc'}`;
      const solicitudesRes = await APIClient.get(`/solicitudes?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      const solicitudes = solicitudesRes.data.data;
      this.setState({
        entities: solicitudes,
        totalSize: solicitudesRes.data.meta.total,
        limit: pagination.limit,
        offset: pagination.offset,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  }

  async loadSolicitudes() {
    const { toastManager } = this.props;
    const { limit, offset } = this.state;
    try {
      this.apiParams = this.apiParams = `includeCantidad=true&sortField=createdAt&sortDir=desc`;
      const solicitudesRes = await APIClient.get(`/solicitudes?limit=${limit || '10'}&offset=${offset || '0'}&${this.apiParams}`);
      this.setState({
        entities: solicitudesRes.data.data,
        totalSize: solicitudesRes.data.meta.total,
      });
    } catch (error) {
      console.error('Cargando solicitudes', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  limpiarSolicitud = async () => {
    const { entityToClean } = this.state;
    const { toastManager } = this.props;
    this.setState({ disableButtons: true });
    try {
      await APIClient.patch(`/solicitudes/${entityToClean.id}/limpiar`);
      toastManager.add('Se limpió la solicitud correctamente.', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        disableButtons: false, isEntityDataLoading: false, entityToClean: {}, showLimpiarSolicitudModal: false,
      });
      await this.loadSolicitudes();
    }
  };

  getEntityData = async (row) => {
    const { toastManager } = this.props;
    try {
      const entity = await APIClient.get(`/solicitudes/${row.id}`);
      const entityData = entity.data.data;
      if (entityData.estadoSolicitudCodigo.match(/SA|SP/) && entityData.detalles.every(det => det.solicitudDetalleTalle.entregado === 0)) {
        this.setState({ entityToCancel: entityData, showAnularModal: true });
      } else {
        toastManager.add('No se puede anular esta solicitud porque ya tiene detalles entregados.', {
          appearance: 'warning',
        });
      }
    } catch (error) {
      console.error('Obteniendo información acerca de la solicitud.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isEntityDataLoading: false });
    }
  }

  handleAnularClick = (row) => {
    this.setState({ isEntityDataLoading: true, showAnularModal: true, entityToCancel: row });
  }

  handleLimpiarClick = (row) => {
    this.setState({ isEntityDataLoading: true, showLimpiarSolicitudModal: true, entityToClean: row });
  }

  handleButtonClick = (row) => {
    this.setState({ isEntityDataLoading: true });
    this.getEntityData(row);
  }

  handleFileChange = (e) => {
    const newFile = e.target.files[0];
    this.setState({ uploadedFile: newFile });
  }

  handleUploadFormChange = (e) => {
    const { id, fileFormatCode, value } = e.target;
    if (value === '') {
      this.setState({ [id]: null });
    } else if (id === 'fileFormatCode' && value !== fileFormatCode) {
      this.setState({ fileFormatCode: value, uploadedFile: null, fileContentText: null })
    } else {
      this.setState({ [id]: value });
    }
  }

  anularSolicitud = async () => {
    const { entityToCancel } = this.state;
    const { toastManager } = this.props;
    this.setState({ disableButtons: true });
    try {
      await APIClient.patch(`/solicitudes/${entityToCancel.id}`, { estadoSolicitudCodigo: 'SB' });
      toastManager.add('Solicitud anulada correctamente.', {
        appearance: 'success',
      });
      this.setState((prevState) => {
        const { entities } = prevState;
        const cancelledEntity = entities.find(ent => ent.id === entityToCancel.id);
        cancelledEntity.estadoSolicitudCodigo = 'SB';
        return { entities, disableButtons: false };
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.response.data.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ disableButtons: false, showAnularModal: false, entityToCancel: {} });
      await this.loadSolicitudes();
    }
  }

  showSolicitudUploadModal = () => {
    this.setState({ showUploadModal: true });
  }

  shouldDisableUploadButton = () => {
    const {
      isFileUploading, fileContentText, fileFormatCode, uploadedFile,
    } = this.state;
    if (isFileUploading) {
      return true;
    }
    if ((fileContentText || uploadedFile) && fileFormatCode) {
      return false;
    }
    return true;
  }

  uploadFile = async () => {
    const { toastManager } = this.props;
    const { uploadedFile, fileFormatCode, fileContentText } = this.state;

    const fileBlob = new Blob([uploadedFile], { type: '.txt, .csv' });
    this.setState({ isFileUploading: true });
    try {
      let text = '';
      if (fileContentText && fileContentText.length > 0) {
        text = fileContentText;
      } else {
        text = await fileBlob.text();
      }
      if (text.length === 0) {
        toastManager.add('No se ha procesado la solicitud. Error: No hay texto que procesar.', {
          appearance: 'error'
        });
        return false;
      }
      await APIClient.post('/solicitudes/cargar', {
        fileContent: text,
        fileFormatCode
      });
      toastManager.add('Se ha creado la nueva solicitud correctamente.', {
        appearance: 'success',
        autoDismiss: true,
      });
      this.loadSolicitudes();
    } catch (err) {
      toastManager.add(`Ocurrió un error al subir el archivo: ${err.response ? err.response.data.message : err.message}`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        fileContentText: null,
        isFileUploading: false,
        fileFormatCode: null,
        showUploadModal: false,
        uploadedFile: null,
      });
    }
  }

  renderAnularPedidoModal = () => {
    const { disableButtons, showAnularModal } = this.state;
    return (
      <Modal
        size="md"
        show={showAnularModal}
        onHide={() => this.setState({ showAnularModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Anular Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea anular este pedido?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disableButtons} variant="primary" onClick={this.anularSolicitud}>
            {disableButtons ? <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" /> : 'Anular'}
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.setState({ showAnularModal: false, entityToCancel: {} })}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderCleanSolicitudModal = () => {
    const { disableButtons, showLimpiarSolicitudModal } = this.state;
    return (
      <Modal size="md" show={showLimpiarSolicitudModal} onHide={() => this.setState({ showLimpiarSolicitudModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Limpiar Solicitud</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea limpiar esta solicitud?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disableButtons} variant="primary" onClick={this.limpiarSolicitud}>
            {disableButtons ? <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" /> : 'Limpiar'}
          </Button>
          <Button disabled={disableButtons} variant="secondary" onClick={() => this.setState({ showLimpiarSolicitudModal: false, entityToCancel: {} })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderSolicitudUploadModal = () => {
    const {
      fileContentText, showUploadModal, isFileUploading, uploadedFile, fileFormatCode
    } = this.state;

    const validFormats = [
      { codigo: 'nestle', descripcion: 'Nestle Argentina' },
      { codigo: 'grupo_familia', descripcion: 'Grupo Familia' }
    ]

    return (
      <Modal size="lg" show={showUploadModal} onHide={() => this.setState({ showUploadModal: false, uploadedFile: null })}>
        <Modal.Header>
          <Modal.Title>Creación de Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Col>
              <FormSelectField
                id="fileFormatCode"
                label="Formato"
                type="text"
                choices={validFormats}
                choiceIdField="codigo"
                choiceLabelField="descripcion"
                disabled={isFileUploading}
                onChange={this.handleUploadFormChange}
                custom
              />
            </Col>
            {fileFormatCode === 'nestle' && (<Row className="justify-content-center">
              <Col md={11}>
                <Form.Group className="d-flex align-items-center" controlId="uploadedFile">
                  {this.renderFileLabel()}
                  <input
                    name="uploadedFile"
                    className="form-control custom-file-input custom-upload-label-position"
                    id="uploadedFile"
                    lang="es"
                    data-browse="Seleccionar"
                    type="file"
                    accept=".txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={this.handleFileChange}
                    disabled={fileContentText || isFileUploading}
                  />
                </Form.Group>
              </Col>
            </Row>)}
            {fileFormatCode === 'grupo_familia' && (<>
              <Col>
                <FormInputField
                  id="fileContentText"
                  label="Ingrese el contenido del texto:"
                  as="textarea"
                  placeholder="Contenido del email en formato tabla..."
                  onChange={this.handleUploadFormChange}
                  disabled={uploadedFile || isFileUploading}
                />
              </Col></>)}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={this.shouldDisableUploadButton()} variant="primary" onClick={this.uploadFile}>
            {isFileUploading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1 p-0" />
            ) : 'Cargar'}
          </Button>
          <Button disabled={isFileUploading} variant="secondary" onClick={() => this.setState({ showUploadModal: false, uploadedFile: null })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderFileLabel = () => {
    const { fileContentText, uploadedFile } = this.state;
    if (fileContentText) {
      return (
        <label id="override-boostrap" className="custom-file-label custom-upload-label-position custom-label-disabled" htmlFor="uploadedFile">
          Haga click para seleccionar un archivo.
        </label>
      );
    }
    if (uploadedFile) {
      return (
        <label className="custom-file-label custom-upload-label-position" htmlFor="uploadedFile">{uploadedFile.name}</label>
      );
    }
    return (
      <label className="custom-file-label custom-upload-label-position" htmlFor="uploadedFile">
        Haga click para seleccionar un archivo.
      </label>
    );
  }

  // Entrega a partir de solicitud
  handleRedirect = async (selectedClienteId, solicitudId) => {
    const { toastManager, history } = this.props;
    try {
      const entregaAbierta = await APIClient.get(`/entregas?filter[clienteId][eq]=${selectedClienteId}&filter[estadoEntrega][eq]=EA`);
      if (entregaAbierta.data.data.length === 0) {
        history.push(`/entregas/nuevo/${selectedClienteId}?solicitudId=${solicitudId}`);
      } else {
        history.push(`/entregas/${entregaAbierta.data.data[0].id}?solicitudId=${solicitudId}`);
      }
    } catch (err) {
      toastManager.add('Ocurrió un error al intentar obtener la entrega', {
        appearance: 'error',
      });
    }
  };

  render() {
    const { entities, isDataLoading, totalSize } = this.state;
    const columns = [
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return '';
          }
          return cellContent;
        },
      },
      {
        dataField: 'cliente.razonSocial',
        text: 'Cliente',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {cellContent}
            <br />
            <small>{row.cliente && row.cliente.documentoNumero}</small>
          </>
        ),
      },
      {
        dataField: 'cliente.documentoNumero',
        text: 'CUIT',
        sort: false,
        hidden: true,
      },
      {
        dataField: 'createdAt',
        text: 'Fecha Alta',
        sort: true,
        formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'fechaConfirmacion',
        text: 'Fecha Confirmación',
        sort: true,
        formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'estadoSolicitudCodigo',
        text: 'Estado',
        sort: true,
        csvFormatter: (cellContent) => {
          let nombre = '';
          switch (cellContent) {
            case 'SA':
              nombre = 'En Carga';
              break;
            case 'SB':
              nombre = 'Cancelada';
              break;
            case 'SC':
              nombre = 'Cerrada';
              break;
            case 'SE':
              nombre = 'Enviada';
              break;
            case 'SP':
              nombre = 'Confirmada';
              break;
            case 'SF':
              nombre = 'Facturada';
              break;
            default:
              break;
          }
          return nombre;
        },
        formatter: (cellContent, row) => UIUtils.getSolicitudEstadoBadge(cellContent),
      },
      {
        dataField: 'usuarioPerfil.usuario.firstName',
        text: 'Usuario',
        formatter: (cellContent, row) => {
          return  `${cellContent} ${row.usuarioPerfil.usuario.lastName}` 
        }
      },
       {
        dataField: 'progress',
        isDummyField: true,
        text: 'Progreso',
        formatter: (cellContent, row) => (
          <span>
            <p style={{ textAlign: 'center' }} className="text-align-center mb-0">
              {`${row.entregado}/${row.cantidad}`}
            </p>
            <ProgressBar now={(row.entregado * 100) / row.cantidad} />
          </span>
        ),
        csvFormatter: (cellContent, row) => `${row.entregado}/${row.cantidad}`,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar className="d-flex justify-content-center align-items-center">
            <ButtonGroup>
              <LinkContainer to={`/solicitudes/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
              <Button
                size="sm"
                disabled={!row.estadoSolicitudCodigo.match(/SP/)}
                variant="outline-primary"
                onClick={() => this.handleRedirect(row.cliente.id, row.id)}
                title="Generar-entrega"
              >
                <FontAwesomeIcon icon={faTruck} fixedWidth size="xs" />
              </Button>
              <Button
                size="sm"
                disabled={!row.estadoSolicitudCodigo.match(/SP/)}
                variant="outline-primary"
                onClick={() => this.handleLimpiarClick(row)}
                title="Limpiar"
              >
                <FontAwesomeIcon icon={faBroom} fixedWidth size="xs" />
              </Button>
              <Button
                size="sm"
                disabled={!row.estadoSolicitudCodigo.match(/SP|SA/)}
                variant="outline-primary"
                onClick={() => this.handleAnularClick(row)}
                title="Anular"
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Pedidos</h1>
        {this.renderAnularPedidoModal()}
        {this.renderCleanSolicitudModal()}
        {this.renderSolicitudUploadModal()}

        <DataTable
          remote={{
            filter: true, pagination: true, sort: true, cellEdit: false,
          }}
          columns={columns}
          data={entities}
          onTableUpdate={this.onTableUpdate}
          isDataLoading={isDataLoading}
          totalSize={totalSize}
          keyField="id"
          exportURL={`/solicitudes/export.csv?${this.apiParams}`}
          addButton={this.showSolicitudUploadModal}
          buttonText="Crear"
          defaultSorted={[{ dataField: 'fechaConfirmacion', order: 'desc' }]}
        />
      </div>
    );
  }
}

export default withToastManager(SolicitudList);
