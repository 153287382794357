import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormCheckField = (props) => {
  const { id,defaultChecked,onChange, ...otherProps } = props;

  return (
    <Form.Group controlId={id}>
      <Form.Check onChange={onChange} type="checkbox" name={id} defaultChecked={defaultChecked} {...otherProps} />
    </Form.Group>
  );
};

FormCheckField.propTypes = {
  onChange:PropTypes.func,
  defaultChecked:PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormCheckField;
