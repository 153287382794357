import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faEdit, faTimes, faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { parseDayData } from './utils';

class PuntoEntregaList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDataLoading: true,
      puntosEntrega: [],
      selectedRow: {},
      showConfirmModal: false,
    };

    this.loadPuntosEntrega = this.loadPuntosEntrega.bind(this);
  }

  componentDidMount() {
    this.loadPuntosEntrega();
  }

  loadPuntosEntrega() {
    const { toastManager } = this.props;

    APIClient.get('/puntos-entrega')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          puntosEntrega: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  changePuntoEntregaState = async () => {
    const { selectedRow } = this.state;
    const { history, toastManager } = this.props;
    const puntosEntrega = [...this.state.puntosEntrega];
    selectedRow.isEliminado = !selectedRow.isEliminado;
    const newPuntosEntrega = puntosEntrega.map((punto) => {
      if (punto.id === selectedRow.id) {
        punto.isEliminado = selectedRow.isEliminado;
        return { ...punto };
      }
      return punto;
    });
    try {
      const apiResponse = await APIClient.patch(`/puntos-entrega/${selectedRow.id}`, {
        isEliminado: selectedRow.isEliminado,
      });
      this.setState(prevState => ({
        ...prevState,
        isDataLoading: false,
        puntosEntrega: newPuntosEntrega,
        selectedRow: {},
        showConfirmModal: false,
      }));

      toastManager.add(
        `Punto de Entrega ${apiResponse.data.data.id} ${selectedRow.isEliminado ? 'deshabilitado' : 'rehabilitado'} con éxito`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => history.go(0),
      );

      return apiResponse.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  renderConfirmModal = () => {
    const { selectedRow } = this.state;
    const { isEliminado } = selectedRow;
    return (
      <Modal
        size="md"
        show={this.state.showConfirmModal}
        onHide={() => this.setState({ showConfirmModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!isEliminado ? 'Deshabilitar ' : 'Habilitar '}
            Punto de Entrega
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Está seguro de que desea
            {!isEliminado ? ' deshabilitar ' : ' habilitar '}
            este punto de entrega?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.changePuntoEntregaState}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showConfirmModal: false })}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  showModal = (row) => {
    this.setState({ selectedRow: row, showConfirmModal: true, isDataLoading: true });
  };

  render() {
    const { isDataLoading, puntosEntrega } = this.state;

    const columns = [
      {
        dataField: 'id',
        hidden: true,
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
        sort: true,
      },
      {
        dataField: 'deliveryDays',
        text: 'Días de entrega',
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            {
              parseDayData(row.deliveryDays)
            }
          </div>
        )
      },
      {
        dataField: 'deliveryTime',
        text: 'Horario de entrega',
        sort: true,
      },
      {
        dataField: 'calle',
        text: 'Dirección',
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            {`${row.calle} ${row.numero}`}
            <br />
            {`${row.localidad} (${row.codigoPostal}), ${row.provincia ? row.provincia.descripcion : ''}`}
          </div>
        ),
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
      },
      {
        dataField: 'isEliminado',
        isDummyField: false,
        text: 'Deshabilitado',
        sort: true,
        formatter: (cellContent) => {
          if (cellContent) {
            return <FontAwesomeIcon icon={faCheck} fixedWidth size="xs" />;
          }
        },
      },
      {
        dataField: ' ',
        isDummyField: true,
        text: 'Acciones',
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span className="d-flex justify-content-center">
            <ButtonToolbar>
              <ButtonGroup>
                <LinkContainer to={`/puntos-entrega/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  title={row.isEliminado ? 'Habilitar' : 'Deshabilitar'}
                  onClick={() => this.showModal(row)}
                >
                  <FontAwesomeIcon icon={formatExtraData[!row.isEliminado]} fixedWidth size="xs" />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </span>
        ),
        formatExtraData: {
          true: faTimes,
          false: faUndo,
        },
      },
    ];

    return (
      <div>
        {this.renderConfirmModal()}
        <h1 className="page-title">Puntos de Entrega</h1>
        <DataTable columns={columns} data={puntosEntrega} isDataLoading={isDataLoading} keyField="id" addButton="/puntos-entrega/nuevo" />
      </div>
    );
  }
}

export default withToastManager(PuntoEntregaList);
