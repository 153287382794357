
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import NextToExpire from './NextToExpire.js';
import ProductOrdervsSales from './ProductOrdervsSales.js';
import ValuedStock from './ValuedStock.js';

class StockReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
 
    return (
      <div>
        <Row className="d-flex align-items-center page-title">
          <Col md={6}>
            <h1>Reportes de stock</h1>
          </Col>
        </Row>
        <Tabs defaultActiveKey="nextToExpireReport" id="uncontrolled-tab-example">
          <Tab eventKey="nextToExpireReport" title="Próximos a vencer">
            <NextToExpire />
          </Tab>
          <Tab eventKey="productOrdervsSales" title="Comparativa entre pedidos y entregados">
            <ProductOrdervsSales />
          </Tab>
          <Tab eventKey="valuedStock" title="Stock valorizado">
            <ValuedStock />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withToastManager(StockReport);
