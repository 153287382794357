import { faKey, faPowerOff, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Dropdown, DropdownButton, Image, Nav, Navbar, NavbarBrand, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import Security from '../services/Security';

// import Dashboard from "./Dashboard/Dashboard";
import MonitorPendientes from './MonitorPendientes/MonitorPendientes';
import NotFound from './NotFound';
import PasswordChange from './PasswordChange';
import AvanceVenta from './Reportes/AvanceVenta';
import SideMenu from './SideMenu';
import ReporteDiario from './ReporteDiario/ReporteDiarioRoutes';
import { thereAreNextProductsToExpire } from '../services/notifications/notification.splice';

// routes
import articuloRoutes from './Articulo/ArticuloRoutes';
import clienteRoutes from './Cliente/clienteRoutes';
import prospectoRoutes from './Prospecto/prospectRoutes';
import clienteTipoRoutes from './ClienteTipo/clienteTipoRoutes';
import coleccionRoutes from './Coleccion/coleccionRoutes';
import entregaRoutes from './Entrega/EntregaRoutes';
import expresoRoutes from './Expreso/ExpresoRoutes';
import stockRoutes from './Stock/stockRoutes';
import lineaRoutes from './Linea/lineaRoutes';
import listaPrecioRoutes from './ListaPrecio/ListaPrecioRoutes';
import marcaRoutes from './Marca/marcaRoutes';
import comboRoutes from './Combo/comboRoutes';
import solicitudRoutes from './Solicitud/solicitudRoutes';
import usuarioRoutes from './Usuario/usuarioRoutes';
import vendedorRoutes from './Vendedor/VendedorRoutes';
import zonaRoutes from './Zona/ZonaRoutes';
import interactionsRoutes from './Interactions/interactionsRoutes';
import pendienteEntregaRoutes from './PendientesEntrega/pendienteEntregaRoutes';
import CustomersReportRoutes from './Reportes/CustomersReport/CustomersReportRoutes';
import precioModificadorRoutes from './PrecioModificador/PrecioModificadorRoutes';
import proveedorRoutes from './Proveedor/ProveedorRoutes';
import puntoEntregaRoutes from './PuntoEntrega/PuntoEntregaRoutes';
import salesReportRoutes from './Reportes/SaleReport/SalesReportRoutes';
import stockReportRoutes from './Reportes/StockReport/StockReportRoutes';

import APIClient from '../services/APIClient';
import Notification from '../components/Notification';

class Layout extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      session: Security.getSession(),
      logo: {},
      showNotificationModal: true
    };

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.getLogo = this.getLogo.bind(this);
    this.changePasswordRedirect = this.changePasswordRedirect.bind(this);
    this.interactionsRedirect = this.interactionsRedirect.bind(this);
    this.stockRedirect = this.stockRedirect.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount() {
    this.getLogo();
  }

  getLogo() {
    APIClient.get('/cms/logo')
      .then((res) => {
        this.setState({ logo: res.data.data });
      })
      .catch((err) => {
        console.error('No se pudo obtener el logo: ', err);
      });
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  changePasswordRedirect(event) {
    event.preventDefault(event);
    const { history } = this.props;
    history.push('/cambiar-password');
  }

  logout(event) {
    event.preventDefault(event);

    const { history } = this.props;
    Security.clearSession();
    history.push('/login');
  }

  interactionsRedirect(event, customerId) {
    event.preventDefault(event);
    const { history } = this.props;
    let url;
    if (customerId) {
      url = `/interacciones?cliente=${customerId}`
    } else {
      url = '/interacciones'
    }
    history.push(url);
  }

  stockRedirect(event) {
    event.preventDefault(event);
    const { history } = this.props;
    history.push('/reporte-stock');
  }

  handleModal(action, payload) {
    switch (action) {
      case 'close':
        this.setState({
          showNotificationModal: false
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { logo, session, showNotificationModal } = this.state;
    const { notification } = this.props;

    return (
      <div>
        <Notification
          title={"Artículos a vencer"}
          detail={`Hay ${notification.validityStockNotification.nextProductsToExpire} artículos a vencer dentro de los próximos 8 meses`}
          handleModal={this.handleModal}
          showModal={showNotificationModal}
          redirect={"/reporte-stock"}
        />
        <Navbar bg="dark" fixed="top" variant="dark" className="flex-md-nowrap p-0 shadow">
          <NavbarBrand href="/" className="col-sm-3 col-md-2 mr-0">
            <Container className="ml-0 p-0 logo-container">
              <Image className="header-logo mw-100" src={logo.full_url} alt="" />
              {/* Portal Comercial */}
            </Container>
          </NavbarBrand>
          <Nav className="ml-4 mr-auto header-title">Portal Comercial</Nav>
          <Nav className="px-3" navbar>
            <DropdownButton
              focusFirstItemOnShow={false}
              alignRight
              className="pl-3"
              variant="dark"
              id="dropdown-item-button-interaction"
              title={(
                <FontAwesomeIcon
                  icon={faBell}
                  fixedWidth
                  style={notification.notifications ? { color: '#ff0000' } : ''}
                  className="mr-2"
                />
              )}
            >
              {
                notification.validityStockNotification.state
                  ? (
                    <Dropdown.Item href="/logout" onClick={this.stockRedirect} as="button">
                      <FontAwesomeIcon icon={faBell} fixedWidth className="mr-1" style={{ color: '#ff0000' }} />
                      {`Hay productos próximos a vencer`}
                    </Dropdown.Item>
                  ) : ''
              }
              {
                notification.interactionNotification.state
                  ? (
                    <Dropdown.Item href="/logout" onClick={(e) => this.interactionsRedirect(e)} as="button">
                      <FontAwesomeIcon icon={faBell} fixedWidth className="mr-1" style={{ color: '#ff0000' }} />
                      {`Tienes ${notification.interactionNotification.interactionsNotSeen} interacciones sin revisar`}
                    </Dropdown.Item>
                  ) : ''
              }
              {
                notification.customersWithoutInteractionsNotification.state
                  ? (
                    notification.customersWithoutInteractionsNotification.customersWithoutInteractions.map(interaction => (
                      <Dropdown.Item key={interaction.customerId} href="/logout" onClick={(e) => this.interactionsRedirect(e, interaction.customerId)} as="button">
                        <FontAwesomeIcon icon={faBell} fixedWidth className="mr-1" style={{ color: '#ff0000' }} />
                        {`El cliente ${interaction.customer} ha estado sin interacciones recientemente`}
                      </Dropdown.Item>
                    ))
                  ) : ''
              }
            </DropdownButton>

            <DropdownButton
              focusFirstItemOnShow={false}
              alignRight={true}
              className="pl-3"
              variant="dark"
              id="dropdown-item-button"
              title={`${session.user.username}`}>
              <Dropdown.Item href="/cambiar-password" onClick={this.changePasswordRedirect} as="button">
                <FontAwesomeIcon icon={faKey} fixedWidth className="mr-1" />
                Cambiar Password
              </Dropdown.Item>
              <Dropdown.Item href="/logout" onClick={this.logout} as="button">
                <FontAwesomeIcon icon={faPowerOff} fixedWidth className="mr-1" />
                Salir
              </Dropdown.Item>
            </DropdownButton>
          </Nav>
        </Navbar>
        <Container fluid>
          <Row>
            <SideMenu />
            <main id="main-content" className="col-md-9 ml-sm-auto col-lg-10 px-4 pb-4" role="main">
              <Switch>
                <Route exact path="/" component={AvanceVenta} />
                <Route exact path="/monitor-pendientes" component={MonitorPendientes} />
                <Route exact path="/cambiar-password" component={PasswordChange} />
                {solicitudRoutes()}
                {/* Logística */}
                {entregaRoutes()}
                {pendienteEntregaRoutes()}
                {expresoRoutes()}
                {puntoEntregaRoutes()}
                {stockRoutes()}
                {/* Articulos */}
                {articuloRoutes()}
                {marcaRoutes()}
                {lineaRoutes()}
                {coleccionRoutes()}
                {/* Clientes */}
                {clienteRoutes()}
                {CustomersReportRoutes()}
                {zonaRoutes()}
                {interactionsRoutes()}
                {listaPrecioRoutes()}
                {precioModificadorRoutes()}
                {clienteTipoRoutes()}
                {vendedorRoutes()}
                {proveedorRoutes()}
                {/* Clientes Propspect */}
                {prospectoRoutes()}
                {/* Administracion */}
                {usuarioRoutes()}
                {/* reportes */}
                {ReporteDiario()}
                {salesReportRoutes()}
                {stockReportRoutes()}
                {/* Combos */}
                {comboRoutes()}
                <Route component={NotFound} />
              </Switch>
            </main>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notification: state.notification,
});

const mapDispatchToProps = { thereAreNextProductsToExpire };

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
