import React from 'react';
import { Route } from 'react-router-dom';
import ArticuloList from './ArticuloList';
import ArticuloEdit from './ArticuloEdit';

const routes = () => [
  <Route path="/articulos/nuevo" exact component={ArticuloEdit} key="/articulos/nuevo" />,
  <Route path="/articulos/:id" component={ArticuloEdit} key="/articulos/:id" />,
  <Route path="/articulos" exact component={ArticuloList} key="/articulos" />,
].map(route => route);

export default routes;
