import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: false,
  interactionNotification: {
    state: false,
    interactionsNotSeen: 0,
  },
  customersWithoutInteractionsNotification: {
    state: false,
    customersWithoutInteractions: [],
  },
  validityStockNotification: {
    state: false,
    nextProductsToExpire: 0,
  },
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    thereAreinteractionNotification: (state, value) => {
      state.interactionNotification = value.payload;
    },
    thereAreCustomerWithoutInteractions: (state, value) => {
      state.customersWithoutInteractionsNotification = value.payload;
    },
    thereAreNextProductsToExpire: (state, value) => {
      state.validityStockNotification = value.payload;
    },
    updateNotificationsState: (state, value) => {
      state.notifications = value.payload.notifications;
      state.interactionNotification = value.payload.interactionNotification;
      state.customersWithoutInteractionsNotification = value.payload.customersWithoutInteractionsNotification;
      state.validityStockNotification = value.payload.validityStockNotification;
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = notificationSlice;

export const {
  thereAreinteractionNotification,
  thereAreCustomerWithoutInteractions,
  thereAreNextProductsToExpire,
  updateNotificationsState,
} = actions;

export default reducer;
