import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';

class VendedorEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
    };
  }

  onLoadForm = async () => {
    // get marcas
    // const marcasRes = await APIClient.get('/marcas');
    // this.setState({
    // });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get vendedor
    const vendedorRes = await APIClient.get(`/vendedores/${id}`);

    this.setState({
      entity: vendedorRes.data.data,
    });

    return vendedorRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/vendedores', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/vendedores/${id}`, entityToSave);
    }

    history.push('/vendedores');
    toastManager.add(`Vendedor ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Ejecutivo comercial nuevo' : `Ejecutivo comercial #${id}`}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
        >
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="nombre"
                  label="Nombre"
                  type="text"
                  defaultValue={entity.nombre}
                />
              </Col>
              <Col md={6}>
                <FormInputField id="email" label="Email" type="text" defaultValue={entity.email} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField
                  id="eliminadoFlag"
                  label="Inactivo"
                  defaultChecked={entity.eliminadoFlag}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(VendedorEdit);
