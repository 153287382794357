import APIClient from "../APIClient";
import config from '../../config';

/**
 * @constant {Object} interactionService services for interactions
 */
export const interactionService = {
    /**
     * Save interaction files
     * @param {Array} files files to upload
     * @param {String} interactionId
     */
    loadFile: async (files, interactionId) => {
        const filesPromises = [];
        for (let i = 0; i < files.length; i++) {
          const curFile = files[i];
          const curFileResponse = await APIClient.getFile(curFile.filename, { responseType: 'blob' });
          const newFile = {
            name: curFile.name,
          };
          const reader = new FileReader();
          reader.onloadend = () => {
            const result = reader.result.split(',');
            newFile.fileData = result[1];
            newFile.fileType = result[0].substring(result[0].lastIndexOf(':') + 1, result[0].lastIndexOf(';'));
            newFile.isUploaded = false;
            const curPromise = APIClient.post(`/interaction/file?interactionId=${interactionId}`, newFile);
            filesPromises.push(curPromise);
          };
          reader.onerror = (error) => {
            console.error('Error: ', error);
          };
          reader.readAsDataURL(curFileResponse.data);
        }
        await Promise.all(filesPromises);
    },

    /**
     * Delete interaction file
     * @param {String} id interaction id
     * @param {String} fileId file id
     */
    deleteFile: async (id, fileId) => {
      try {
        await APIClient.delete(`/interaction/${id}/file/${fileId}`);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Download interaction file
     * @param {Object} file file to download
     * @param {Object} interactionEdit current interaction
     */
    downLoadFile: (file, interactionEdit) => {
      APIClient.downloadFile(`${config.api.backPublicPath}/interactions/files/${interactionEdit.id}/${file.filename}`).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name || file.filename);
        document.body.appendChild(link);
        link.click();
      });
    }
}