/* External libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';

/* local imports */
import APIClient from '../../services/APIClient';

class Subtable extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    filterQuery: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
      isDataLoading: true,
      selectedClienteIds: props.clientId,
      detSolicitudArticulo: [],
    };

    this.loadPendientes = this.loadPendientes.bind(this);
  }

  componentDidMount() {
    this.loadPendientes();
  }

  async loadPendientes() {
    const { toastManager } = this.props;
    try {
      const { selectedClienteIds, row } = this.state;
      const clientIdProps = `clientId=${selectedClienteIds}`;
      const ArticleId = `articleId=${row.articuloId}`;
      const queryParameters = `${clientIdProps}&${ArticleId}`;
      const detSolicitudArticulo = await APIClient.get(`/reportes-articulos-pendientes/articulos-pendientes?${queryParameters}`);

      this.setState({
        detSolicitudArticulo: detSolicitudArticulo.data.data[0],
        isDataLoading: false,
      });
    } catch (error) {
      toastManager.add(`No se pudo obtener la información. ${error}`, {
        appearance: 'error',
      });
    }
  }

  render() {
    const { isDataLoading, detSolicitudArticulo } = this.state;
    return !isDataLoading ? (
      <div>
        <table className="w-100">
          <thead className="font-weight-bold">
            <tr>
              <th>Solicitud</th>
              <th>Fecha de Alta</th>
              <th>Articulo</th>
              <th>Cantidad</th>
              <th>Entregado</th>
            </tr>
          </thead>
          <tbody>
            {detSolicitudArticulo.map((detSolicitudArticulo) => (
              <tr key={`${detSolicitudArticulo.Solicitud_id}`}>
                <td>{detSolicitudArticulo.Solicitud_id}</td>
                <td>{detSolicitudArticulo.Fecha_de_alta}</td>
                <td>{detSolicitudArticulo.Articulo}</td>
                <td>{detSolicitudArticulo.Cantidad}</td>
                <td>{detSolicitudArticulo.Entregados}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <span>Cargando...</span>
    );
  }
}

export default withToastManager(Subtable);

Subtable.propTypes = {
  filterQuery: PropTypes.string,
};
Subtable.defaultProps = {
  filterQuery: '',
};
