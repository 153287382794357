import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormInputField = (props) => {
  const { validText, invalidText, id, label, isInvalid, ...otherProps } = props;

  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control name={id}  {...otherProps}/>
      {validText && <Form.Control.Feedback type='valid'>{validText}</Form.Control.Feedback>}
      {invalidText && <Form.Control.Feedback type='invalid'>{invalidText}</Form.Control.Feedback>}
    </Form.Group>
  );
};

FormInputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  arrayvalidateindex: PropTypes.arrayOf(PropTypes.string),
};
FormInputField.defaultProps = {
  arrayvalidateindex: [],
};
export default FormInputField;
