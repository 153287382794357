const monthSelectOptionsCalculator = () => {
  // current date
  const currentDay = new Date();
  const currentMonth = Number(currentDay.getMonth());
  const currentYear = Number(currentDay.getFullYear());

  // last day per month
  const optionsDefault = [
    {
      month: 'Enero - Febrero',
      value: `${currentYear}-02-01`,
    },
    {
      month: 'Febrero - Marzo',
      value: `${currentYear}-03-01`,
    },
    {
      month: 'Marzo - Abril',
      value: `${currentYear}-04-01`,
    },
    {
      month: 'Abril - Mayo',
      value: `${currentYear}-05-01`,
    },
    {
      month: 'Mayo - Junio',
      value: `${currentYear}-06-01`,
    },
    {
      month: 'Junio - Julio',
      value: `${currentYear}-07-01`,
    },
    {
      month: 'Julio - Agosto',
      value: `${currentYear}-08-01`,
    },
    {
      month: 'Agosto - Septiembre',
      value: `${currentYear}-09-01`,
    },
    {
      month: 'Septiembre - Octubre',
      value: `${currentYear}-10-01`,
    },
    {
      month: 'Octubre - Noviembre',
      value: `${currentYear}-11-01`,
    },
    {
      month: 'Noviembre - Diciembre',
      value: `${currentYear}-12-01`,
    },
    {
      month: 'Diciembre - Enero',
      value: `${currentYear + 1}-01-01`,
    },
  ];

  const monthOptions = [];
  // add next year for months that are next year
  for (let i = currentMonth; i < currentMonth + 11; i += 1) {
    if (i === 12) {
      for (let e = 0; e < currentMonth; e += 1) {
        optionsDefault[e].month = optionsDefault[e].month + ' - ' + (currentYear + 1).toString();
        optionsDefault[e].value = `${currentYear + 1}-${e + 1}-01`;
        monthOptions.push(optionsDefault[e]);
      }
    }

    if (optionsDefault[i] !== undefined) {
      monthOptions.push(optionsDefault[i]);
    }
  }
  return monthOptions;
};

export const assocProductsToDelete = (priceModifierToUpdate, newDetalles) => {
  // looking for deleted products for update the price modifier
  const assocToDelete = {
    detalles: [],
  };
  let exist = false;
  priceModifierToUpdate.detalles.forEach((oldProduct) => {
    exist = false;
    newDetalles.forEach((newProduct) => {
      if (oldProduct.id === newProduct.id) {
        exist = true;
      }
    });
    if (!exist) {
      assocToDelete.detalles.push(oldProduct.id);
    }
  });
  return assocToDelete;
};

export default monthSelectOptionsCalculator();
