import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';

class ProveedorList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDataLoading: true,
      proveedores: [],
    };

    this.loadProveedores = this.loadProveedores.bind(this);
  }

  componentDidMount() {
    this.loadProveedores();
  }

  loadProveedores() {
    const { toastManager } = this.props;

    APIClient.get('/proveedores')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          proveedores: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { isDataLoading, proveedores } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'nombre',
        text: 'Nombre',
        sort: true,
      },
      {
        dataField: 'isEliminado',
        text: 'Inactivo',
        sort: true,
        formatter: (cellContent, row) => (
          <>{row.isEliminado && <FontAwesomeIcon icon={faCheck} fixedWidth />}</>
        ),
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/proveedores/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Proveedores</h1>
        <DataTable columns={columns} data={proveedores} isDataLoading={isDataLoading} keyField="id" addButton="/proveedores/nuevo" />
      </div>
    );
  }
}

export default withToastManager(ProveedorList);
