const config = {
  api: {
    url: process.env.REACT_APP_API_URL || 'http://localhost',
    publicPath: process.env.REACT_APP_PUBLIC_PATH || 'http://localhost/public/images/product',
    backPublicPath: `${process.env.REACT_APP_API_URL}/uploads`,
  },
  date: {
    locale: 'es-AR'
  },
  export: {
    extension: 'xlsx'
  },
  facturarXubio: {
    splitMoneyLimit: process.env.REACT_APP_SPLIT_LIMIT,
  }
};

export default config;
