import React from 'react';
import { Route } from 'react-router-dom';
import ReporteDiario from './ReporteDiario';

const routes = () => [
  <Route path="/reportes-diarios" exact component={ReporteDiario} key="/reportes-diarios" />,
  ,
].map(route => route);

export default routes;
